import React from "react";
import "./header.css";
import InfiniteLooper from "../../InfiniteLooper/InfiniteLooper";
import {Link} from 'react-router-dom'
const Header = () => {
  return (
    <div>
      <div className="header d-flex align-items-center justify-content-center flex-column text-center">
        <h1 className="heading_xl fw-700 lh-150 text-uppercase">
          Get Accurate Business <br /> Valuation on Our Platform
        </h1>
        <p className="heading_m color_light_v1 lh-150 fw-700 text-uppercase">
          For Headstart Network Foundation
        </p>

        <Link to="/signup">
          <button className='header_btn'>
            Get started
          </button>
        </Link>
        {/* <div className="home_zefy-sv_loop_root"></div> */}
      </div>
      <InfiniteLooper speed={3.8} direction={"left"}>
        <div>
          <h1 className="d-flex align-items-center color_light_v4 fw-600 heading_m ">
            VCBay <span className="loop_span"></span> Headstart
            <span className="loop_span"></span>
          </h1>
        </div>
      </InfiniteLooper>
    </div>
  );
};
export default Header;
