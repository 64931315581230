import React, { useEffect, useState } from 'react';

import { useFormik } from "formik";
import * as Yup from 'yup';
import api from '../../axiosAPI';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAuth } from '../../Utils/Auth'
import DashNav from '../../Components/DashBoardComponents/DashboardNav/DashNav';
import './payment.css'
import useQuery from '../../Utils/queryParamsExtract'

function loadScript(src) {
     return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
               resolve(true);
               script.onerror = () => {
                    resolve(false);
               };
          };
          document.body.appendChild(script);
     });
}

const Payment = () => {
     const { billingAddress, currentUser } = useAuth();
     const query = useQuery()
     const success = query.get('success');

     const navigate = useNavigate();

     const [isLoading, setIsLoading] = useState(false);

     const [countries, setCountries] = useState([]);
     const [country, setCountry] = useState();

     const [countryCode, setCountryCode] = useState()

     const [states, setStates] = useState([])
     const [state, setState] = useState()

     const [stateCode, setStateCode] = useState()

     const [cities, setCities] = useState([])
     const [city, setCity] = useState()


     const fetchcountries = async () => {
          const response = await api.get(`/location/countries`);
          setCountries(response.data);
     };

     const fetchstates = async () => {
          const response = await api.get(`/location/country/${countryCode}`);
          if (response) {
               setStates(response.data);
          }
     }

     const fetchcities = async () => {
          const response = await api.get(`/location/cities/${countryCode}/${stateCode}`);
          setCities(response.data);

     };



     const validationSchema = Yup.object().shape({
          gstNumber: Yup.string(),
          mobileNumber: Yup.number()
               .required("Mobile number is required"),
          billingAddress: Yup.string()
               .required("Billing address is required"),
          country: Yup.string()
               .required("Country is required"),
          state: Yup.string()
               .required("State is required"),
          city: Yup.string()
               .required("City is required"),
          pinCode: Yup.string()
               .required('Pin code is required')
     });

     useEffect(() => {
          fetchcountries();
     }, [])

     useEffect(() => {
          setStateCode()
          setState()
          fetchstates();
     }, [country, countryCode]);

     useEffect(() => {
          setCity()
          fetchcities();
     }, [state, stateCode]);

     const onSubmit = () => {

          if (values) {
               initPayment()
          }


     }
     const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({ //isSubmitting

          initialValues: {
               country: country,
               gstNumber: billingAddress && billingAddress.gstNumber,
               mobileNumber: billingAddress && billingAddress.phoneNumber,
               billingAddress: billingAddress && billingAddress.address,
               state: state,
               city: city,
               pinCode: billingAddress && billingAddress.pinCode,
          },
          enableReinitialize: true,
          onSubmit,
          validationSchema: validationSchema
     })




     const initPayment = async () => {
          setIsLoading(true)
          const res = await loadScript(
               "https://checkout.razorpay.com/v1/checkout.js"
          );

          if (!res) {
               return toast("Payment failed to load, please try again");
          }
          const data = {
               credit_request: 1,
               billingAddress: {
                    country: values.country,
                    phoneNumber: values.mobileNumber,
                    address: values.billingAddress,
                    pinCode: values.pinCode,
                    gstNumber: values.gstNumber,
                    city: values.city,
                    state: values.state,
                    locationCode: {
                         country: countryCode,
                         state: stateCode
                    }
               }
          }

          api.post('/payment/create/orderId', data).then(res => {
               setIsLoading(false)
               const { orderDetails, userDetails } = res.data;
               var options = {
                    key: process.env.REACT_APP_RazorPay_test_Key,
                    amount: orderDetails.amount,
                    currency: orderDetails.currency,
                    name: "VCBay Solution",
                    description: "Valuation Report Payment",
                    image: "https://headstart-users.s3.ap-south-1.amazonaws.com/VCBay.svg",
                    order_id: orderDetails.id,
                    callback_url: `${process.env.REACT_APP_baseURL}/api/payment/check-success/${orderDetails.id}/${data.credit_request}`,
                    prefill: {
                         name: userDetails?.firstName + " " + userDetails?.lastName,
                         email: userDetails?.email,
                    },
                    theme: {
                         color: "#880015",
                    },

               };
               const PaymentInstance = new window.Razorpay(options);
               PaymentInstance.open();
          }).catch(() => {
               toast.error('Something went wrong')
          })

     }
     useEffect(() => {
          if (success) {
               navigate('/dashboard')
          }
     }, [])

     return <>
          <div className="dasboard_main">
               <DashNav isDashboard={currentUser?.initialPayment ? true : false} />
               <div className="dashboard_head">
                    <div className="dashboard_head_content">
                    </div>
               </div>
          </div>
          <div className='dasboard_payment_root'>
               <div className='dasboard_payment_max white-background p-32'>
                    <p className='fw-500 heading_s'>Billing Details</p>
                    <form className='mt-30' autoComplete="off" onSubmit={handleSubmit}>
                         <div>
                              <label className='auth_label' htmlFor="billingAddress">Billing Address <span className='fw-600 text-danger'>*</span></label>
                              <textarea

                                   id="billingAddress"
                                   className={`valuation_input w-100 mt-1 ${(touched.billingAddress && errors.billingAddress) && 'border-danger'}`}
                                   placeholder='Enter your billing address'
                                   value={values.billingAddress}
                                   onChange={handleChange('billingAddress')}
                                   onBlur={handleBlur}
                              />
                              {
                                   (touched.billingAddress && errors.billingAddress) && <div className='text-danger mt-1'>{errors.billingAddress}</div>
                              }

                         </div>

                         <div className='row'>
                              <div className='mt-3 col-lg-4 col-sm-12 col-xs-12'>
                                   <label className='auth_label' htmlFor="country">Country <span className='fw-600 text-danger'>*</span></label>
                                   <select
                                        id="country"
                                        className="valuation_input w-100 mt-1"
                                        name="grossValue"
                                        value={country}
                                        onChange={(e) => {
                                             setCountryCode(e.target.value.split("#")[0])
                                             setCountry(e.target.value.split("#")[1])
                                        }}

                                   >
                                        <option className="" value="">
                                             {country ? country : "Country"}
                                        </option>
                                        {countries !== null
                                             ? countries.map((option, i) => (
                                                  <option
                                                       className=""
                                                       key={i}
                                                       value={`${option.iso2}` + "#" + `${option.name}`}

                                                  >
                                                       {option.name}
                                                  </option>
                                             ))
                                             : null}
                                   </select>
                              </div>

                              <div className='mt-3 col-lg-4 col-sm-12 col-xs-12'>
                                   <label className='auth_label' htmlFor="state">State <span className='fw-600 text-danger'>*</span></label>
                                   <select
                                        id="country"
                                        className="valuation_input w-100 mt-1"
                                        name="grossValue"
                                        value={state}
                                        onChange={(e) => {
                                             setStateCode(e.target.value.split("#")[0])
                                             setState(e.target.value.split("#")[1])
                                        }}
                                   >
                                        <option className="" value="">
                                             {state ? state : "State"}
                                        </option>
                                        {states !== null
                                             ? states.map((option, i) => (
                                                  <option
                                                       className=""
                                                       key={i}
                                                       value={`${option.iso2}` + "#" + `${option.name}`}
                                                  >
                                                       {option.name}
                                                  </option>
                                             ))
                                             : null}
                                   </select>

                              </div>
                              <div className='mt-3 col-lg-4 col-sm-12 col-xs-12'>
                                   <label className='auth_label' htmlFor="city">City <span className='fw-600 text-danger'>*</span></label>
                                   <select
                                        id="country"
                                        className="valuation_input w-100 mt-1"
                                        name="grossValue"
                                        value={city}
                                        onChange={(e) => {
                                             // setCity(e.target.value.split("#")[0])
                                             setCity(e.target.value.split("#")[1])
                                        }}
                                   >
                                        <option className="" value="">
                                             {city ? city : "City"}
                                        </option>
                                        {cities !== null
                                             ? cities.map((option, i) => (
                                                  <option
                                                       className=""
                                                       key={i}
                                                       value={`${option.iso2}` + "#" + `${option.name}`}
                                                  >
                                                       {option.name}
                                                  </option>
                                             ))
                                             : null}
                                   </select>

                              </div>




                         </div>

                         <div className='row mt-4'>
                              <div className='col-lg-6 col-sm-12 col-xs-12'>
                                   <label className='auth_label' htmlFor="pinCode">Pincode <span className='fw-600 text-danger'>*</span></label>
                                   <input type="text"
                                        id="pinCode"
                                        className={`valuation_input w-100 mt-1 text-uppercase ${(errors.pinCode && touched.pinCode) && 'border-danger'}`}
                                        placeholder="Enter your pincode here"
                                        value={values.pinCode}
                                        onChange={handleChange('pinCode')}
                                        onBlur={handleBlur}

                                   />
                                   {
                                        (errors.pinCode && touched.pinCode) && <div className='text-danger mt-1'>{errors.pinCode}</div>
                                   }

                              </div>


                              <div className='col-lg-6 col-sm-12 col-xs-12'>
                                   <label className='auth_label' htmlFor="mobileNumber">Mobile Number <span className='fw-600 text-danger'>*</span></label>
                                   <input
                                        type="number"
                                        id="mobileNumber"
                                        className={`valuation_input w-100   mt-1 ${(touched.mobileNumber && errors.mobileNumber) && 'border-danger'}`}
                                        placeholder='Enter mobile number'
                                        value={values.mobileNumber}
                                        onChange={handleChange('mobileNumber')}
                                        onBlur={handleBlur}
                                   />
                                   {
                                        (touched.mobileNumber && errors.mobileNumber) && <div className='text-danger mt-1'>{errors.mobileNumber}</div>
                                   }

                              </div>
                         </div>

                         <div className='mt-4 '>
                              <label className='auth_label' htmlFor="gstNumber">GST Number</label>
                              <input
                                   type="text"
                                   id="gstNumber"
                                   className={`valuation_input w-100 mt-1 ${touched.gstNumber && errors.gstNumber ? 'border-danger' : ''}`}
                                   placeholder='Enter GST number'
                                   value={values.gstNumber}
                                   onChange={handleChange('gstNumber')}
                                   onBlur={handleBlur}
                              />
                              {
                                   (touched.gstNumber && errors.gstNumber) && <div className='text-danger mt-1'>{errors.gstNumber}</div>
                              }

                         </div>
                         <button className='cta-small mt-5' type='submit'>
                              {
                                   isLoading ? <div className='d-flex justify-content-center w-100'>
                                        <CircularProgress style={{ color: "#fff" }} />
                                   </div> : <>Make Payment </>
                              }

                         </button>
                    </form>
               </div>
          </div>

     </>



}

export default Payment