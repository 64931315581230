import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

const COLORS = [
  "#A4A5A5",
  "#494C4C",
  "#222222",
  "#404654",
  "#123769",
  "#000000",
];
const DANGERCOLORS = [
  "#ff7a7a",
  "#e66e6e",
  "#cc6262",
  "#b35555",
  "#994949",
  "#803d3d",
];

const renderCustomizedLabel = (props) => {
  const { cx, cy, fill } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        50
      </text>
    </g>
  );
};

const FundingPie = ({ transactionsEquidam, fundsAllocated }) => {
  const [chartdata, setchartdata] = useState([]);
  useEffect(() => {
    let funddata = [...transactionsEquidam.cInfo.useOfFunds];
    let perc = 0;

    for (const c of funddata) {
      if (transactionsEquidam.cInfo.capitalneeded && c.amount) {
        perc = +(
          (c.amount / transactionsEquidam.cInfo.capitalneeded) *
          100
        ).toFixed(2);
        c.percentage = perc;
      } else c.percentage = 0;
    }
    setchartdata(funddata);
  }, [transactionsEquidam]);
  return (
    <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={chartdata}
            cx={120}
            cy={100}
            innerRadius={60}
            outerRadius={80}
            fill="#4AD7D1"
            paddingAngle={5}
            dataKey="percentage"
            activeShape={renderCustomizedLabel}
          >
            {chartdata.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  transactionsEquidam.cInfo.capitalneeded < fundsAllocated
                    ? DANGERCOLORS[index % DANGERCOLORS.length]
                    : COLORS[index % COLORS.length]
                }
              />
            ))}
            <Label
              value={
                transactionsEquidam.cInfo.capitalneeded
                  ? `${(
                    (fundsAllocated /
                      transactionsEquidam.cInfo.capitalneeded) *
                    100
                  ).toFixed(2)} %`
                  : "0 %"
              }
              position="center"
            />
          </Pie>
          <Tooltip />
          <Legend layout="vertical" wrapperStyle={{
            fontSize: "16px",
            fontWeight: 400,
            width: '100%',
            height: "auto",
            bottom: "-150px"
          }} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FundingPie;
