import React from 'react'
import { Modal, Box } from "@mui/material";
import { CancelIcon } from '../../Icons';
import PropTypes from 'prop-types';


const CustomModal = ({ open, handleClose, header, width, children }) => {

     const style = {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: "2px 2px 8px rgba(83, 83, 83, 0.12)",
          bgcolor: "#fff",
          width: `${width ? width : 568} !important`,
          maxWidht: "90% !important",
          borderRadius: "24px",
          padding: "32px",
          outline: 'none'
     };

     return (
          <Modal
               open={open}

               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
          >
               <Box sx={style}>

                    <div className='d-flex align-items-center justify-content-between mb-md-32'>
                         <p className='body2'>{header}</p>
                         <div onClick={handleClose}>
                              <CancelIcon />
                         </div>
                    </div>

                    <div>
                         {children}
                    </div>


               </Box>
          </Modal >
     )
}

CustomModal.propTypes = {
     header: PropTypes.string
};
export default CustomModal