import React, { useState } from 'react'
import { useFormik } from "formik";
import * as Yup from 'yup';
import api from '../../../axiosAPI';
import { toast } from 'react-toastify';
import { CircularProgress } from "@mui/material";
import { useAuth } from '../../../Utils/Auth';

const MyProfile = () => {

     const { currentUser, setLoadCurrent, loadCurrent } = useAuth();
     const [isLoading, setIsLoading] = useState(false);

     const validationSchema = Yup.object().shape({
          firstName: Yup.string(),
          lastName: Yup.string(),
          organizationName: Yup.string(),
          email: Yup.string()
               .email("Please enter your email"),
     });
     const onSubmit = (values) => {
          setIsLoading(true)
          api.post('/user/change-details', values).then(res => {
               if (res.data.success) {
                    setIsLoading(false)
                    toast.success(`Basic Details updated!`);
                    setLoadCurrent(!loadCurrent);
                    // actions.resetForm();
               }
          }).catch(error => {
               setIsLoading(false)
               toast.error(error.response.data.message, { position: "bottom-right" });
          })

     }
     const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({ //isSubmitting
          initialValues: {
               firstName: currentUser?.firstName,
               lastName: currentUser?.lastName,
               organizationName: currentUser?.organizationName,
               email: currentUser?.email,
          },
          onSubmit,
          enableReinitialize: true,
          validationSchema: validationSchema
     })
     return (
          <>
               <div className='dasboard_payment_history_root'>
                    <div className='dasboard_payment_history_max white-background p-32'>
                         <p className='fw-500 heading_s'>Basic details</p>
                         <form className='mt-30' autoComplete="off" onSubmit={handleSubmit}>
                              <div className='row'>
                                   <div className='col-lg-6 col-sm-12'>
                                        <label className='auth_label' htmlFor="firstName">First Name</label>
                                        <input
                                             type="text"
                                             id="firstName"
                                             className={`valuation_input w-100 mt-1 ${touched.firstName && errors.firstName ? 'border-danger' : ''}`}
                                             placeholder='Enter first name'
                                             value={values.firstName}
                                             onChange={handleChange('firstName')}
                                             onBlur={handleBlur}
                                        />

                                        {
                                             (touched.firstName && errors.firstName) && <div className='text-danger mt-1'>{errors.firstName}</div>
                                        }

                                   </div>

                                   <div className='col-lg-6 col-sm-12'>
                                        <label className='auth_label' htmlFor="lastName">Last Name</label>
                                        <input
                                             type="text"
                                             id="lastName"
                                             className={`valuation_input w-100  mt-1 ${(touched.lastName && errors.lastName) && 'border-danger'}`}
                                             placeholder='Enter last name'
                                             value={values.lastName}
                                             onChange={handleChange('lastName')}
                                             onBlur={handleBlur}
                                        />
                                        {
                                             (touched.lastName && errors.lastName) && <div className='text-danger mt-1'>{errors.lastName}</div>
                                        }

                                   </div>

                              </div>

                              <div className='mt-3'>
                                   <label className='auth_label' htmlFor="organizationName">Organisation Name</label>
                                   <input
                                        type="text"
                                        id="organizationName"
                                        className={`valuation_input w-100  mt-1 ${(touched.organizationName && errors.organizationName) && 'border-danger'}`}
                                        placeholder='Enter your organisation name'
                                        value={values.organizationName}
                                        onChange={handleChange('organizationName')}
                                        onBlur={handleBlur}
                                   />
                                   {
                                        (errors.organizationName && touched.organizationName) && <div className='text-danger mt-1'>{errors.organizationName}</div>
                                   }
                              </div>

                              <div className='mt-3'>
                                   <label className='auth_label' htmlFor="email">Email</label>
                                   <input
                                        type="email"
                                        id="email"
                                        className={`valuation_input w-100  mt-1 ${(errors.email && touched.email) && 'border-danger'}`}
                                        placeholder='Enter your Email here'
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur}
                                   />
                                   {
                                        (errors.email && touched.email) && <div className='text-danger mt-1'>{errors.email}</div>
                                   }

                              </div>
                              <button className='cta-small mt-32' type='submit'>
                                   {
                                        isLoading ? <div className='d-flex justify-content-center w-100'>
                                             <CircularProgress style={{ color: "#fff" }} />
                                        </div> : <>Update details</>
                                   }

                              </button>
                         </form>
                    </div>
               </div>
          </>
     )
}

export default MyProfile