import React, { useEffect, useState } from "react";
import FundingPie from "./Chart";

const TransactionCharts = ({ transactionsEquidam, currency }) => {
  const [fundsAllocated, setfundsAllocated] = useState(0);
  useEffect(() => {
    let t = 0;
    for (const c of transactionsEquidam.cInfo.useOfFunds) {
      if (c.amount) t += c.amount;
    }
    setfundsAllocated(t);
  }, [transactionsEquidam]);
  //   console.log(fundsAllocated);
  return (
    <div>
      <div className="mt-24">
        <p className="title1">Capital Needed</p>
        <p className="title1 mt-16">{`${currency} ${transactionsEquidam.cInfo.capitalneeded
          ? transactionsEquidam.cInfo.capitalneeded
          : 0
          }`}</p>
      </div>
      <div className="mt-24">
        <p className="title1">Funds Allocated</p>
        <p className="body2 mt-16">
          Capital is a resource, not your final goal. It is going to be easier
          to convince investors if they see that they just need to put more fuel
          on the fire to make it grow, rather than putting the capital to build
          the engine.
        </p>
        <p className="title1 mt-16"> {`${currency} ${fundsAllocated}`}</p>
      </div>
      <FundingPie
        transactionsEquidam={transactionsEquidam}
        fundsAllocated={fundsAllocated}
      />
      {transactionsEquidam.cInfo.capitalneeded > fundsAllocated ? (
        <div style={{ marginTop: 200 }}>
          <p className="title2">100% NOT REACHED!</p>
          <p className="body2">
            You are not allocating the whole capital raised
          </p>
        </div>
      ) : transactionsEquidam.cInfo.capitalneeded < fundsAllocated ? (
        <div style={{ marginTop: 200 }}>
          <p className="title2">EXCEEDING 100%,</p>
          <p className="body2">
            You are allocating more funds than the ones raised
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default TransactionCharts;
