export const FinantialDrawerData = {
  Revenues: {
    title: "Revenues",
    what: "Yearly estimated revenues",
    tips: "Revenues are the core of every solid financial model. They are the first proof that your idea is sounding and you are capable of recognizing and addressing the needs of the customers, who shall be willing to pay for your product/service. A big part of the financials are modelled after your Revenues, therefore you need to pay extra-attention in filling in this field. Be particularly careful with your growth rate: it should be reasonable, as Revenues do not generally go from 0 to 1 trillion in 1 year.",
    articletopic: "Startup Projections, how to start",
    article:
      "https://www.equidam.com/startup-projections-how-to-start/?ref=pltfrm",
  },
  "Cost of goods sold": {
    title: "Cost of goods sold",
    what: "Operating costs linked to the amount of products/services sold (e.g. cost for raw materials)",
    tips: `If you produce physical goods, you should account for the raw materials; if you run a store chain, you may consider the acquired goods and their storing costs; in case you provide an online service, you should consider the cost of the servers hosting your software, and many other examples. To have solid financial projections, costs should grow proportionally with revenues (having "billions" of revenues and very low costs is highly unlikely)
        <br/><br/>
        If you deal in physical products (both production and trading), when you estimate COGS be careful in considering the change in Inventory, i.e. the difference between the goods you have stored at the end and those you had at the beginning of the year. You need to subtract this amount to the absolute size of the COGS. In fact, initial inventory accounts for raw material you have consumed or goods you have sold in your activity during the current year, while final inventory represents the ones you will use or sell in the ensuing periods.`,
  },
  Salaries: {
    title: "Salaries",
    what: `Pay-rolls of the employees and contractor wages, including taxes and directly linked costs`,
    tips: `This field should include the compensation for the people you plan to involve in your company. As nowadays different form of employment may be used in innovative ventures, considering only the pay-rolls may misrepresent the actual cost of work. If founders hire themselves as contractors, or if you have external collaborators that are practically equivalent to employees in terms of activity and commitment, you should consider their compensation here.`,
  },
  "Operating Expenses": {
    title: "Operating Expenses",
    what: `Other operating costs not varying according to the amount of products/services sold, namely, fixed costs (e.g. office rent)`,
    tips: `Every company faces a series of costs that are not directly linked to the number of sold units, which does not mean they do not grow over time, just they are less proportional to Revenues than COGS. Such category of costs includes marketing, administration, utilities, software services, rental and maintenance costs.`,
  },
  "D&A": {
    title: "D&A",
    what: `Depreciation and Amortization`,
    howTitle: `How Equidam computes it`,
    howDesc: `D&A = Revenues * Industry average percentage of D&A on Revenues (based on 35,000+ public traded companies worldwide)`,
    tips: `Every company faces a series of costs that are not directly linked to the number of sold units, which does not mean they do not grow over time, just they are less proportional to Revenues than COGS. Such category of costs includes marketing, administration, utilities, software services, rental and maintenance costs.`,
  },
  Interest: {
    title: "Interest",
    what: `Interest paid on debt`,
    howTitle: `How Equidam computes it`,
    howDesc: `Equidam default value:
        <br/><br/>
        Standard interest = Debt at the end of the year * 5%<br/>
        Coverage ratio = EBIT / standard interest<br/><br/>

        According to different values of coverage ratios, a spread is assigned to compensate the risk, as companies with low coverage ratios have a higher risk of not being able to cover the debt payments with their earnings. The spread of the company is then added to the country-specific risk free rate and results in the final interest percentage indicated on the report.<br/><br/>

        The result is then:<br/>
        Interest = Debt at the end of the year * final interest<br/><br/>

        Equidam also takes into account the possibility you experience negative cash at the end of the year. If you are in this situation, it means that your negative cash flow has burnt out all the cash you considered in your balance sheet at the end of the past period. It implies you have to borrow from banks and pay an interest until your cash at the end of the year becomes positive again, otherwise you would face bankruptcy.<br/><br/>

        The final formula is therefore:<br/>
        Interest = Debt at the end of the year * final interest + Negative Cash at the end of the year * standard banking interest`,
  },
  Taxes: {
    title: "Taxes",
    what: `Taxes paid in cash`,
    howTitle: `How Equidam computes it`,
    howDesc: `Equidam default value:<br/><br/>

        Taxes = (EBIT - Interest) * Country specific tax rate (based on external statistics)<br/><br/>

        Equidam also accounts for tax carry forward (tax credits that you can benefit from) for future years. If in the current year a carry forward is present, insert it in the Balance Sheet section on the Deferred Tax Assets input field. The deferred tax assets for future years are automatically calculated based on each country standard corporate tax rate.<br/><br/>

        Taxes = (EBIT - Interest) * Country specific tax rate - Deferred Tax Assets`,
  },
  Receivables: {
    title: "Receivables",
    what: `Revenues not yet cashed`,
    howTitle: `How Equidam computes it`,
    howDesc: `Equidam default value:<br/><br/>

        Receivables = Revenues * Industry average percentage of Receivables on Revenues (based on 35,000+ public traded companies worldwide)`,
    tips: `Receivables are credits your clients owe to you. In many markets it is common practice to delay the payment date of invoices, in some situations it is part of the specific agreement you reach with the customer (therefore it depends on the bargaining power of the clients), in some others your clients may be in financial distress. Whatever the reason is, receivables mark the difference between your revenues and your cash inflows.`,
  },
  Inventories: {
    title: "Inventories",
    what: `Value of products not yet sold`,
    howTitle: `How Equidam computes it`,
    howDesc: `Equidam default value:

        Inventory = Revenues * Industry average percentage of Inventory on Revenues (based on 35,000+ public traded companies worldwide)`,
    tips: `If you deal in physical products, you already wrote off change in inventory in your COGS, but you actually spent that money to buy raw materials and components. Inventory accounts for what will be consumed and/or transformed in your productive process over next years, and therefore refers to those period from an accounting point of view, but have generated cash outflow in the current period.`,
  },
  Paybles: {
    title: "Paybles",
    what: `Costs not yet paid`,
    howTitle: `How Equidam computes it`,
    howDesc: `Equidam default value:<br/><br/>

        Payables = Revenues * Industry average percentage of Payables on Revenues (based on 35,000+ public traded companies worldwide)`,
    tips: `Payables are debts you owe to your suppliers. In many markets it is common practice to delay the payment date of invoices, in some situations it is part of the specific agreement you reach with the supplier (therefore it depends on your bargaining power), in some others you may negotiate longer payments because you are in financial distress. Whatever the reason is, payables mark the difference between your costs and your cash outflows.`,
  },
  "Capital Expenditures": {
    title: "Capital Expenditures",
    what: `Expenses of the year that will determine the creation of an asset for the company, being tangible or intangible, and will therefore be depreciated or amortized. These can include buildings, patents, etc.`,
    tips: `If you are going to dispose of some of your assets in the projected period, you can show the cash you plan to obtain from the divestment by inputting a negative number`,
  },
  "Debt at the End of the Year": {
    title: "Debt at the End of the Year",
    what: `The outstanding LONG-TERM debt of the company, or fixed liabilities (i.e. with maturity longer than 1 year)`,
    tips: `In subsequent years, consider the amount of debt repaid and fill in the difference.<br/><br/>

        "Debt at the end of the year" for the first column of projections is equivalent to the “Debt due beyond one year time” of the balance sheet. If you update it in one of the two inputs, it will automatically change in the other one.`,
    example: `If the company has $ 30,000 of debt today and in year 1 $ 5,000 are repaid, the value in year 2 should be $ 25,000.`,
  },
  "Fundraising Plan": {
    title: "Fundraising Plan",
    what: `The equity future fundraising for the coming years excluding the ongoing capital raise. You can use negative values in case of dividends or payouts.`,
    tips: `The ongoing equity fundraising should be inserted in the “Current Funding Round” section of the "Transaction" tab.<br/><br/>

        The future plan of fundraising does not affect the current valuation..`,
  },
  EBITDA: {
    title: "EBITDA",
    what: `Earning Before Interest, Taxes, Depreciation and Amortization`,
    howTitle: `How Equidam computes it`,
    howDesc: `EBITDA = Revenues - Cost of Goods Sold - Salaries - Other Operating Expenses`,
    tips: `EBITDA is carefully considered by investors. It is a quick proxy of the company’s profitability and cash generation, moreover it is directly involved in computing terminal valuation in the DCF with Multiple and Venture Capital methods. It represents the first level of operating result of your business abilities, as it is not affected by your past investments (D&A), your financial structure (Interests), and your country (Taxes). The ratio of EBITDA on Revenues may immediately provide a sounding solidity check of your projections: if it goes above 30% you should put extra-attention in your assumptions on Revenues and costs, and may be willing to revise them one more time before proceeding with the financials.`,
  },
  EBIT: {
    title: "EBIT",
    what: `Earning Before Interest and Taxes`,
    howTitle: `How Equidam computes it`,
    howDesc: `EBIT = EBITDA - D&A`,
    tips: `EBIT is the most acknowledged measure of profitability of a company. Although less informative than EBITDA for valuation, it is an important indicator of how your business will cope over years. By taking into account also the theoretical loss of value of your investments over time (namely, D&A), it describes your business result net of financial structure and country specific taxation. Thus, EBIT represents a business-specific result, which is useful in peer benchmarking.`,
  },
  "Working Capital": {
    title: "Working Capital",
    what: `Short term operational assets (credit due by clients and inventory) minus short term operational liabilities (debt with suppliers)`,
    howTitle: `How Equidam computes it`,
    howDesc: `Working capital = Receivables + Inventory - Payables`,
    tips: `To be able to compute the free cash flow to equity (on which valuation is based) starting from net profit, a series of adjustments is necessary to switch from the accounting to the cash flow cycle. In fact, accounting counts revenues, costs, etc. when they were “booked” (by, for example, sending or receiving an invoice), that may differ (as it does in most of the cases) from when they were actually “cashed”.
    <br/> <br/>
    The size of the working capital defines how capital intensive is your business. At the end of the year, if you have many credits with you customer, a warehouse full of raw materials and components, and few debts with your supplier, your business has proved to be very capital intensive. Vice versa, if you have been able to get paid immediately, have small or no inventory, and negotiate with your suppliers delayed payments, you have a low capital intensive business. Capital intensity is chiefly linked to the industry, and this is why Equidam uses average percentage of its components on Revenues to compute it.`,
  },
  "Net Profit": {
    title: "NET PROFIT",
    what: `Net result of the company for the year`,
    howTitle: `How Equidam computes it`,
    howDesc: `Net profit = EBIT - Interest - Taxes`,
  },
  "Change in Working capital": {
    title: "Change in Working capital",
    what: `The cash absorbed (if positive) or generated (if negative) by managing the inventory and the relationship with suppliers and customers`,
    howTitle: `How Equidam computes it`,
    howDesc: `Change in Working capital = Working capital of a certain year - Working capital of the previous year`,
  },
  "Change in Outstanding Debt": {
    title: "Change in Outstanding Debt",
    what: `Change in outstanding debt for principal repayment (if negative) or new debt raised (if positive)`,
    howTitle: `How Equidam computes it`,
    howDesc: `Change in Debt = Debt at the end of the year - Debt at the end of the previous year`,
  },
  "Free Cash flow to Equity": {
    title: "Free Cash flow to Equity",
    what: `Cash available to shareholders in the year`,
    howTitle: `How Equidam computes it`,
    howDesc: `Free cash flow to equity = Net Profit + D&A - Change in Working Capital - Capital Expenditures + Change in outstanding debt`,
  },
  "Free Cash flow": {
    title: "Free Cash flow",
    what: `Cash going to the bank account after every inflow from clients and investors and outflow to suppliers and investors has been paid`,
    howTitle: `How Equidam computes it`,
    howDesc: `Free cash flow = Free cash flow to equity + Future Fundraising`,
  },
};
