import React from "react";

import PropTypes from "prop-types";

import TransacElement from "../TransacElement";
import "./step3.css";
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';

import { toast } from "react-toastify";
import Information from "../Information";
import TransactionCharts from "./TransactionCharts";
import { getcurrencylist } from "../Lists/Currency";

const FundingRounds = (props) => {
  const {
    transactions,
    fundingStatus,
    fundingRow,
    deleteFundingRow,
    setFundingRows,
    setFundingStatus,
    handleFundingStatus,

    currency,

    transRef,
    value,
    transactionsEquidam,
    settransactionsEquidam,
    handlesave,
  } = props;
  let { contents } = transactions ?? {};
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  }

  const AddFundingRow = () => {
    if (
      !fundingStatus.equitypct ||
      !fundingStatus.amountraised ||
      !fundingStatus.valuation ||
      !fundingStatus.closeddate
    )
      return toast.error("fill all fields");
    let newState = { ...transactionsEquidam };
    fundingStatus.opendate = false;
    fundingStatus.closeddate = new Date(fundingStatus.closeddate).toISOString();
    fundingStatus.closeddatestring = formatDate(fundingStatus.closeddate);
    newState.closedrounds = [...fundingRow, fundingStatus];

    settransactionsEquidam(newState);
    setFundingRows((prev) => [...prev, fundingStatus]);
    setFundingStatus({
      equitypct: "",
      amountraised: "",
      valuation: "",
      closeddate: "",
    });
  };

  const FundingRoundContent = {
    0: "Give investors a complete picture of your current funding round by specifying here the capital needed and how it will be used.",
    1: "Keep track of your previous valuations and their development over time",
  };

  React.useEffect(() => {
    if (transRef) {
      transRef.current?.scrollIntoView({
        behaviour: "smooth",
      });
    }
  }, [transRef]);

  return (
    <>
      <div className="main-tab-content">
        <div className="" ref={transRef}>
          <h6 className="title2">Transactions</h6>
          <p>{FundingRoundContent[value]}</p>
          <Information />
        </div>
        <div className="tabs-content mt-16">
          <form id="transactions" onSubmit={handlesave}>
            <div className="row">
              <div className="col-8">
                {" "}
                <div className="white-background p-32 mt-24">
                  <div className="">
                    <p className="title2">Current Funding Round</p>
                    <p className="caption color_grey_46 mb-16 mt-8">
                      Give investors a complete picture of your current funding
                      round by specifying here the capital needed and how it
                      will be used.
                    </p>
                  </div>
                  <hr style={{ margin: "34px 0" }} />
                  {contents
                    ? contents.map((content, i) => (
                      <TransacElement
                        key={i}
                        content={content}
                        currency={currency}
                      />
                    ))
                    : null}
                </div>
              </div>
              <div className="col-4">
                <TransactionCharts
                  transactionsEquidam={transactionsEquidam}
                  currency={
                    getcurrencylist().find((v) => v.code === currency)?.symbol
                  }
                />
              </div>
            </div>
            <div className="white-background p-32 mt-24">
              <div>
                <p className="title2">Past fundings</p>
                <p>
                  Give investors a complete picture of your current funding
                  round by specifying here the capital needed and how it will be
                  used.
                </p>
              </div>

              <table className="w_100 mt-32">
                <thead className="pastfundingthead nunito">
                  <tr>
                    <th className="body1 text-start">Equity{"(%)"}</th>
                    <th className="body1 text-start">
                      Capital Raised{`(${currency})`}
                    </th>
                    <th className="body1 text-start">
                      Valuation{`(${currency})`}
                    </th>
                    <th className="body1 text-start">Closing Date</th>
                    <th className="body1 text-start"></th>
                  </tr>
                </thead>
                <tbody className="pastfundingtbody">
                  <tr>
                    <td>
                      <input
                        type="number"
                        name="equitypct"
                        value={fundingStatus.equitypct}
                        onChange={handleFundingStatus}
                        placeholder="eg: 2"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="amountraised"
                        value={fundingStatus.amountraised}
                        onChange={handleFundingStatus}
                        placeholder="eg: 2"
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        name="valuation"
                        value={fundingStatus.valuation}
                        onChange={handleFundingStatus}
                        placeholder="eg: 2"
                      />
                    </td>
                    <td>
                      <input
                        type="date"
                        name="closeddate"
                        value={fundingStatus.closeddate}
                        onChange={handleFundingStatus}
                      />
                    </td>
                    <td>
                      <Tooltip title="Add More Rows">
                        <button
                          type="button"
                          className="addrowsbtn"
                          onClick={AddFundingRow}
                        >
                          <AddIcon />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>

                  {fundingRow
                    ? fundingRow.map((row) => {
                      return (
                        <>
                          <hr style={{ margin: "14px 0" }} />
                          <tr>
                            <td>{row.equitypct}</td>
                            <td>{row.amountraised}</td>
                            <td>{row.valuation}</td>
                            <td>{formatDate(row.closeddate)}</td>
                            <td>
                              <Tooltip title="Remove Row">
                                <button
                                  type="button"
                                  className="removebtn fw-600 text-danger"
                                  onClick={() =>
                                    deleteFundingRow(row.closeddate)
                                  }
                                >
                                  Remove
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        </>
                      );
                    })
                    : ""}
                </tbody>
              </table>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

FundingRounds.propTypes = {
  transactions: PropTypes.any,
  handleNext: PropTypes.any,
  fundingStatus: PropTypes.any,
  handleFundingStatus: PropTypes.any,
  AddFundingRow: PropTypes.any,
  fundingRow: PropTypes.any,
  deleteFundingRow: PropTypes.any,
  setFundingRows: PropTypes.any,
  setFundingStatus: PropTypes.func,
};
export default FundingRounds;
