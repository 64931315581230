import React, {
     useState,
     //  useEffect
} from "react";
// import { useAuth } from "../../../../Utils/Auth";

import AddIcon from '@mui/icons-material/Add';
import { toast } from "react-toastify";
import Sharechart from "./Sharechart";
import { CircularProgress } from '@mui/material';
import "./index.css";

import api from '../../../../axiosAPI';
import { useParams } from "react-router-dom";
import Information from "../Information";
const index = ({
     companyInfoRef,
     // companyInfo,
     companyname,
     setcompanyname,
     description,
     setdescription,
     website,
     setwebsite,
     linkedin,
     setlinkedin,
     twitter,
     settwitter,
     facebook,
     setfacebook,
     address,
     setaddress,
     competitors,
     setcompetitors,
     shareholders,
     setshareholders,
     logo,
     setlogo,
     handlesave,
     getcompetitors,
     getshareholders,
     // companymongoDocument,
     AddFundingRow,
}) => {
     let { valuation_id } = useParams();

     const [islogoloading, setislogoloading] = useState(false);
     const [exceedingLimit, setexceedingLimit] = useState(false);

     const storeObjectiveNameVal = (val, i) => {
          var inputObjectives = [...competitors];
          inputObjectives[i].competitorname = val;
          setcompetitors(inputObjectives);
     };
     const storeObjectiveUrlVal = (val, i) => {
          var inputObjectives = [...competitors];
          inputObjectives[i].competitorurl = val;
          setcompetitors(inputObjectives);
     };
     const storeShareNameVal = (val, i) => {
          var inputObjectives = [...shareholders];
          inputObjectives[i].shareholdername = val;
          setshareholders(inputObjectives);
     };
     const storeSharePerVal = (val, i) => {
          var inputObjectives = [...shareholders];
          inputObjectives[i].shareholderpercentage = val;
          setshareholders(inputObjectives);
          let count = 0;
          for (const s of inputObjectives) {
               count += +s.shareholderpercentage;
          }
          if (count > 100) {
               setexceedingLimit(true);
          } else {
               setexceedingLimit(false);
          }
     };

     // useEffect(()=>{
     //   AddFundingRow('c')
     // },[shareholders])
     const deleteFundingRow = async (i, type) => {
          if (type === "c" && i) {
               await api.delete(
                    `/valuation/companies/${valuation_id}/competitors/${i}`
               );
               getcompetitors();
          } else if (type === "s") {
               let newcompetitors = [];
               let deleteshareholders = shareholders;
               const newd = deleteshareholders.filter((s, id) => {
                    return i !== id;
               });

               for (const c of newd) {
                    if (c.shareholdername.length > 0 && c.shareholderpercentage > 0)
                         newcompetitors.push({
                              name: c.shareholdername,
                              percentage: +c.shareholderpercentage,
                         });
               }

               await api.post(
                    `/valuation/companies/${valuation_id}/shareholders`,
                    { shareholders: newcompetitors }
               );
               getshareholders();
          }
     };



     // useEffect(() => {
     //   if (
     //     user.type === "startup" &&
     //     companymongoDocument &&
     //     companymongoDocument.clonedReport === false &&
     //     companymongoDocument.compnanyInfoFilled === false
     //   ) {
     //     setdescription(profile.description);

     //     setwebsite(profile.website);
     //     setlinkedin(profile.socialConnects.linkedin);
     //     setaddress(
     //       `${profile.location.address}, ${profile.location.postalcode}, ${profile.location.country}`
     //     );
     //   }
     // }, [companyInfo, companymongoDocument]);

     const handleLogoUpload = (e) => {
          const image = e.target.files[0];
          if (image.size > 1024 * 1024) {
               toast.error("File size should be less  than 1MB");
               return;
          }
          if (
               image.type !== "image/jpg" &&
               image.type !== "image/png" &&
               image.type !== "image/jpeg"
          ) {
               toast.error("Only jpg/png files are allowed!");
               return;
          }
          const formdata = new FormData();
          formdata.append("valuationlogo", image);
          setislogoloading(true);
          api({
               method: "post",
               url: `/valuation/logo`,
               data: formdata,
          })
               .then((res) => {
                    const { data } = res;
                    if (data) {
                         setislogoloading(false);
                         toast.success("Company logo uploaded!");
                         setlogo(data.location);
                    }
               })
               .catch(() => {
                    toast.error("Error in upload!");
               });
     };

     return (
          <div className="main-tab-content valuation_company_info">
               <div className="" ref={companyInfoRef}>
                    <div>
                         <h6 className="title2" id="valuation-head-id">Company Info</h6>
                         <p className="my-10">
                              Give basic details of your company
                         </p>
                         <Information />
                    </div>
               </div>

               <form id="companyinfo" onSubmit={handlesave} className="mt-24">

                    <div className="white-background p-32">
                         <div>
                              <p className="title3">Company Profile</p>
                              <p>In this section you can edit the information about your company</p>
                         </div>
                         <hr style={{ margin: "34px 0" }} />
                         <div className="row m-0">
                              <div className="col-lg-9 col-sm-9 col-md-12 br1">
                                   <div className="tabs-content">
                                        <div className="form-group row_align m-0">
                                             <p className="valuation_label">1. Company Name <span className="text-danger fw-500" style={{ fontSize: '1.3rem' }}>*</span></p>
                                             <input
                                                  required
                                                  type="text"
                                                  className="valuation_input w-100 mt-2"
                                                  aria-describedby="emailHelp"
                                                  placeholder="eg. ABC. Inc"
                                                  value={companyname}
                                                  onChange={(e) => setcompanyname(e.target.value)}
                                             />
                                        </div>

                                        <hr style={{ margin: "34px 0" }} />

                                        <div>
                                             <p className="valuation_label">2. Business description <span className="text-danger fw-500" style={{ fontSize: '1.3rem' }}>*</span></p>
                                             <textarea
                                                  required
                                                  type="text"
                                                  style={
                                                       description.length >= 200
                                                            ? { minHeight: "120px", border: "1.5px solid red" }
                                                            : { minHeight: "120px", border: "1px solid #cacaca" }
                                                  }
                                                  className="valuation_input w-100 mt-2"
                                                  aria-describedby="emailHelp"
                                                  placeholder="eg. Our company aims in improving..."
                                                  value={description}
                                                  onChange={(e) => setdescription(e.target.value)}
                                             />

                                             <p className="fw-600">
                                                  {" "}

                                                  {`${description.length}/200`}
                                                  &nbsp; &nbsp; &nbsp;

                                                  {description.length > 200 ? <p className="text-danger">Input too long</p> : ""}
                                             </p>
                                        </div>

                                        <hr style={{ margin: "34px 0" }} />

                                        <div className="row m-0">
                                             <div
                                                  className="col-sm-6 col-12 ps-0 mb-24"
                                             >
                                                  {" "}
                                                  <div>
                                                       <p className="valuation_label">
                                                            <svg
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 width="18"
                                                                 height="18"
                                                                 fill="none"
                                                                 viewBox="0 0 18 18"
                                                                 className="me-2"
                                                            >
                                                                 <path
                                                                      fill="#444"
                                                                      d="M12.634 10.666c.066-.55.116-1.1.116-1.667 0-.566-.05-1.116-.116-1.666h2.816c.134.533.217 1.091.217 1.666s-.083 1.134-.217 1.667h-2.816zm-1.475 4.633c.5-.925.883-1.925 1.15-2.966h2.458a6.692 6.692 0 01-3.608 2.966zm-.209-4.633h-3.9c-.083-.55-.133-1.1-.133-1.667 0-.566.05-1.125.133-1.666h3.9c.075.541.134 1.1.134 1.666 0 .567-.059 1.117-.134 1.667zM9 15.633c-.691-1-1.25-2.109-1.591-3.3h3.183c-.342 1.191-.9 2.3-1.592 3.3zM5.667 5.666H3.234a6.602 6.602 0 013.6-2.967c-.5.925-.875 1.925-1.167 2.967zm-2.433 6.667h2.433c.292 1.041.667 2.041 1.167 2.966a6.673 6.673 0 01-3.6-2.966zm-.684-1.667a6.868 6.868 0 01-.216-1.667c0-.575.083-1.133.216-1.666h2.817c-.067.55-.117 1.1-.117 1.666 0 .567.05 1.117.117 1.667H2.55zM9 2.358c.692 1 1.25 2.116 1.592 3.308H7.409A11.35 11.35 0 019 2.358zm5.767 3.308h-2.458a13.05 13.05 0 00-1.15-2.967 6.656 6.656 0 013.608 2.967zM9 .666C4.392.666.667 4.416.667 8.999A8.333 8.333 0 109 .666z"
                                                                 ></path>
                                                            </svg>
                                                            Website
                                                       </p>
                                                       <input
                                                            type="url"
                                                            className="w-100 valuation_input mt-2"
                                                            aria-describedby="emailHelp"
                                                            placeholder="eg: www.asdfg.com"
                                                            value={website}
                                                            onChange={(e) => setwebsite(e.target.value)}
                                                       />
                                                  </div>
                                             </div>

                                             <div className="col-sm-6 col-12 pe-0 mb-24">
                                                  {" "}
                                                  <div className="form-group">
                                                       <p className="valuation_label">
                                                            <svg
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 width="14"
                                                                 height="14"
                                                                 fill="none"
                                                                 viewBox="0 0 14 14"
                                                                 className="me-2"
                                                            >
                                                                 <path
                                                                      fill="#000"
                                                                      d="M1.688.125A1.574 1.574 0 00.125 1.688v10.624c0 .855.709 1.563 1.563 1.563h10.625c.854 0 1.563-.708 1.563-1.563V1.688c0-.855-.709-1.563-1.563-1.563H1.688zm0 1.25h10.625c.178 0 .313.134.313.313v10.624a.307.307 0 01-.313.313H1.688a.306.306 0 01-.313-.313V1.688c0-.179.135-.313.313-.313zm1.836 1.074a1.075 1.075 0 10-.051 2.149 1.075 1.075 0 00.051-2.149zm5.644 2.852c-.896 0-1.491.49-1.738.957h-.039v-.82H5.633v5.937h1.836v-2.93c0-.774.154-1.523 1.113-1.523.945 0 .958.874.958 1.562v2.891h1.836V8.113c0-1.596-.34-2.812-2.207-2.812h-.001zm-6.543.136v5.938h1.856V5.437H2.625z"
                                                                 ></path>
                                                            </svg>
                                                            Linkedin
                                                       </p>
                                                       <input
                                                            type="url"
                                                            className="w-100 valuation_input mt-2"
                                                            aria-describedby="emailHelp"
                                                            placeholder="eg: linkedin.com/company/asdfg"
                                                            value={linkedin}
                                                            onChange={(e) => setlinkedin(e.target.value)}
                                                       />
                                                  </div>
                                             </div>

                                             <div className="col-sm-6 col-12 ps-0">
                                                  {" "}
                                                  <div className="form-group">
                                                       <p className="valuation_label">
                                                            <svg
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 width="20"
                                                                 height="20"
                                                                 fill="none"
                                                                 viewBox="0 0 20 20"
                                                                 className="me-2"
                                                            >
                                                                 <path
                                                                      fill="#000"
                                                                      d="M14.166 7.456a3.453 3.453 0 01-.981.265c.353-.208.623-.538.751-.93a3.466 3.466 0 01-1.086.408 1.717 1.717 0 00-1.248-.532c-.944 0-1.709.754-1.709 1.683 0 .132.015.26.044.384a4.878 4.878 0 01-3.523-1.759 1.652 1.652 0 00-.232.846c0 .584.303 1.1.761 1.401a1.73 1.73 0 01-.775-.21v.02a1.692 1.692 0 001.372 1.65 1.755 1.755 0 01-.773.028 1.707 1.707 0 001.597 1.17 3.463 3.463 0 01-2.531.696 4.887 4.887 0 002.62.758c3.145 0 4.864-2.565 4.864-4.79l-.006-.219c.336-.235.625-.53.855-.869z"
                                                                 ></path>
                                                                 <path
                                                                      fill="#000"
                                                                      d="M5 2.643c-.442 0-1.354.378-1.667.69-.312.313-.69 1.225-.69 1.667v10c0 .442.378 1.354.69 1.667.313.313 1.225.69 1.667.69h10c.442 0 1.354-.377 1.666-.69.313-.313.69-1.225.69-1.667V5c0-.442-.377-1.354-.69-1.666-.312-.313-1.224-.69-1.666-.69H5zm0-.976h10A3.334 3.334 0 0118.333 5v10A3.333 3.333 0 0115 18.334H5A3.333 3.333 0 011.667 15V5A3.333 3.333 0 015 1.667z"
                                                                 ></path>
                                                            </svg>
                                                            Twitter
                                                       </p>
                                                       <input
                                                            type="url"
                                                            className="w-100 valuation_input mt-2"
                                                            aria-describedby="emailHelp"
                                                            placeholder="eg: twitter.com/asdfg"
                                                            value={twitter}
                                                            onChange={(e) => settwitter(e.target.value)}
                                                       />
                                                  </div>
                                             </div>

                                             <div className="col-sm-6 col-12 pe-0">
                                                  {" "}
                                                  <div className="form-group">
                                                       <p className="valuation_label">
                                                            <svg
                                                                 xmlns="http://www.w3.org/2000/svg"
                                                                 width="16"
                                                                 height="16"
                                                                 fill="none"
                                                                 viewBox="0 0 16 16"
                                                                 className="me-2"
                                                            >
                                                                 <path
                                                                      fill="#000"
                                                                      d="M15.188.188H.813a.624.624 0 00-.625.625v14.375c0 .346.28.625.625.625h14.375c.346 0 .625-.279.625-.625V.813a.624.624 0 00-.625-.625zm-.625 14.375h-3.596v-4.8h2.031l.305-2.358h-2.336V5.9c0-.683.19-1.148 1.168-1.148h1.248v-2.11c-.216-.029-.957-.093-1.82-.093-1.8 0-3.033 1.1-3.033 3.117v1.738H6.495v2.358h2.037v4.803H1.438V1.438h13.125v13.125z"
                                                                 ></path>
                                                            </svg>
                                                            Facebook
                                                       </p>
                                                       <input
                                                            type="url"
                                                            className="w-100 valuation_input mt-2"
                                                            aria-describedby="emailHelp"
                                                            placeholder="eg: facebook.com/asdfg"
                                                            value={facebook}
                                                            onChange={(e) => setfacebook(e.target.value)}
                                                       />
                                                  </div>
                                             </div>
                                        </div>

                                        <hr style={{ margin: "34px 0" }} />

                                        <div>
                                             <p className="valuation_label">Company Address</p>

                                             <textarea
                                                  type="text"
                                                  className="w-100 valuation_input mt-2"
                                                  aria-describedby="emailHelp"
                                                  placeholder="eg: Schiedamse Vest 154, 3011 BH Rotterdam, the Netherlands"
                                                  value={address}
                                                  onChange={(e) => setaddress(e.target.value)}
                                             />
                                        </div>
                                   </div>
                              </div>

                              <div className="col-lg-3 col-sm-12 col-md-3 mt-4">
                                   <div className="auth_upload ">
                                        {logo ? (
                                             <>
                                                  <img src={logo} alt="logo" className="companyLogo border rounded " width={"100%"} height={150} />
                                                  <p
                                                       className="font12 mt-1 fw-600 text-danger"
                                                       style={{
                                                            cursor: "pointer",
                                                            backgroundImage:
                                                                 "url(/storage/images/companies/716cid964d3d9418ca40b31eb83c0eb7ca78c9.png')",
                                                       }}
                                                       onClick={() => setlogo(null)}
                                                  >
                                                       Delete
                                                  </p>
                                             </>
                                        ) : (
                                             <>
                                                  {islogoloading ? (
                                                       <CircularProgress />
                                                  ) : (
                                                       <>
                                                            <label htmlFor="clogo">
                                                                 <div className="com_logo_" onClick={{}}>
                                                                      Upload
                                                                 </div>
                                                                 <input
                                                                      type="file"
                                                                      accept="image/*"
                                                                      name="profile_pic"
                                                                      id="clogo"
                                                                      onChange={(e) => handleLogoUpload(e)}
                                                                 />
                                                            </label>
                                                       </>
                                                  )}
                                             </>
                                        )}
                                        <div className="text-left">
                                             <p className="mb-2 mt-1 fw-500">Company Logo</p>
                                             <div className=" d-flex align-items-center justify-content-between">
                                                  <div>
                                                       <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14 1.5H8L6.5 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V3C15.5 2.175 14.825 1.5 14 1.5ZM14 10.5H2V3H14V10.5ZM5 6.7575L6.0575 7.815L7.25 6.63V9.75H8.75V6.63L9.9425 7.8225L11 6.7575L8.0075 3.75L5 6.7575Z" fill="black" />
                                                       </svg>
                                                  </div>
                                                  <div>
                                                       <p className="color_light_v1">Max <span className="fw-600">1MB</span> only: <span className="fw-600">.png, .jpg, .jpeg</span></p>
                                                  </div>

                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="white-background p-32 mt-24">
                         <div>
                              <p className="title3" id="valuation-head-id">Competitors</p>
                              <p>Use this section to indicate your closest competitors. You can add as many as you&rsquo;d like.</p>
                         </div>

                         <hr style={{ margin: "24px 0" }} />

                         <div className="row">
                              <div className="col-5 ">
                                   <p className="valuation_label">Company Name</p>
                              </div>
                              <div className="col-5 px-2">
                                   <p className="valuation_label">Company Website</p>
                              </div>
                         </div>

                         {competitors
                              ? competitors.map((row, i) => {
                                   return (
                                        <>
                                             <div key={i} className="row mt-24">
                                                  <div className="col-5 px-2">
                                                       <input
                                                            type="text"
                                                            name="objective"
                                                            id="objective"
                                                            placeholder="eg: ABC Capital"
                                                            className="valuation_input-outline mb-2 w-100"
                                                            value={row.competitorname}
                                                            onChange={(e) =>
                                                                 storeObjectiveNameVal(e.target.value, i)
                                                            }
                                                       />
                                                  </div>
                                                  <div className="col-5 px-2">
                                                       <input
                                                            type="text"
                                                            name="objective"
                                                            id="objective"
                                                            className="valuation_input-outline mb-2 w-100"
                                                            placeholder="eg: abcapital.com"
                                                            value={row.competitorurl}
                                                            onChange={(e) =>
                                                                 storeObjectiveUrlVal(e.target.value, i)
                                                            }
                                                       />
                                                  </div>

                                                  <button
                                                       type="button"
                                                       className="removebtn col-2 fw-600 text-danger"
                                                       title="Remove"

                                                       onClick={() => deleteFundingRow(row.id, "c")}
                                                  >
                                                       Remove
                                                  </button>
                                             </div>
                                        </>
                                   );
                              })
                              : ""}
                         <div className="text-center mt-24">
                              <button
                                   type="button"
                                   className="addrowsbtn"
                                   onClick={() => AddFundingRow("c")}
                              >
                                   <AddIcon />
                              </button>
                              <p className="caption color_grey_44 mt-12">Add more competitors</p>
                         </div>
                    </div>

                    <div className="white-background p-32 mt-24">
                         <div className="mb-3">
                              <p id="valuation-head-id" className="title3">
                                   Shareholders
                              </p>
                              <p>
                                   In this section you can add who holds the shares of the company
                              </p>
                         </div>
                         <hr style={{ margin: "24px 0" }} />
                         <div className=" row">
                              <div className="col-lg-7 col-sm-12 col-md-6">
                                   <table className="pastfundingtable mb-0">
                                        <thead className="">
                                             <tr>
                                                  <th className="body1">Shareholder</th>
                                                  <th className="body1">Percentage (%)</th>
                                             </tr>
                                        </thead>
                                   </table>
                                   {shareholders
                                        ? shareholders.map((row, i) => {
                                             return (
                                                  <>
                                                       <div key={i} className="row mt-24">
                                                            {/* <p>{obj}</p> */}
                                                            <div className="col-sm-5 col-12 px-2">
                                                                 <input
                                                                      type="text"
                                                                      name="objective"
                                                                      id="objective"
                                                                      className="valuation_input-outline mb-2"
                                                                      placeholder="eg: William Davis"
                                                                      value={row.shareholdername}
                                                                      onChange={(e) =>
                                                                           storeShareNameVal(e.target.value, i)
                                                                      }
                                                                 />
                                                            </div>
                                                            <div className="col-sm-5 col-8 px-2">
                                                                 <input
                                                                      type="number"
                                                                      name="objective"
                                                                      id="objective"
                                                                      className="valuation_input mb-2"
                                                                      value={row.shareholderpercentage}
                                                                      onChange={(e) =>
                                                                           storeSharePerVal(e.target.value, i)
                                                                      }
                                                                 />
                                                            </div>

                                                            <button
                                                                 type="button"
                                                                 className="removebtn col-sm-2 col-4 fw-600"
                                                                 title="Remove"
                                                                 style={{ color: "rgba(139, 0, 0, 0.5)" }}
                                                                 onClick={() => deleteFundingRow(i, "s")}
                                                            >
                                                                 Remove
                                                            </button>
                                                       </div>
                                                  </>
                                             );
                                        })
                                        : ""}
                                   <div className="text-center mt-24">
                                        <button
                                             type="button"
                                             className="addrowsbtn"
                                             onClick={() => AddFundingRow("s")}
                                        >
                                             <AddIcon />
                                        </button>
                                        <p className="font12 color_grey_44 mt-12">
                                             Add more shareholders
                                        </p>
                                   </div>
                              </div>
                              <div className="col-lg-5 col-md-6 col-sm-12">

                                   <Sharechart
                                        exceedingLimit={exceedingLimit}
                                        shareholders={shareholders}
                                   />
                                   {exceedingLimit && (
                                        <p className="title3 mt-40 text-center">
                                             Shareholder stake is exceeding 100%
                                        </p>
                                   )}


                              </div>
                         </div>
                    </div>
               </form>
          </div>
     );
};

export default index;
