module.exports.getindustrywithcode = () => {
  return [
    { option_label: "coal wholesale", code: "5010101012" },
    { option_label: "coal mining support", code: "5010101011" },
    { option_label: "other coal", code: "5010101010" },
    { option_label: "integrated oil & gas", code: "5010201010" },
    { option_label: "unconventional oil & gas production", code: "5010202015" },
    {
      option_label: "natural gas exploration & production - offshore",
      code: "5010202014",
    },
    {
      option_label: "natural gas exploration & production - onshore",
      code: "5010202013",
    },
    {
      option_label: "oil exploration & production - offshore",
      code: "5010202012",
    },
    {
      option_label: "oil exploration & production - onshore",
      code: "5010202011",
    },
    {
      option_label: "other oil & gas exploration and production",
      code: "5010202010",
    },
    { option_label: "petroleum product wholesale", code: "5010203013" },
    { option_label: "gasoline stations", code: "5010203012" },
    { option_label: "petroleum refining", code: "5010203011" },
    {
      option_label: "other oil & gas refining and marketing",
      code: "5010203010",
    },
    { option_label: "unconventional oil & gas drilling", code: "5010301015" },
    { option_label: "gas drilling - offshore", code: "5010301014" },
    { option_label: "oil drilling - offshore", code: "5010301013" },
    { option_label: "gas drilling - onshore", code: "5010301012" },
    { option_label: "oil drilling - onshore", code: "5010301011" },
    { option_label: "other oil & gas drilling", code: "5010301010" },
    {
      option_label: "oil related - surveying & mapping services",
      code: "5010302013",
    },
    { option_label: "oil related equipment", code: "5010302012" },
    { option_label: "oil related services", code: "5010302011" },
    {
      option_label: "other oil related services and equipment",
      code: "5010302010",
    },
    { option_label: "oil & gas storage", code: "5010303015" },
    { option_label: "sea-borne tankers", code: "5010303014" },
    { option_label: "oil pipeline transportation", code: "5010303013" },
    { option_label: "natural gas pipeline transportation", code: "5010303012" },
    { option_label: "lng transportation & storage", code: "5010303011" },
    {
      option_label: "other oil & gas transportation services",
      code: "5010303010",
    },
    { option_label: "geothermal equipment", code: "5020101020" },
    { option_label: "renewable energy services", code: "5020101019" },
    { option_label: "wave power energy equipment", code: "5020101018" },
    { option_label: "hydropower equipment", code: "5020101017" },
    { option_label: "waste to energy systems & equipment", code: "5020101016" },
    { option_label: "biomass power energy equipment", code: "5020101015" },
    { option_label: "thermal solar systems & equipment", code: "5020101014" },
    {
      option_label: "photovoltaic solar systems & equipment",
      code: "5020101013",
    },
    { option_label: "stationary fuel cells", code: "5020101012" },
    { option_label: "wind systems & equipment", code: "5020101011" },
    {
      option_label: "other renewable energy equipment & services",
      code: "5020101010",
    },
    { option_label: "hydrogen fuel", code: "5020102015" },
    { option_label: "biomass & biogas fuels", code: "5020102014" },
    { option_label: "pyrolytic & synthetic fuels", code: "5020102013" },
    { option_label: "ethanol fuels", code: "5020102012" },
    { option_label: "biodiesel", code: "5020102011" },
    { option_label: "other renewable fuels", code: "5020102010" },
    { option_label: "uranium processing", code: "5030101012" },
    { option_label: "uranium mining", code: "5030101011" },
    { option_label: "other uranium", code: "5030101010" },
    { option_label: "commodity chemicals wholesale", code: "5110101016" },
    { option_label: "industrial gases", code: "5110101015" },
    { option_label: "explosives", code: "5110101014" },
    { option_label: "tanning & softening agents", code: "5110101013" },
    { option_label: "paints & coatings", code: "5110101012" },
    { option_label: "plastics", code: "5110101011" },
    { option_label: "other commodity chemicals", code: "5110101010" },
    { option_label: "agricultural chemicals wholesale", code: "5110102014" },
    { option_label: "organic fertilizers", code: "5110102013" },
    { option_label: "pesticides", code: "5110102012" },
    { option_label: "fertilizers", code: "5110102011" },
    { option_label: "other agricultural chemicals", code: "5110102010" },
    { option_label: "specialty chemicals wholesale", code: "5110103017" },
    { option_label: "industrial biotechnology chemicals", code: "5110103016" },
    { option_label: "epoxy chemicals", code: "5110103015" },
    { option_label: "advanced polymers", code: "5110103014" },
    { option_label: "adhesives", code: "5110103013" },
    { option_label: "synthetic fibers", code: "5110103012" },
    { option_label: "coloring agents", code: "5110103011" },
    { option_label: "other specialty chemicals", code: "5110103010" },
    { option_label: "diversified chemicals", code: "5110109010" },
    { option_label: "rare earth minerals", code: "5120101016" },
    { option_label: "pearl cultivation", code: "5120101015" },
    { option_label: "semiprecious gem stones", code: "5120101014" },
    { option_label: "diamond mining", code: "5120101013" },
    { option_label: "platinum mining", code: "5120101012" },
    { option_label: "silver mining", code: "5120101011" },
    {
      option_label: "other non-gold precious metals & minerals",
      code: "5120101010",
    },
    { option_label: "metal merchant wholesalers", code: "5120102016" },
    { option_label: "metallic rolling & drawing products", code: "5120102015" },
    { option_label: "metal service centers", code: "5120102014" },
    { option_label: "iron, steel mills & foundries", code: "5120102013" },
    { option_label: "coke coal mining", code: "5120102012" },
    { option_label: "iron ore mining", code: "5120102011" },
    { option_label: "other iron & steel", code: "5120102010" },
    { option_label: "bauxite mining", code: "5120103016" },
    { option_label: "aluminum wholesalers", code: "5120103015" },
    { option_label: "aluminum refining", code: "5120103014" },
    { option_label: "aluminum rolling", code: "5120103013" },
    {
      option_label: "secondary smelting & alloying of aluminum",
      code: "5120103012",
    },
    { option_label: "primary aluminum production", code: "5120103011" },
    { option_label: "other aluminum", code: "5120103010" },
    { option_label: "specialty mining & metals wholesale", code: "5120105017" },
    { option_label: "nonferrous metal processing", code: "5120105016" },
    { option_label: "nonferrous metal mining", code: "5120105015" },
    { option_label: "zinc ore mining", code: "5120105014" },
    { option_label: "nickel ore mining", code: "5120105013" },
    { option_label: "copper ore mining", code: "5120105012" },
    { option_label: "lead ore mining", code: "5120105011" },
    { option_label: "other specialty mining & metals", code: "5120105010" },
    { option_label: "gold refining", code: "5120106012" },
    { option_label: "gold mining", code: "5120106011" },
    { option_label: "other gold", code: "5120106010" },
    {
      option_label: "mining machinery & equipment manufacturing",
      code: "5120107013",
    },
    { option_label: "mining support services", code: "5120107012" },
    {
      option_label: "geophysical surveying & mapping services",
      code: "5120107011",
    },
    {
      option_label: "other mining support services & equipment",
      code: "5120107010",
    },
    { option_label: "diversified mining", code: "5120108010" },
    { option_label: "construction material wholesale", code: "5120201015" },
    { option_label: "rock mining", code: "5120201014" },
    {
      option_label: "tile & paving material manufacturing",
      code: "5120201013",
    },
    { option_label: "cement & concrete manufacturing", code: "5120201012" },
    { option_label: "construction material processing", code: "5120201011" },
    { option_label: "other construction materials", code: "5120201010" },
    { option_label: "wood product wholesale", code: "5130101016" },
    { option_label: "wood products", code: "5130101015" },
    { option_label: "forest support & services", code: "5130101014" },
    { option_label: "logging & sawmills", code: "5130101013" },
    {
      option_label: "forest nurseries & gathering of forest products",
      code: "5130101012",
    },
    { option_label: "timber tract operations", code: "5130101011" },
    { option_label: "other forest & wood products", code: "5130101010" },
    { option_label: "paper product wholesale", code: "5130102014" },
    { option_label: "pulp mills", code: "5130102013" },
    { option_label: "newsprint mills", code: "5130102012" },
    { option_label: "paper mills & products", code: "5130102011" },
    { option_label: "other paper products", code: "5130102010" },
    {
      option_label: "container & packaging material wholesale",
      code: "5130201016",
    },
    { option_label: "wood container & packaging", code: "5130201015" },
    { option_label: "plastic containers & packaging", code: "5130201014" },
    { option_label: "metal containers & packaging", code: "5130201013" },
    { option_label: "glass containers & packaging", code: "5130201012" },
    { option_label: "textile containers & packaging", code: "5130201011" },
    {
      option_label: "other non-paper containers & packaging",
      code: "5130201010",
    },
    { option_label: "paper packaging wholesale", code: "5130202011" },
    { option_label: "other paper packaging", code: "5130202010" },
    { option_label: "aircraft equipment wholesale", code: "5210101019" },
    { option_label: "military clothing & accessories", code: "5210101018" },
    { option_label: "spacecraft manufacturing", code: "5210101017" },
    { option_label: "satellite design & manufacture", code: "5210101016" },
    { option_label: "military vehicles manufacturing", code: "5210101015" },
    { option_label: "aircraft parts manufacturing", code: "5210101014" },
    { option_label: "military aircraft manufacturing", code: "5210101013" },
    { option_label: "commercial aircraft manufacturing", code: "5210101012" },
    { option_label: "arms & ammunitions manufacturing", code: "5210101011" },
    { option_label: "other aerospace & defense", code: "5210101010" },
    {
      option_label: "industrial machinery & equipment wholesale",
      code: "5210201024",
    },
    { option_label: "industrial valve manufacturing", code: "5210201023" },
    { option_label: "machine tools", code: "5210201022" },
    { option_label: "industrial moulds", code: "5210201021" },
    { option_label: "automatic vending machines", code: "5210201020" },
    { option_label: "fluid power cylinder & actuators", code: "5210201019" },
    { option_label: "industrial process furnace & ovens", code: "5210201018" },
    { option_label: "welding & soldering equipment", code: "5210201017" },
    { option_label: "air & gas compressors", code: "5210201016" },
    { option_label: "pump & pumping equipment", code: "5210201015" },
    { option_label: "testing & measuring equipment", code: "5210201014" },
    { option_label: "ball & roller bearings", code: "5210201013" },
    { option_label: "industrial machinery", code: "5210201012" },
    { option_label: "industrial parts & components", code: "5210201011" },
    {
      option_label: "other industrial machinery & equipment",
      code: "5210201010",
    },
    {
      option_label: "heavy machinery & vehicles wholesale",
      code: "5210202017",
    },
    { option_label: "commercial landscaping equipment", code: "5210202016" },
    { option_label: "agricultural machinery", code: "5210202015" },
    { option_label: "locomotive engines & rolling stock", code: "5210202014" },
    { option_label: "heavy buses & coaches", code: "5210202013" },
    { option_label: "heavy trucks", code: "5210202012" },
    { option_label: "construction machinery", code: "5210202011" },
    { option_label: "other heavy machinery & vehicles", code: "5210202010" },
    { option_label: "electric equipment wholesale", code: "5210203020" },
    {
      option_label: "electrical measuring & testing instruments",
      code: "5210203019",
    },
    { option_label: "portable motors & generators", code: "5210203018" },
    { option_label: "switchgear", code: "5210203017" },
    { option_label: "electrical insulators", code: "5210203016" },
    {
      option_label: "heating, ventilation & air conditioning systems",
      code: "5210203015",
    },
    { option_label: "lighting equipment", code: "5210203014" },
    { option_label: "electrical components", code: "5210203013" },
    { option_label: "wires & cables", code: "5210203012" },
    {
      option_label: "batteries & uninterruptable power supplies",
      code: "5210203011",
    },
    {
      option_label: "other electrical components & equipment",
      code: "5210203010",
    },
    { option_label: "nuclear generators & components", code: "5210204016" },
    { option_label: "industrial electrical switchgear", code: "5210204015" },
    { option_label: "heavy motors & generators", code: "5210204014" },
    { option_label: "turbine manufacturing", code: "5210204013" },
    { option_label: "elevator & conveying equipment", code: "5210204012" },
    {
      option_label: "electrical transmission & grid equipment",
      code: "5210204011",
    },
    { option_label: "other heavy electrical equipment", code: "5210204010" },
    { option_label: "ship repairing & maintenance", code: "5210205012" },
    { option_label: "ship parts manufacturers", code: "5210205011" },
    { option_label: "other shipbuilding", code: "5210205010" },
    { option_label: "telecommunication  construction", code: "5220102023" },
    { option_label: "nuclear power plant construction", code: "5220102022" },
    { option_label: "electric power plant construction", code: "5220102021" },
    { option_label: "gas infrastructure construction", code: "5220102020" },
    { option_label: "land division & subdivision", code: "5220102019" },
    { option_label: "water & sewage construction", code: "5220102018" },
    { option_label: "industrial plant construction", code: "5220102017" },
    { option_label: "building contractors", code: "5220102016" },
    { option_label: "civil engineers & architects", code: "5220102015" },
    {
      option_label: "power & communications network construction",
      code: "5220102014",
    },
    { option_label: "railway construction", code: "5220102013" },
    { option_label: "highway & bridge construction", code: "5220102012" },
    { option_label: "commercial buildings", code: "5220102011" },
    { option_label: "other construction & engineering", code: "5220102010" },
    {
      option_label: "diversified industrial goods wholesalers",
      code: "5220201010",
    },
    { option_label: "carbon capture & storage", code: "5220301015" },
    {
      option_label: "environmental r&d services & biotechnology",
      code: "5220301014",
    },
    { option_label: "environmental consultancy services", code: "5220301013" },
    {
      option_label: "waste management, disposal & recycling services",
      code: "5220301012",
    },
    { option_label: "purification & treatment equipment", code: "5220301011" },
    {
      option_label: "other environmental services & equipment",
      code: "5220301010",
    },
    { option_label: "book printing services", code: "5220302013" },
    {
      option_label: "newspaper & magazine printing services",
      code: "5220302012",
    },
    { option_label: "specialized printing services", code: "5220302011" },
    { option_label: "other commercial printing services", code: "5220302010" },
    { option_label: "online job portals", code: "5220303014" },
    { option_label: "executive search services", code: "5220303013" },
    { option_label: "outsourcing & staffing services", code: "5220303012" },
    { option_label: "human resources consulting services", code: "5220303011" },
    { option_label: "other employment services", code: "5220303010" },
    { option_label: "transaction & payment services", code: "5220304027" },
    { option_label: "exhibition & conference services", code: "5220304026" },
    { option_label: "call center services", code: "5220304025" },
    { option_label: "testing laboratories", code: "5220304024" },
    {
      option_label: "translation & interpretation services",
      code: "5220304023",
    },
    { option_label: "industrial design services", code: "5220304022" },
    { option_label: "maintenance & repair services", code: "5220304021" },
    { option_label: "pest control services", code: "5220304020" },
    { option_label: "office equipment & supplies rental", code: "5220304019" },
    { option_label: "industrial equipment rental", code: "5220304018" },
    { option_label: "data processing services", code: "5220304017" },
    { option_label: "cleaning services", code: "5220304016" },
    { option_label: "commercial educational services", code: "5220304015" },
    { option_label: "security services", code: "5220304014" },
    { option_label: "management consulting services", code: "5220304013" },
    { option_label: "legal services", code: "5220304012" },
    { option_label: "corporate accounting services", code: "5220304011" },
    { option_label: "other business support services", code: "5220304010" },
    { option_label: "office supplies wholesale", code: "5220306014" },
    {
      option_label: "health, safety & fire protection equipment",
      code: "5220306013",
    },
    { option_label: "office supplies", code: "5220306012" },
    { option_label: "office furniture", code: "5220306011" },
    { option_label: "other business support supplies", code: "5220306010" },
    { option_label: "news agencies", code: "5220307018" },
    { option_label: "journals & scholarly research", code: "5220307017" },
    {
      option_label: "education & training information providers",
      code: "5220307016",
    },
    { option_label: "legal & tax information providers", code: "5220307015" },
    { option_label: "trade & business publishing", code: "5220307014" },
    { option_label: "rating agencies", code: "5220307013" },
    { option_label: "compliance & investor communication", code: "5220307012" },
    { option_label: "financial information providers", code: "5220307011" },
    {
      option_label: "other professional information services",
      code: "5220307010",
    },
    { option_label: "industrial conglomerates", code: "5230101010" },
    { option_label: "integrated logistics operators", code: "5240501014" },
    { option_label: "courier services", code: "5240501013" },
    { option_label: "air freight", code: "5240501012" },
    { option_label: "freight logistics", code: "5240501011" },
    {
      option_label: "other courier, postal, air freight & land-based logistics",
      code: "5240501010",
    },
    { option_label: "deep sea freight", code: "5240502013" },
    { option_label: "inland water freight", code: "5240502012" },
    { option_label: "marine logistics", code: "5240502011" },
    { option_label: "other marine freight & logistics", code: "5240502010" },
    { option_label: "truck rental", code: "5240503014" },
    { option_label: "warehousing", code: "5240503013" },
    { option_label: "freight trucking", code: "5240503012" },
    { option_label: "railway freight operators", code: "5240503011" },
    { option_label: "other ground freight & logistics", code: "5240503010" },
    {
      option_label: "inter-modal passenger transportation",
      code: "5240601014",
    },
    { option_label: "specialized aviation services", code: "5240601013" },
    { option_label: "charter & private air services", code: "5240601012" },
    { option_label: "regional airlines", code: "5240601011" },
    { option_label: "other airlines", code: "5240601010" },
    { option_label: "passenger car rental", code: "5240602017" },
    { option_label: "taxis & limousines", code: "5240602016" },
    { option_label: "commuter ferry operators", code: "5240602015" },
    { option_label: "marine passenger transportation", code: "5240602014" },
    { option_label: "rail services", code: "5240602013" },
    { option_label: "charter bus services", code: "5240602012" },
    { option_label: "commuting services", code: "5240602011" },
    {
      option_label: "other passenger transportation, ground & sea",
      code: "5240602010",
    },
    { option_label: "airline catering services", code: "5240701014" },
    { option_label: "airport fueling services", code: "5240701013" },
    { option_label: "duty free shops", code: "5240701012" },
    { option_label: "airport operators", code: "5240701011" },
    { option_label: "other airport operators & services", code: "5240701010" },
    { option_label: "marine cargo handling services", code: "5240702013" },
    { option_label: "port operators", code: "5240702012" },
    { option_label: "port warehousing services", code: "5240702011" },
    { option_label: "other marine port services", code: "5240702010" },
    { option_label: "parking lot operators", code: "5240703013" },
    { option_label: "railway operators", code: "5240703012" },
    { option_label: "highway operators", code: "5240703011" },
    { option_label: "other highways & rail tracks", code: "5240703010" },
    { option_label: "auto & truck wholesale", code: "5310101016" },
    { option_label: "luxury vehicles", code: "5310101015" },
    { option_label: "electrical (alternative) vehicles", code: "5310101014" },
    { option_label: "light trucks", code: "5310101013" },
    {
      option_label: "automobiles & multi utility vehicles",
      code: "5310101012",
    },
    { option_label: "motorcycles & scooters", code: "5310101011" },
    { option_label: "other auto & truck manufacturers", code: "5310101010" },
    { option_label: "auto & truck parts wholesale", code: "5310102017" },
    { option_label: "motorcycle parts & accessories", code: "5310102016" },
    { option_label: "automotive accessories", code: "5310102015" },
    { option_label: "automotive systems", code: "5310102014" },
    { option_label: "automotive batteries", code: "5310102013" },
    { option_label: "engine & powertrain systems", code: "5310102012" },
    { option_label: "automotive body parts", code: "5310102011" },
    {
      option_label: "other auto, truck & motorcycle parts",
      code: "5310102010",
    },
    { option_label: "tires & rubber products wholesale", code: "5310103015" },
    { option_label: "rubber plantations", code: "5310103014" },
    { option_label: "industrial rubber products", code: "5310103013" },
    { option_label: "tire retreading", code: "5310103012" },
    { option_label: "tire & tube manufacturers", code: "5310103011" },
    { option_label: "other tires & rubber products", code: "5310103010" },
    { option_label: "textiles & leather goods wholesale", code: "5320201019" },
    { option_label: "cotton farming", code: "5320201018" },
    { option_label: "yarn goods", code: "5320201017" },
    { option_label: "fabric dyeing & finishing", code: "5320201016" },
    { option_label: "fur goods", code: "5320201015" },
    { option_label: "leather goods", code: "5320201014" },
    {
      option_label: "organic & ecologically produced fabric",
      code: "5320201013",
    },
    { option_label: "natural fabrics", code: "5320201012" },
    { option_label: "synthetic fabrics", code: "5320201011" },
    {
      option_label: "altro prodotti tessili e di pelletteria",
      code: "5320201010",
    },
    { option_label: "apparel wholesale", code: "5320202030" },
    { option_label: "fashion eyewear", code: "5320202029" },
    { option_label: "handbags & luggage", code: "5320202028" },
    { option_label: "watches", code: "5320202027" },
    { option_label: "oreficeria", code: "5320202026" },
    { option_label: "accessories", code: "5320202025" },
    { option_label: "luxury accessories", code: "5320202024" },
    { option_label: "animal & pet clothing", code: "5320202023" },
    { option_label: "theatrical costumes", code: "5320202022" },
    { option_label: "luxury clothing", code: "5320202021" },
    { option_label: "fair trade & ethical clothing", code: "5320202020" },
    { option_label: "industrial clothing & uniforms", code: "5320202019" },
    { option_label: "hosiery & socks", code: "5320202018" },
    { option_label: "lingerie", code: "5320202017" },
    { option_label: "knitwear", code: "5320202016" },
    { option_label: "jeans", code: "5320202015" },
    { option_label: "sportswear & outdoors clothing", code: "5320202014" },
    { option_label: "children's & infants' clothing", code: "5320202013" },
    { option_label: "women's clothing", code: "5320202012" },
    { option_label: "men's clothing", code: "5320202011" },
    { option_label: "other apparel & accessories", code: "5320202010" },
    { option_label: "footwear wholesale", code: "5320203017" },
    { option_label: "functional footwear", code: "5320203016" },
    { option_label: "luxury footwear", code: "5320203015" },
    { option_label: "sports & outdoor footwear", code: "5320203014" },
    { option_label: "children's & infants' footwear", code: "5320203013" },
    { option_label: "women's footwear", code: "5320203012" },
    { option_label: "men's footwear", code: "5320203011" },
    { option_label: "other footwear", code: "5320203010" },
    {
      option_label: "residential architectural & interior design services",
      code: "5320301016",
    },
    { option_label: "retirement home builders", code: "5320301015" },
    {
      option_label: "sustainable & energy efficient home builders",
      code: "5320301014",
    },
    { option_label: "prefabricated homes", code: "5320301013" },
    {
      option_label: "residential builders - multifamily homes",
      code: "5320301012",
    },
    { option_label: "residential builders - single homes", code: "5320301011" },
    { option_label: "other homebuilding", code: "5320301010" },
    {
      option_label: "construction supplies & fixtures wholesale",
      code: "5320302020",
    },
    { option_label: "lighting fixtures", code: "5320302019" },
    { option_label: "roofing supplies", code: "5320302018" },
    { option_label: "bathroom fixtures", code: "5320302017" },
    { option_label: "kitchen cabinets", code: "5320302016" },
    { option_label: "plumbing fixtures & fittings", code: "5320302015" },
    {
      option_label: "flooring & interior tile manufacturers",
      code: "5320302014",
    },
    { option_label: "doors & window frames", code: "5320302013" },
    {
      option_label: "luxury construction supplies & fixtures",
      code: "5320302012",
    },
    { option_label: "construction supplies", code: "5320302011" },
    {
      option_label: "other construction supplies & fixtures",
      code: "5320302010",
    },
    { option_label: "luxury appliances", code: "5320403016" },
    { option_label: "appliance & houseware wholesale", code: "5320403015" },
    { option_label: "cutlery & flatware", code: "5320403014" },
    { option_label: "kitchen appliances", code: "5320403013" },
    { option_label: "tools & housewares", code: "5320403012" },
    { option_label: "household appliances", code: "5320403011" },
    {
      option_label: "other appliances, tools & housewares",
      code: "5320403010",
    },
    { option_label: "home furnishings wholesale", code: "5320404016" },
    { option_label: "antiques", code: "5320404015" },
    { option_label: "luxury furnishing", code: "5320404014" },
    { option_label: "furniture & art", code: "5320404013" },
    { option_label: "wallpaper", code: "5320404012" },
    { option_label: "carpets & curtains", code: "5320404011" },
    { option_label: "other home furnishings", code: "5320404010" },
    {
      option_label: "toys & children's products wholesale",
      code: "5320501016",
    },
    { option_label: "children's products & accessories", code: "5320501015" },
    { option_label: "children's furniture", code: "5320501014" },
    { option_label: "children's safety products", code: "5320501013" },
    { option_label: "games, toys & children's vehicles", code: "5320501012" },
    { option_label: "dolls & stuffed toys", code: "5320501011" },
    { option_label: "other toys & children's products", code: "5320501010" },
    { option_label: "leisure products wholesale", code: "5320502016" },
    { option_label: "luxury recreational products", code: "5320502015" },
    { option_label: "musical instruments", code: "5320502014" },
    { option_label: "sporting & outdoor goods", code: "5320502013" },
    { option_label: "bicycle manufacturing", code: "5320502012" },
    { option_label: "sailing yachts & motorboats", code: "5320502011" },
    { option_label: "other recreational products", code: "5320502010" },
    { option_label: "campsite operators", code: "5330101017" },
    { option_label: "self-catering accommodation", code: "5330101016" },
    { option_label: "bed & breakfast", code: "5330101015" },
    { option_label: "resort operators", code: "5330101014" },
    { option_label: "luxury hotels", code: "5330101013" },
    { option_label: "cruise lines", code: "5330101012" },
    { option_label: "hotels & motels", code: "5330101011" },
    { option_label: "other hotels, motels & cruise lines", code: "5330101010" },
    { option_label: "cafés", code: "5330102016" },
    { option_label: "banquet halls & catering", code: "5330102015" },
    { option_label: "mobile caterers", code: "5330102014" },
    { option_label: "quick service restaurants", code: "5330102013" },
    { option_label: "commercial food services", code: "5330102012" },
    { option_label: "pubs, bars & night clubs", code: "5330102011" },
    { option_label: "other restaurants & bars", code: "5330102010" },
    { option_label: "lottery operators", code: "5330103015" },
    { option_label: "horse & dog race tracks", code: "5330103014" },
    { option_label: "casinos", code: "5330103013" },
    { option_label: "gaming machine operators", code: "5330103012" },
    {
      option_label: "gambling & gaming machine manufacturers",
      code: "5330103011",
    },
    { option_label: "other casinos & gaming", code: "5330103010" },
    { option_label: "guided tour operators", code: "5330104023" },
    { option_label: "marinas", code: "5330104022" },
    { option_label: "hunting & fishing", code: "5330104021" },
    { option_label: "golf courses", code: "5330104020" },
    { option_label: "professional sports venues", code: "5330104019" },
    { option_label: "public sport facilities", code: "5330104018" },
    {
      option_label: "adventure sports facilities & ski resorts",
      code: "5330104017",
    },
    { option_label: "gyms, fitness and spa centers", code: "5330104016" },
    { option_label: "amusement parks and zoos", code: "5330104015" },
    { option_label: "travel agents", code: "5330104014" },
    { option_label: "museums & historic places", code: "5330104013" },
    { option_label: "theatres & performing arts", code: "5330104012" },
    { option_label: "movie theaters & movie products", code: "5330104011" },
    { option_label: "other leisure & recreation", code: "5330104010" },
    { option_label: "marketing consulting services", code: "5330201022" },
    { option_label: "market research", code: "5330201021" },
    { option_label: "branding & naming", code: "5330201020" },
    { option_label: "digital media agencies", code: "5330201019" },
    { option_label: "public relations", code: "5330201018" },
    { option_label: "guerrilla marketing", code: "5330201017" },
    {
      option_label: "sales promotions & events management",
      code: "5330201016",
    },
    { option_label: "direct marketing", code: "5330201015" },
    { option_label: "outdoor advertising", code: "5330201014" },
    {
      option_label: "signs & advertising specialty producers",
      code: "5330201013",
    },
    { option_label: "media buying agency", code: "5330201012" },
    { option_label: "advertising agency", code: "5330201011" },
    { option_label: "other advertising & marketing", code: "5330201010" },
    { option_label: "cable service providers", code: "5330202013" },
    { option_label: "radio broadcasting", code: "5330202012" },
    { option_label: "television broadcasting", code: "5330202011" },
    { option_label: "other broadcasting", code: "5330202010" },
    {
      option_label: "adult entertainment production & broadcasting",
      code: "5330203016",
    },
    { option_label: "copyright management", code: "5330203015" },
    {
      option_label: "entertainment production equipment & services",
      code: "5330203014",
    },
    { option_label: "plays & concert production", code: "5330203013" },
    {
      option_label: "music, music video production & distribution",
      code: "5330203012",
    },
    { option_label: "movie, tv production & distribution", code: "5330203011" },
    { option_label: "other entertainment production", code: "5330203010" },
    { option_label: "adult publishing", code: "5330204016" },
    { option_label: "digital publishing", code: "5330204015" },
    { option_label: "directory publishing", code: "5330204014" },
    { option_label: "book publishing", code: "5330204013" },
    { option_label: "magazine publishing", code: "5330204012" },
    { option_label: "newspaper publishing", code: "5330204011" },
    { option_label: "other consumer publishing", code: "5330204010" },
    {
      option_label: "internet & mail order department stores",
      code: "5340201013",
    },
    { option_label: "luxury department stores", code: "5340201012" },
    { option_label: "general department stores", code: "5340201011" },
    { option_label: "other department stores", code: "5340201010" },
    { option_label: "discount stores without gasoline", code: "5340202015" },
    { option_label: "discount stores with gasoline", code: "5340202014" },
    { option_label: "discount stores without grocery", code: "5340202013" },
    { option_label: "discount stores with grocery", code: "5340202012" },
    {
      option_label: "internet & mail order discount stores",
      code: "5340202011",
    },
    { option_label: "other discount stores", code: "5340202010" },
    { option_label: "luxury car dealers", code: "5340301016" },
    { option_label: "tire dealers", code: "5340301015" },
    {
      option_label: "automotive parts & accessories retailers",
      code: "5340301014",
    },
    { option_label: "motorcycle dealers", code: "5340301013" },
    { option_label: "used car dealers", code: "5340301012" },
    { option_label: "new car dealers", code: "5340301011" },
    {
      option_label: "other auto vehicles, parts & service retailers",
      code: "5340301010",
    },
    {
      option_label: "luxury home improvement product retailers",
      code: "5340302017",
    },
    { option_label: "interior design services", code: "5340302016" },
    { option_label: "home décor retailers", code: "5340302015" },
    { option_label: "kitchen & bathroom retailers", code: "5340302014" },
    { option_label: "nursery & garden centers", code: "5340302013" },
    { option_label: "builder merchants", code: "5340302012" },
    { option_label: "paint & wallpaper retailers", code: "5340302011" },
    {
      option_label: "other home improvement products & services retailers",
      code: "5340302010",
    },
    { option_label: "antique dealers", code: "5340303015" },
    { option_label: "luxury furnishing retailers", code: "5340303014" },
    { option_label: "soft furnishing retailers", code: "5340303013" },
    { option_label: "floor covering retailers", code: "5340303012" },
    { option_label: "furniture retailers", code: "5340303011" },
    { option_label: "other home furnishings retailers", code: "5340303010" },
    { option_label: "sports & outdoors retailers", code: "5340304019" },
    { option_label: "luxury apparel retailers", code: "5340304018" },
    { option_label: "handbags & luggage retailers", code: "5340304017" },
    { option_label: "teen fashion retailers", code: "5340304016" },
    {
      option_label: "children's & infants' clothing retailers",
      code: "5340304015",
    },
    { option_label: "women's apparel retailers", code: "5340304014" },
    { option_label: "men's apparel retailers", code: "5340304013" },
    { option_label: "jewelry & watch retailers", code: "5340304012" },
    { option_label: "footwear retailers", code: "5340304011" },
    {
      option_label: "other apparel & accessories retailers",
      code: "5340304010",
    },
    { option_label: "mobile phone retailers", code: "5340305013" },
    { option_label: "consumer electronics retailers", code: "5340305012" },
    {
      option_label: "computer hardware & software retailers",
      code: "5340305011",
    },
    {
      option_label: "other computer & electronics retailers",
      code: "5340305010",
    },
    { option_label: "adult products retailers", code: "5340309025" },
    { option_label: "pet & pet supplies retailers", code: "5340309024" },
    { option_label: "sporting goods stores", code: "5340309023" },
    { option_label: "used merchandise stores", code: "5340309022" },
    { option_label: "gift, novelty & souvenir stores", code: "5340309021" },
    { option_label: "office supplies & stationery stores", code: "5340309020" },
    { option_label: "florists", code: "5340309019" },
    { option_label: "book & magazine retailers", code: "5340309018" },
    { option_label: "toys & games retailers", code: "5340309017" },
    { option_label: "hobby & craft product retailers", code: "5340309016" },
    { option_label: "musical instrument retailers", code: "5340309015" },
    { option_label: "health food stores", code: "5340309014" },
    { option_label: "optical goods stores", code: "5340309013" },
    { option_label: "personal care products retailers", code: "5340309012" },
    { option_label: "luxury beauty supply retailers", code: "5340309011" },
    {
      option_label: "other miscellaneous specialty retailers",
      code: "5340309010",
    },
    { option_label: "craft & micro brewers", code: "5410101011" },
    { option_label: "other brewers", code: "5410101010" },
    { option_label: "malt producers", code: "5410102013" },
    { option_label: "distilleries", code: "5410102012" },
    { option_label: "wineries", code: "5410102011" },
    { option_label: "other distillers & wineries", code: "5410102010" },
    { option_label: "bottled water & ice", code: "5410103014" },
    { option_label: "energy drinks", code: "5410103013" },
    { option_label: "fruit drinks", code: "5410103012" },
    { option_label: "carbonated soft drinks", code: "5410103011" },
    { option_label: "other non-alcoholic beverages", code: "5410103010" },
    { option_label: "cattle farming", code: "5410201029" },
    { option_label: "hog & pig farming", code: "5410201028" },
    { option_label: "agricultural biotechnology", code: "5410201027" },
    { option_label: "fishing & farming wholesale", code: "5410201026" },
    { option_label: "agricultural consultancy services", code: "5410201025" },
    { option_label: "animal feed", code: "5410201024" },
    { option_label: "organic farming", code: "5410201023" },
    { option_label: "agriculture support services", code: "5410201022" },
    { option_label: "animal breeding", code: "5410201021" },
    { option_label: "fur farming", code: "5410201020" },
    { option_label: "aquaculture", code: "5410201019" },
    { option_label: "commercial fishing", code: "5410201018" },
    { option_label: "commercial nurseries", code: "5410201017" },
    { option_label: "sugarcane farming", code: "5410201016" },
    { option_label: "coffee, tea & cocoa farming", code: "5410201015" },
    { option_label: "vegetable, fruit & nut farming", code: "5410201014" },
    { option_label: "sheep & specialty livestock farming", code: "5410201013" },
    { option_label: "poultry farming", code: "5410201012" },
    { option_label: "grain (crop) production", code: "5410201011" },
    { option_label: "other fishing & farming", code: "5410201010" },
    { option_label: "pet food manufacturing", code: "5410202031" },
    { option_label: "frozen food manufacturing", code: "5410202030" },
    { option_label: "all other food manufacturing", code: "5410202029" },
    { option_label: "ready-made meals", code: "5410202028" },
    { option_label: "baby food", code: "5410202027" },
    { option_label: "food ingredients", code: "5410202026" },
    { option_label: "special foods & wellbeing products", code: "5410202025" },
    {
      option_label: "snack food & non-chocolate confectionary",
      code: "5410202024",
    },
    { option_label: "chocolate & confectionery", code: "5410202023" },
    { option_label: "sugar & artificial sweeteners", code: "5410202022" },
    { option_label: "coffee & tea", code: "5410202021" },
    {
      option_label: "starch, vegetable fat & oil manufacturing",
      code: "5410202020",
    },
    { option_label: "dairy products", code: "5410202019" },
    {
      option_label: "seafood product preparation & packaging",
      code: "5410202018",
    },
    { option_label: "halal meat processing", code: "5410202017" },
    { option_label: "meat processing", code: "5410202016" },
    { option_label: "fruit & vegetable processing", code: "5410202015" },
    {
      option_label: "cookie, cracker & pasta manufacturing",
      code: "5410202014",
    },
    { option_label: "breakfast cereal manufacturing", code: "5410202013" },
    {
      option_label: "bread & bakery product manufacturing",
      code: "5410202012",
    },
    { option_label: "flour milling", code: "5410202011" },
    { option_label: "other food processing", code: "5410202010" },
    { option_label: "chewing tobacco products", code: "5410203014" },
    { option_label: "cigars & cigarette manufacturing", code: "5410203013" },
    { option_label: "tobacco stemming & redrying", code: "5410203012" },
    { option_label: "tobacco farming", code: "5410203011" },
    { option_label: "other tobacco", code: "5410203010" },
    { option_label: "auto cleaning products", code: "5420101016" },
    { option_label: "pet & plant protection agents", code: "5420101015" },
    { option_label: "brooms, brushes & dustpans", code: "5420101014" },
    { option_label: "air fresheners", code: "5420101013" },
    { option_label: "cleaning supplies", code: "5420101012" },
    { option_label: "laundry supplies", code: "5420101011" },
    { option_label: "other household products", code: "5420101010" },
    { option_label: "birth control products", code: "5420102015" },
    { option_label: "hair accessories", code: "5420102014" },
    { option_label: "sanitary products", code: "5420102013" },
    { option_label: "luxury cosmetics", code: "5420102012" },
    { option_label: "cosmetics & perfumes", code: "5420102011" },
    { option_label: "other personal products", code: "5420102010" },
    { option_label: "funeral services", code: "5420103018" },
    { option_label: "personal care services", code: "5420103017" },
    { option_label: "consumer repair services", code: "5420103016" },
    { option_label: "child care & family services", code: "5420103015" },
    { option_label: "general education services", code: "5420103014" },
    { option_label: "personal legal services", code: "5420103013" },
    { option_label: "accounting & tax preparation", code: "5420103012" },
    { option_label: "consumer goods rental", code: "5420103011" },
    { option_label: "other personal services", code: "5420103010" },
    { option_label: "retail - drugs without grocery", code: "5430101012" },
    { option_label: "retail - drugs with grocery", code: "5430101011" },
    { option_label: "other drug retailers", code: "5430101010" },
    { option_label: "food markets", code: "5430102016" },
    { option_label: "tobacco stores", code: "5430102015" },
    { option_label: "vending machine providers", code: "5430102014" },
    { option_label: "beer, wine & liquor stores", code: "5430102013" },
    { option_label: "supermarkets & convenience stores", code: "5430102012" },
    { option_label: "food wholesale", code: "5430102011" },
    { option_label: "other food retail & distribution", code: "5430102010" },
    { option_label: "islamic banks", code: "5510101015" },
    { option_label: "private banks", code: "5510101014" },
    { option_label: "money center banks", code: "5510101013" },
    { option_label: "retail & mortgage banks", code: "5510101012" },
    { option_label: "corporate banks", code: "5510101011" },
    { option_label: "other banks", code: "5510101010" },
    { option_label: "microfinancing", code: "5510103015" },
    { option_label: "credit unions", code: "5510103014" },
    { option_label: "consumer leasing", code: "5510103013" },
    { option_label: "consumer credit cards services", code: "5510103012" },
    { option_label: "personal & car loans", code: "5510103011" },
    { option_label: "other consumer lending", code: "5510103010" },
    { option_label: "commercial leasing", code: "5510105015" },
    { option_label: "factoring", code: "5510105014" },
    { option_label: "international trade financing", code: "5510105013" },
    { option_label: "import-export banks", code: "5510105012" },
    { option_label: "commercial loans", code: "5510105011" },
    { option_label: "other corporate financial services", code: "5510105010" },
    { option_label: "merchant banks", code: "5510201015" },
    {
      option_label: "islamic investment banking & brokerage services",
      code: "5510201014",
    },
    { option_label: "inter-dealer broker", code: "5510201013" },
    { option_label: "brokerage services", code: "5510201012" },
    { option_label: "investment banking", code: "5510201011" },
    {
      option_label: "other investment banking & brokerage services",
      code: "5510201010",
    },
    {
      option_label: "islamic investment management & fund operators",
      code: "5510202017",
    },
    { option_label: "private equity", code: "5510202016" },
    { option_label: "venture capital", code: "5510202015" },
    { option_label: "wealth management", code: "5510202014" },
    {
      option_label: "collective investment fund operators",
      code: "5510202013",
    },
    { option_label: "hedge funds", code: "5510202012" },
    { option_label: "investment management", code: "5510202011" },
    {
      option_label: "other investment management & fund operators",
      code: "5510202010",
    },
    { option_label: "diversified investment services", code: "5510203010" },
    {
      option_label: "financial technology & infrastructure",
      code: "5510205013",
    },
    {
      option_label: "clearing, settlement & custodial service",
      code: "5510205012",
    },
    { option_label: "securities & commodity exchanges", code: "5510205011" },
    {
      option_label:
        "other financial & commodity market operators & service providers",
      code: "5510205010",
    },
    { option_label: "insurance brokers", code: "5530101012" },
    { option_label: "islamic insurance", code: "5530101011" },
    { option_label: "other multiline insurance & brokers", code: "5530101010" },
    { option_label: "casualty insurance", code: "5530102014" },
    { option_label: "travel insurance", code: "5530102013" },
    { option_label: "insurance - automobile", code: "5530102012" },
    { option_label: "property insurance", code: "5530102011" },
    { option_label: "other property & casualty insurance", code: "5530102010" },
    { option_label: "health insurance", code: "5530103012" },
    { option_label: "life insurance", code: "5530103011" },
    { option_label: "other life & health insurance", code: "5530103010" },
    { option_label: "property & casualty reinsurance", code: "5530105012" },
    { option_label: "life & health reinsurance", code: "5530105011" },
    { option_label: "other reinsurance", code: "5530105010" },
    {
      option_label: "residential real estate rental & development",
      code: "5540201014",
    },
    {
      option_label: "industrial real estate rental & development",
      code: "5540201013",
    },
    {
      option_label: "retail real estate rental & development",
      code: "5540201012",
    },
    {
      option_label: "office real estate rental & development",
      code: "5540201011",
    },
    {
      option_label: "other real estate rental, development & operations",
      code: "5540201010",
    },
    { option_label: "residential real estate services", code: "5540202014" },
    { option_label: "industrial real estate services", code: "5540202013" },
    { option_label: "retail real estate services", code: "5540202012" },
    { option_label: "office real estate services", code: "5540202011" },
    { option_label: "other real estate services", code: "5540202010" },
    { option_label: "diversified reits", code: "5540301010" },
    { option_label: "industrial reits", code: "5540302013" },
    { option_label: "retail reits", code: "5540302012" },
    { option_label: "office reits", code: "5540302011" },
    { option_label: "other commercial reits", code: "5540302010" },
    { option_label: "residential reits", code: "5540303010" },
    { option_label: "islamic reits", code: "5540304016" },
    { option_label: "mortgage reits", code: "5540304015" },
    { option_label: "timber reits", code: "5540304014" },
    { option_label: "self-storage reits", code: "5540304013" },
    { option_label: "hospitality reits", code: "5540304012" },
    { option_label: "healthcare reits", code: "5540304011" },
    { option_label: "other specialized reits", code: "5540304010" },
    { option_label: "uk investment trusts", code: "5550101010" },
    { option_label: "islamic mutual funds", code: "5550102011" },
    { option_label: "other mutual funds", code: "5550102010" },
    { option_label: "closed end funds", code: "5550103010" },
    { option_label: "islamic commodity etfs", code: "5550104012" },
    { option_label: "islamic etfs", code: "5550104011" },
    { option_label: "other exchange-traded funds", code: "5550104010" },
    { option_label: "pension funds", code: "5550105010" },
    { option_label: "insurance funds", code: "5550106010" },
    { option_label: "shell companies", code: "5560101011" },
    { option_label: "other investment holding companies", code: "5560101010" },
    {
      option_label: "advanced medical equipment wholesale",
      code: "5610101016",
    },
    {
      option_label: "medical software & technology services",
      code: "5610101015",
    },
    { option_label: "medical imaging systems", code: "5610101014" },
    { option_label: "laser equipment", code: "5610101013" },
    { option_label: "medical monitoring systems", code: "5610101012" },
    {
      option_label: "medical diagnostic & testing equipment",
      code: "5610101011",
    },
    {
      option_label: "other advanced medical equipment & technology",
      code: "5610101010",
    },
    { option_label: "drug delivery systems", code: "5610102019" },
    {
      option_label: "veterinary medical equipment & supplies",
      code: "5610102018",
    },
    {
      option_label: "laboratory diagnostic & testing substances",
      code: "5610102017",
    },
    {
      option_label: "glasses, spectacles & contact lenses",
      code: "5610102016",
    },
    { option_label: "medical equipment wholesale", code: "5610102015" },
    { option_label: "medical devices & implants", code: "5610102014" },
    { option_label: "medical equipment", code: "5610102013" },
    { option_label: "medical prosthetics", code: "5610102012" },
    { option_label: "medical supplies", code: "5610102011" },
    {
      option_label: "other medical equipment, supplies & distribution",
      code: "5610102010",
    },
    { option_label: "alternative medicine facilities", code: "5610201019" },
    { option_label: "home healthcare services", code: "5610201018" },
    { option_label: "telemedicine services", code: "5610201017" },
    { option_label: "veterinary services", code: "5610201016" },
    { option_label: "medical & diagnostic laboratories", code: "5610201015" },
    { option_label: "doctor's office", code: "5610201014" },
    { option_label: "ambulance & emergency services", code: "5610201013" },
    { option_label: "residential & long-term care", code: "5610201012" },
    {
      option_label: "hospitals, clinics & primary care services",
      code: "5610201011",
    },
    {
      option_label: "other healthcare facilities & services",
      code: "5610201010",
    },
    { option_label: "hmo medical centers", code: "5610202011" },
    { option_label: "other managed healthcare", code: "5610202010" },
    { option_label: "alternative medicine", code: "5620104016" },
    { option_label: "generic pharmaceuticals", code: "5620104015" },
    { option_label: "veterinary drugs", code: "5620104014" },
    {
      option_label: "in-vivo diagnostic & testing substances",
      code: "5620104013",
    },
    { option_label: "biopharmaceuticals", code: "5620104012" },
    {
      option_label: "proprietary & advanced pharmaceuticals",
      code: "5620104011",
    },
    { option_label: "other pharmaceuticals", code: "5620104010" },
    { option_label: "bio medical devices", code: "5620201013" },
    { option_label: "bio diagnostics & testing", code: "5620201012" },
    { option_label: "bio therapeutic drugs", code: "5620201011" },
    {
      option_label: "other biotechnology & medical research",
      code: "5620201010",
    },
    { option_label: "nfc & rfid systems", code: "5710101015" },
    { option_label: "semiconductor wholesale", code: "5710101014" },
    { option_label: "processors", code: "5710101013" },
    { option_label: "memory chips (ram)", code: "5710101012" },
    { option_label: "integrated circuits", code: "5710101011" },
    { option_label: "other semiconductors", code: "5710101010" },
    { option_label: "semiconductor equipment wholesalers", code: "5710102013" },
    {
      option_label: "semiconductor testing equipment & service",
      code: "5710102012",
    },
    {
      option_label: "semiconductor machinery manufacturing",
      code: "5710102011",
    },
    {
      option_label: "other semiconductor equipment & testing",
      code: "5710102010",
    },
    { option_label: "fiber optic cable manufacturing", code: "5710201017" },
    { option_label: "satellite communications network", code: "5710201016" },
    { option_label: "broadcasting equipment", code: "5710201015" },
    { option_label: "voip equipment & systems", code: "5710201014" },
    { option_label: "conferencing tools & systems", code: "5710201013" },
    { option_label: "security & surveillance", code: "5710201012" },
    { option_label: "network equipment", code: "5710201011" },
    { option_label: "other communications & networking", code: "5710201010" },
    { option_label: "electronic component", code: "5710401014" },
    { option_label: "display screens", code: "5710401013" },
    { option_label: "advanced electronic equipment", code: "5710401012" },
    { option_label: "biometric products", code: "5710401011" },
    { option_label: "other electronic equipment & parts", code: "5710401010" },
    { option_label: "office equipment wholesale", code: "5710501015" },
    { option_label: "scientific & precision equipment", code: "5710501014" },
    { option_label: "point of sale systems", code: "5710501013" },
    { option_label: "office technology equipment", code: "5710501012" },
    { option_label: "commercial document management", code: "5710501011" },
    { option_label: "other office equipment", code: "5710501010" },
    { option_label: "consumer document management", code: "5710601019" },
    {
      option_label: "computer hardware component assembly",
      code: "5710601018",
    },
    { option_label: "storage devices", code: "5710601017" },
    { option_label: "servers & systems", code: "5710601016" },
    { option_label: "computer peripherals", code: "5710601015" },
    { option_label: "input devices", code: "5710601014" },
    { option_label: "tablet & netbook computers", code: "5710601013" },
    { option_label: "laptop & desktop computers", code: "5710601012" },
    { option_label: "scientific & super computers", code: "5710601011" },
    { option_label: "other computer hardware", code: "5710601010" },
    { option_label: "mobile device component assembly", code: "5710602015" },
    { option_label: "electronic books", code: "5710602014" },
    { option_label: "personal music players", code: "5710602013" },
    { option_label: "portable satellite navigation", code: "5710602012" },
    { option_label: "phones & smart phones", code: "5710602011" },
    { option_label: "other phones & handheld devices", code: "5710602010" },
    { option_label: "consumer electronic wholesale", code: "5710603014" },
    { option_label: "home audio", code: "5710603013" },
    { option_label: "tv & video", code: "5710603012" },
    { option_label: "photographic equipment", code: "5710603011" },
    { option_label: "other household electronics", code: "5710603010" },
    { option_label: "cloud computing services", code: "5720101015" },
    { option_label: "it testing services", code: "5720101014" },
    {
      option_label: "technology consulting & outsourcing services",
      code: "5720101013",
    },
    { option_label: "computer training", code: "5720101012" },
    { option_label: "computer programming", code: "5720101011" },
    { option_label: "other it services & consulting", code: "5720101010" },
    { option_label: "server & database software", code: "5720102017" },
    {
      option_label: "programming software & testing tools",
      code: "5720102016",
    },
    { option_label: "mobile system software", code: "5720102015" },
    { option_label: "mobile application software", code: "5720102014" },
    { option_label: "enterprise software", code: "5720102013" },
    { option_label: "application software", code: "5720102012" },
    { option_label: "system software", code: "5720102011" },
    { option_label: "other software", code: "5720102010" },
    { option_label: "internet gaming", code: "5720103016" },
    {
      option_label: "internet security & transactions services",
      code: "5720103015",
    },
    { option_label: "content & site management services", code: "5720103014" },
    { option_label: "e-commerce & auction services", code: "5720103013" },
    { option_label: "social media & networking", code: "5720103012" },
    { option_label: "search engines", code: "5720103011" },
    { option_label: "other online services", code: "5720103010" },
    { option_label: "voip services", code: "5810101015" },
    {
      option_label: "telecommunications network infrastructure",
      code: "5810101014",
    },
    { option_label: "internet service providers", code: "5810101013" },
    { option_label: "telecommunications resellers", code: "5810101012" },
    { option_label: "wired telecommunications carriers", code: "5810101011" },
    {
      option_label: "other integrated telecommunications services",
      code: "5810101010",
    },
    { option_label: "wireless telecoms service providers", code: "5810102014" },
    { option_label: "wi-fi & wi-max providers", code: "5810102013" },
    { option_label: "satellite service operators", code: "5810102012" },
    { option_label: "alternative communications services", code: "5810102011" },
    {
      option_label: "other wireless telecommunications services",
      code: "5810102010",
    },
    { option_label: "nuclear utilities", code: "5910101013" },
    { option_label: "fossil fuel electric utilities", code: "5910101012" },
    { option_label: "renewable utilities", code: "5910101011" },
    { option_label: "other electric utilities", code: "5910101010" },
    { option_label: "nuclear ipps", code: "5910102013" },
    { option_label: "renewable ipps", code: "5910102012" },
    { option_label: "fossil fuel ipps", code: "5910102011" },
    { option_label: "other independent power producers", code: "5910102010" },
    { option_label: "natural gas distribution", code: "5910201011" },
    { option_label: "other natural gas utilities", code: "5910201010" },
    { option_label: "heating & air-conditioning supply", code: "5910301013" },
    { option_label: "sewage treatment facilities", code: "5910301012" },
    { option_label: "water supply & irrigation systems", code: "5910301011" },
    { option_label: "other water & related utilities", code: "5910301010" },
    { option_label: "multiline utilities", code: "5910401010" },
  ];
};
