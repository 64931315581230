import React, { useEffect } from 'react';
import AuthLayout from '../../Components/Layouts/Auth';
import useQuery from '../../Utils/queryParamsExtract';
import { Link } from 'react-router-dom';
import api from '../../axiosAPI';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
const VerifyEmail = () => {
     const navigate = useNavigate();
     const query = useQuery();
     const email = query.get('email')
     const emailverified = query.get('emailverified')

     const handleEmailResend = () => {
          api.post('/user/resend-email-verification', { email }).then(res => {
               if (res.data.success) {
                    toast.success("Email sent");
               }
          }).catch(error => {
               toast.error(error.response.data.message, { position: "bottom-right" });
          })
     }

     useEffect(() => {
          if (emailverified) {
               navigate('/login')
          }
     }, [])

     return <AuthLayout authType="emailVerify">
          <div className='my-160' style={{ maxWidth: '457px' }}>
               <p className='font-inter fw-500 heading_s'>You will need to verify your email to complete Sign-up process. An email has been sent to <span className='color_secondary'>{email}</span></p>
               <div className='d-flex align-items-center justify-content-between mt-3'>
                    <p className='font-inter heading_xs color_secondary cursor_p fw-400' onClick={handleEmailResend}>Resend verification email</p>
                    <p className='font-inter heading_xs color_secondary cursor_p fw-400'>
                         <Link to="/login">Log in</Link>
                    </p>
               </div>
          </div>
     </AuthLayout>
}


export default VerifyEmail;