import React from "react";
import Logo from "../../../Assets/Images/SmallLogo.png";
import "./DashNav.css";
import UserInfo from "../../UserInfo";
import { NavLink } from "react-router-dom";

const DashNav = ({ isDashboard }) => {
  return (
    <div className="dashNav_root">
      <div className="dashNav_main">
        <div className="d-flex align-items-center">
          <NavLink to="/">
            <div className="dashNav_logo">
              <img src={Logo} alt="Logo" />
            </div>
          </NavLink>

          {
            isDashboard && <div className="dashNav_tabs_root d-flex align-items-center ml-40">
              <NavLink to='/dashboard/my-reports' className={({ isActive }) =>
                isActive ? "nav-active" : ""
              }>
                <p className="body2">My Reports</p>
              </NavLink>
              {/* <NavLink to='/dashboard/analytics'>
              <p className="body2 ml-40">Analytics</p>
            </NavLink> */}
            </div>
          }

        </div>
        <div>
          <UserInfo isDashboard={true} />
        </div>
      </div>
    </div>
  );
};

export default DashNav;
