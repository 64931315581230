import React from "react";
import { ReactComponent as Questionnaire } from "../../../Assets/Images/Questionnaire.svg";
import { ReactComponent as Financial } from "../../../Assets/Images/financial.svg";
import country from "../../../Assets/Images/country.svg";
import { useMediaQuery } from "react-responsive";
import "./works.css";
const HowItWorks = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <div id="howItWorks">
      <div className="section_info">
        <h1 className="section_info--header">How it works</h1>
        <p className="section_info--text">
          Valuation can be complex, but we&apos;ve simplified the process for
          you. <br /> Discover how it works on our platform.
        </p>

        <div
          className={`section_main ${
            isDesktopOrLaptop ? "px-80" : "px-3"
          } mt-80`}
        >
          <div className="flex-responsive justify-content-between">
            <div className={`${isDesktopOrLaptop && "px-4"}`}>
              <div className="works_card">
                <div className="d-flex align-items-end">
                  <Questionnaire />
                  <p className="fw-700 lh-150 text_s color_light_v1 text-uppercase">
                    Questionnaire
                  </p>
                </div>
                <div className="fw-500 lh-140 font-inter heading_l mt-24">
                  Capture the qualitative aspects of your business
                </div>
                <div className="font-inter fw-500 heading_m color_light_v2 mt-24">
                  37 multiple choice questions completed in few minutes to
                  capture elements such as team experience, market competition,
                  and barriers to entry.
                </div>
              </div>
            </div>
            <div className="">
              <div className="works_card">
                <div className="d-flex align-items-end">
                  <Financial />
                  <p className="fw-700 lh-150 text_s color_light_v1 text-uppercase">
                    FINANCIAL PROJECTIONS
                  </p>
                </div>
                <div className="fw-500 lh-140 font-inter heading_l mt-24">
                  Capture the value of future cash flows
                </div>
                <div className="font-inter fw-500 heading_m color_light_v2 mt-24">
                  Insert the financial projections for the next 3 years to
                  capture the value of the future financial performance of your
                  company.
                </div>
              </div>
            </div>
          </div>
          <div className="data_card mt-40 flex-responsive justify-content-around">
            <img src={country} alt="country" className="data_card--map" />

            <div className="data_card--text">
              <div className="text-uppercase fw-500 lh-140 heading_l font-inter">
                RELIABLE AND TAILORED DATA SOURCES
              </div>
              <div className="fw-500 lh-140 heading_s font-inter color_light_v1 mt-24">
                A grounded valuation depends on grounded assumptions. This is
                why we curate the reliability of our data sources, as well as
                their specificity to your country and industry.
              </div>
            </div>
            <div
              className={`data_card--number ${!isDesktopOrLaptop && "d-flex"}`}
            >
              <div
                className={`d-flex flex-column align-items-center my-2 ${
                  !isDesktopOrLaptop && "mx-3"
                }`}
              >
                <h1 className="fw-400 lh-90 color_primary text_xl ">90</h1>
                <p className="fw-500 lh-140 heading_m font-inter">Countries</p>
              </div>
              <div
                className={`d-flex flex-column align-items-center  my-2 ${
                  !isDesktopOrLaptop && "mx-3"
                }`}
              >
                <h1 className="fw-400 lh-90 color_primary text_xl">136</h1>
                <p className="fw-500 lh-140 heading_m font-inter">Industries</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
