/** @format */

import { React, useContext } from "react";
import { FormContext } from "../Context/FormContext";
import PropTypes from "prop-types";
import "./input.css";

const Input = ({
  field_id,
  field_label,
  field_placeholder,
  field_value,
  field_type,
  key,
  field_variable,
  type,
}) => {
  const { handleChange } = useContext(FormContext);
  //  handleChange()
  return (
    <div className="row mb-24">
      <div className="col-lg-7 col-md-6 col-12">
        <label htmlFor={field_id} className="valuation_label">
          {key} {field_label} <span className="text-danger fw-500" style={{ fontSize: '1.3rem' }}>*</span>
        </label>
      </div>
      <div className="col-lg-5 col-md-6 col-12 text-end">
        <input
          name={field_variable}
          type={field_type}
          className="valuation_input"
          min="0"
          id={field_id}
          required
          aria-describedby="emailHelp"
          placeholder={field_placeholder ? field_placeholder : ""}
          value={field_value}
          onChange={(event) => handleChange(field_id, event, type)}
        />
      </div>
    </div>
  );
};

Input.propTypes = {
  field_id: PropTypes.number.isRequired,
  field_label: PropTypes.string.isRequired,
  field_placeholder: PropTypes.string.isRequired,
  field_value: PropTypes.any.isRequired,
  field_type: PropTypes.string.isRequired,
  key: PropTypes.number.isRequired,
};

export default Input;
