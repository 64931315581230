import React, { useEffect, useState } from "react";
import DashNav from "../../Components/DashBoardComponents/DashboardNav/DashNav";
import "./index.css";
import { useAuth } from '../../Utils/Auth';
import { Outlet, useNavigate } from "react-router-dom"
import LetsStart from "../../Components/Valuation/ValuationMain/LetsStart";
const Dashboard = () => {
  const navigate = useNavigate()
  const { isLoading, currentUser, hasCredit } = useAuth();
  const [createVal, setCreateVal] = useState(false);
  const handleOpenCreateVal = () => {
    setCreateVal(true)
  }
  const handleCloseCreateVal = () => {
    setCreateVal(false)
  }

  const handleCreateReport = () => {
    if (hasCredit) {
      handleOpenCreateVal()
    } else {
      navigate('/payment')
    }
  }



  useEffect(() => {
    if (!isLoading) {
      if (currentUser) {

        if (!currentUser.initialPayment) {
          navigate('/payment')
        } else if (location.pathname === '/dashboard') {
          navigate('/dashboard/my-reports')
        }
      }
    }
  }, [isLoading]);
  return (
    <div className="dashboard_root">

      <div className={`${location.pathname.includes('generate-report') ? "dasboard_main--val" : "dasboard_main"}`}>
        <DashNav isDashboard={true} />
        {
          location.pathname.includes('generate-report') ? null : <div className="dashboard_head">
            <div className="dashboard_head_content">
              <div className="mt-48">
                <p className="title2">Welcome back, {currentUser && currentUser.firstName} {currentUser && currentUser.lastName}</p>
                {/* <p className="mt-16 caption color_light_v2">
                  In publishing and graphic design, Lorem ipsum is a placeholder
                  <br />
                  text commonly used to demonstrate the visual form
                </p> */}
              </div>
              <div className="mt-48">
                {/* <p className="body2">8 credits left</p> */}
                <button className="create-report-Cta mt-24" onClick={handleCreateReport}>Create Report</button>
                {
                  createVal && <LetsStart
                    handleClose={handleCloseCreateVal}
                    open={createVal}
                  />
                }
              </div>
            </div>
          </div>
        }

      </div>
      <Outlet />


    </div>
  );
};

export default Dashboard;
