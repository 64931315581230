import React, { useContext } from 'react';
import { CircularProgress, Tooltip } from "@mui/material";
import Element from '../Elements'
import { getcurrencylist } from "../Lists/Currency";
import { FormContext } from "../Context/FormContext"
import Back from '../../../Layouts/Back';
const Tenques = ({
     tenquestions,
     submittenquestionsToEquidam,
     currency,
     type,
     tenquesLoader,
     tenquestionsFilled,
     handleChangeStep
}) => {

     const { handleChange } = useContext(FormContext);
     if (tenquestionsFilled === false)
          return (
               <div>
                    <div>
                         <div className="d-flex align-items-center">
                              <Back type="onlyIcon" />
                              <p className='fw-600 heading_s mx-3 mt-1'>Business Profile</p>
                         </div>
                         <p className="caption12 mt-2">
                              An initial estimate of your valuation based on what similar
                              companies are worth
                         </p>
                    </div>
                    <div className="mt-24 mb-3">
                         <div className="mb-3">
                              <form className='white-background p-32'>
                                   {tenquestions
                                        ? tenquestions.map((field, i) => (
                                             <>
                                                  <Element type={type} key={i} field={field} />
                                                  {field.row_label ? (
                                                       <>
                                                            <label className='valuation_label'>Revenues</label>
                                                            <div className="row mt-20">
                                                                 <div className="col-lg-3 col-md-6 col-12">
                                                                      <div>
                                                                           <p className="valuation_label">Past year ({currency})</p>
                                                                           <div className="mt-8">
                                                                                {field.past_year.type === "input" ? (
                                                                                     <Tooltip
                                                                                          title={
                                                                                               getcurrencylist().find(
                                                                                                    (v) => v.code === currency
                                                                                               )?.symbol
                                                                                          }
                                                                                          placement="left-start"
                                                                                     >
                                                                                          <input
                                                                                               type="number"
                                                                                               className="valuation_input"
                                                                                               min="0"
                                                                                               required
                                                                                               placeholder="eg: 200000"
                                                                                               name={field.past_year.field_variable}
                                                                                               value={field.past_year["value"]}
                                                                                               onChange={(event) =>
                                                                                                    handleChange(
                                                                                                         field.past_year.id,
                                                                                                         event,
                                                                                                         type
                                                                                                    )
                                                                                               }
                                                                                          />
                                                                                     </Tooltip>
                                                                                ) : (
                                                                                     <p>
                                                                                          <b>{field.past_year.value}</b>
                                                                                     </p>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                                 <div className="ml-80 col-lg-6 col-md-6 col-12">
                                                                      <div>
                                                                           <p className="valuation_label">
                                                                                In three years time ({currency})
                                                                           </p>
                                                                           <div className="mt-8">
                                                                                {field.forcast.type === "input" ? (
                                                                                     <Tooltip
                                                                                          title={
                                                                                               getcurrencylist().find(
                                                                                                    (v) => v.code === currency
                                                                                               )?.symbol
                                                                                          }
                                                                                          placement="left-start"
                                                                                     >
                                                                                          <input
                                                                                               type="number"
                                                                                               min="1000"
                                                                                               className="valuation_input"
                                                                                               required
                                                                                               placeholder="eg: 200000"
                                                                                               name={field.forcast.field_variable}
                                                                                               value={field.forcast["value"]}
                                                                                               onChange={(event) =>
                                                                                                    handleChange(
                                                                                                         field.forcast.id,
                                                                                                         event,
                                                                                                         type
                                                                                                    )
                                                                                               }
                                                                                          />
                                                                                     </Tooltip>
                                                                                ) : (
                                                                                     <p>
                                                                                          <b>{field.forcast.value}</b>
                                                                                     </p>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </>
                                                  ) : null}
                                                  {/* <hr style={{ margin: "24px 0" }} /> */}
                                             </>
                                        ))
                                        : null}

                              </form>
                              <div className="text-left mt-5 d-flex align-items-center">
                                   <button
                                        onClick={submittenquestionsToEquidam}
                                        className="cta_lets_start d-flex align-items-center"
                                   >
                                        Estimate my Valuation
                                   </button>
                                   {tenquesLoader ? (
                                        <span className="ml-8">
                                             <CircularProgress style={{ color: "#000" }} />
                                        </span>
                                   ) : null}
                              </div>
                         </div>
                    </div>
               </div>
          );
     else if (tenquestionsFilled === true) {
          handleChangeStep(0)
     } else
          return (
               <div className="v_center">
                    <CircularProgress style={{ color: "#000" }} />
               </div>
          );
}

export default Tenques;