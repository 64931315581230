/** @format */
import Tooltip from '@mui/material/Tooltip';
import { React, useContext } from "react";
import { FormContext } from "./Context/FormContext";
// import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import { getcurrencylist } from "./Lists/Currency";

export default function TrasacElement({
  content: { h1, p, fields },
  currency,
}) {
  const { handletransaction } = useContext(FormContext);
  return (
    <div className="mt-32 mb-32">
      <p className="body1">{`${h1}(${currency})*`}</p>
      <p className="mt-8 caption color_grey_46">{p}</p>
      {fields
        ? fields.map((field, i) => (
          <div key={i} className="row align-items-end mt-24">
            <div className="col-lg-4 col-md-4 col-6 d-flex flex-column">
              <label htmlFor={field.field_id} className="valution_label">
                {field.field_title}
              </label>
              <Tooltip
                title={
                  getcurrencylist().find((v) => v.code === currency)?.symbol
                }
                placement="left-start"
              >
                <input
                  // type='text'
                  className="input1"
                  name={field.field_variable}
                  type={field.field_type}
                  placeholder={field.field_placeholder}
                  id={field.field_id}
                  required
                  value={field.fieldvalue}
                  onChange={(event) =>
                    handletransaction(field.field_id, event)
                  }
                />
              </Tooltip>
            </div>
            <div className="col-lg-3 col-md-4 col-6">
              {" "}
              <label className="valution_label">Percentage (%)</label>
              <Tooltip title={"%"} placement="right-end">
                <input
                  // type='text'
                  disabled
                  className="input1 "
                  name={field.field_variable}
                  type={field.field_type}
                  placeholder={field.field_placeholder}
                  id={field.field_id}
                  required
                  value={
                    field.field_variable === "capitalneeded"
                      ? 100
                      : field.fieldpercentage
                  }
                  onChange={(event) =>
                    handletransaction(field.field_id, event)
                  }
                />
              </Tooltip>
            </div>
            <div className="col-lg-5 col-md-4 col-6">
              <p className='caption10 mx-4'>{field.meta}</p>
            </div>
          </div>
        ))
        : null}
      <hr />
    </div>
  );
}
TrasacElement.propTypes = {
  content: PropTypes.any.isRequired,
  // handleNext: PropTypes.any.isRequired,
  // balanceSheet: PropTypes.any.isRequired
};
