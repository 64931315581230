import React, { useState } from 'react';
import AuthLayout from '../../Components/Layouts/Auth';
import { ForwardIcon } from '../../Components/Icons';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CryptoJS from "crypto-js";
import api from '../../axiosAPI';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {  Link } from "react-router-dom";
const Signup = () => {
     const navigate = useNavigate();
     const [isLoading, setIsLoading] = useState(false);
     const [showPassword, setShowPassword] = useState(false)
     const validationSchema = Yup.object().shape({
          firstName: Yup.string("Please enter your first name")
               .required("First name is required"),
          lastName: Yup.string("Please enter your last name")
               .required("Last name is required"),
          organizationName: Yup.string()
               .required("Organisation name is required"),
          email: Yup.string()
               .email("Please enter your email")
               .required("Email name is required"),
          password: Yup.string()
               .required("Passwort is required")
               .min(6, "Password too short! Must be at least 6 characters."),
     });

     const onSubmit = (values, actions) => {
          setIsLoading(true)
          const password = CryptoJS.AES.encrypt(values.password, "H~jP,/%6R*'Tc|xg").toString();
          const postData = { ...values, password }

          api.post('/user/signup', postData).then(res => {
               if (res.data.success) {
                    setIsLoading(false)
                    toast.success(`You're successfully signed up!`);
                    actions.resetForm();
                    navigate(`/verify-email?email=${values.email}`)
               }
          }).catch(error => {
               setIsLoading(false)
               toast.error(error.response.data.message, { position: "bottom-right" });
          })

     }

     const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({ //isSubmitting
          initialValues: {
               firstName: "",
               lastName: "",
               organizationName: "",
               email: "",
               password: "",

          },
          onSubmit,
          enableReinitialize: true,
          validationSchema: validationSchema
     })

     return <AuthLayout authType="signup">

          <form className='mt-30' autoComplete="off" onSubmit={handleSubmit}>
               <div className='d-flex justify-content-between'>
                    <div>
                         <label className='auth_label' htmlFor="firstName">First Name</label>
                         <input
                              type="text"
                              id="firstName"
                              className={`auth_input auth_input--small mt-1 ${touched.firstName && errors.firstName ? 'border-danger' : ''}`}
                              placeholder='Enter first name'
                              value={values.firstName}
                              onChange={handleChange('firstName')}
                              onBlur={handleBlur}
                         />

                         {
                              (touched.firstName && errors.firstName) && <div className='text-danger mt-1'>{errors.firstName}</div>
                         }

                    </div>

                    <div>
                         <label className='auth_label' htmlFor="lastName">Last Name</label>
                         <input
                              type="text"
                              id="lastName"
                              className={`auth_input auth_input--small mt-1 ${(touched.lastName && errors.lastName) && 'border-danger'}`}
                              placeholder='Enter last name'
                              value={values.lastName}
                              onChange={handleChange('lastName')}
                              onBlur={handleBlur}
                         />
                         {
                              (touched.lastName && errors.lastName) && <div className='text-danger mt-1'>{errors.lastName}</div>
                         }

                    </div>

               </div>

               <div className='mt-3'>
                    <label className='auth_label' htmlFor="organizationName">Organisation Name</label>
                    <input
                         type="text"
                         id="organizationName"
                         className={`auth_input mt-1 ${(touched.organizationName && errors.organizationName) && 'border-danger'}`}
                         placeholder='Enter your organisation name'
                         value={values.organizationName}
                         onChange={handleChange('organizationName')}
                         onBlur={handleBlur}
                    />
                    {
                         (errors.organizationName && touched.organizationName) && <div className='text-danger mt-1'>{errors.organizationName}</div>
                    }
               </div>

               <div className='mt-3'>
                    <label className='auth_label' htmlFor="email">Email</label>
                    <input
                         type="email"
                         id="email"
                         className={`auth_input mt-1 ${(errors.email && touched.email) && 'border-danger'}`}
                         placeholder='Enter your email here'
                         value={values.email}
                         onChange={handleChange('email')}
                         onBlur={handleBlur}
                    />
                    {
                         (errors.email && touched.email) && <div className='text-danger mt-1'>{errors.email}</div>
                    }

               </div>
               <div className='mt-3'>
                    <label className='auth_label' htmlFor="password">Password</label>

                    <div className='position-relative'>
                         <input type={showPassword ? "text" : "password"}
                              id="password"
                              className={`auth_input mt-1 ${(errors.password && touched.password) && 'border-danger'}`}
                              placeholder="Enter your password here"
                              value={values.password}
                              onChange={handleChange('password')}
                              onBlur={handleBlur}
                         />
                         {
                              (errors.password && touched.password) && <div className='text-danger mt-1'>{errors.password}</div>
                         }

                         <div
                              className='position-absolute cursor_p'
                              style={{ top: '30%', right: '5%' }}
                              onClick={() => setShowPassword(!showPassword)}
                         >
                              {showPassword ? (
                                   <VisibilityOff style={{ color: "#123769" }} />
                              ) : (
                                   <Visibility style={{ color: "#123769" }} />
                              )}
                         </div>
                    </div>

               </div>
               <button className='auth_btn mt-32' type='submit'>
                    {
                         isLoading ? <div className='d-flex justify-content-center w-100'>
                              <CircularProgress style={{ color: "#fff" }} />
                         </div> : <>Register <ForwardIcon /></>
                    }

               </button>
               
               <div>
                         <p className='color_light_v2 text_xs mt-2 font-inter fw-400'>Already an account? <Link to="/login"><span className='color_secondary cursor_p'>Login</span></Link></p>
               </div>
          </form>
     </AuthLayout >
}

export default Signup