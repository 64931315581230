import React, { useState } from "react";
// import ShareModal from "./ShareModal";
import { MoreVertTwoTone } from "@mui/icons-material";
import { Popover } from "@mui/material";
// import ConfirmModel from "../../../components/Modal/confirmModel";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CheckSymbol from "../../Icons/CheckSymbol";
import cover from "../../../Assets/Images/generated_report.svg";
import { CircularProgress } from '@mui/material'
import api from '../../../axiosAPI'
const ReportCard = ({
  report,
  setValuationStep,
  setnewReport,
  handleclonereport,
}) => {
  const navigate = useNavigate();

  var options = { day: "2-digit", year: "2-digit", month: "short" };

  const [reportdata, setreportdata] = useState();
  // const [title, settitle] = useState("");
  const [status, setstatus] = useState("");

  // const [open, setopen] = useState(false);

  // const handleOpenshare = () => {
  //   console.log(setreportdata, setstatus, open, openc);
  //   setopen(true);
  // };
  // const handleCloseShare = () => {
  //   setopen(false);
  // };

  const [, setOpenc] = React.useState(false); //openc
  const handleOpenc = () => setOpenc(true);
  const handleClosec = () => setOpenc(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isdownloading, setisdownloading] = React.useState(false);
  const popopen = Boolean(anchorEl);
  const popid = popopen ? "simple-popover" : undefined;
  const handleClick = (event, data, s) => {
    setAnchorEl(event.currentTarget);
    setreportdata(data);
    // settitle(data.companyName);
    setstatus(s);
  };
  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const handledownloadreport = async (c, r) => {
    setisdownloading(true)
    try {
      const response = await api.get(`/valuation/companies/${c}/reports/${r}`)
      if (response.data.success) {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(response.data.message.data).buffer])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${response.data.eqdata.reportTitle}.pdf`
        );

        document.body.appendChild(link);
        link.click();
        setisdownloading(false)
      }
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.error);
        setisdownloading(false)
        handleClosec();
        handlePopClose();
      }
    }
  };
  return (
    <>
      {/* <ShareModal
          handleClose={handleCloseShare}
          open={open}
          v_id={report._id}
        /> */}
      {/* <ConfirmModel
          open={openc}
          handleClose={handleClosec}
          confirmFunction={() => {
            handledelete(report.cid, title);
            handleClosec();
            handlePopClose();
          }}
        /> */}
      <div className="report_block white_bg h-100 d-flex flex-column justify-content-between p-24">
        <div className="d-flex align-items-center justify-content-between">
          <div>

            <p className="body2">
              {report?.companyName ? report?.companyName : "Acorn Industries"}
            </p>

            <p className="overline mt-1 grey_58">

              {new Date(report.createdAt).toLocaleDateString("en-GB")}
            </p>

          </div>

          <div>

            {report.reportGenerated ? (
              <p className="m-0 cursor_p">
                <MoreVertTwoTone
                  onClick={(e) => handleClick(e, report, "completed")}
                />
              </p>
            ) : (
              <>
                <p className="m-0 cursor_p">
                  <MoreVertTwoTone
                    onClick={(e) => handleClick(e, report, "continue")}
                  />
                </p>
              </>
            )}

          </div>
        </div>
        {
          report.reportGenerated ? <div className="generated_val_cover mt-20">
            <img src={cover} alt="cover" />
          </div> : <div className="mt-24 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {/* <div className="val_card_progress_check">
                <span>
                  <CheckSymbol />
                </span>
              </div> */}
                {report.compnanyInfoFilled ? (
                  <div className="val_card_progress_check">
                    <span>
                      <CheckSymbol />
                    </span>
                  </div>
                ) : (
                  <span className="caption val_card_progress_circle">1</span>
                )}
                <p className="caption ml-12">Company Info</p>
              </div>
            </div>
            <span className="val_card_progress_line"></span>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {/* <span className="caption val_card_progress_circle">2</span> */}
                {report.quesFilled ? (
                  <div className="val_card_progress_check">
                    <span>
                      <CheckSymbol />
                    </span>
                  </div>
                ) : (
                  <span className="caption val_card_progress_circle">2</span>
                )}
                <p className="caption ml-12">Questionnaire</p>
              </div>
            </div>
            <span className="val_card_progress_line"></span>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {/* <span className="caption val_card_progress_circle">3</span> */}
                {report.financialsFilled ? (
                  <div className="val_card_progress_check">
                    <span>
                      <CheckSymbol />
                    </span>
                  </div>
                ) : (
                  <span className="caption val_card_progress_circle">3</span>
                )}
                <p className="caption ml-12">Financial</p>
              </div>
            </div>
            <span className="val_card_progress_line"></span>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                {/* <span className="caption val_card_progress_circle">4</span> */}
                {report.transFilled ? (
                  <div className="val_card_progress_check">
                    <span>
                      <CheckSymbol />
                    </span>
                  </div>
                ) : (
                  <span className="caption val_card_progress_circle">4</span>
                )}
                <p className="caption ml-12">Funding rounds</p>
              </div>
            </div>
          </div>
        }
        {
          report.reportGenerated ? <div className="d-flex justify-content-between align-items-center mt-32">
            <p className="caption12">
              <span className="color_grey_46">Last edited: </span>
              <span>
                {/* 20/10/2010 */}
                {new Date(report.updatedAt).toLocaleDateString("en-US", options)}
              </span>
            </p>
            <p
              className="trans_cta"
              onClick={() => {
                handledownloadreport(report.cid, report.reportid);
              }}
            >
              {isdownloading ? (
                <CircularProgress
                  style={{ height: 17, width: 18, color: "#000" }}
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                  viewBox="0 0 20 20"
                  className="cursor_p"
                >
                  <path
                    fill="#000"
                    d="M15 12.5V15H5v-2.5H3.333V15c0 .917.75 1.667 1.667 1.667h10c.916 0 1.666-.75 1.666-1.666v-2.5H15zm-.834-3.333l-1.175-1.175-2.158 2.15V3.334H9.166v6.808l-2.158-2.15-1.175 1.175L10 13.334l4.166-4.167z"
                  ></path>{" "}
                </svg>
              )}
            </p>
          </div> : <div className="d-flex justify-content-between align-items-center mt-32">
            <p className="caption12">
              <span className="color_grey_46">Last edited: </span>
              <span>
                {/* 20/10/2010 */}
                {new Date(report.updatedAt).toLocaleDateString("en-US", options)}
              </span>
            </p>
            <p
              className="caption fw-600 cursor_p"
              style={{
                borderBottom: "1px solid #000",
                width: "fit-content",
              }}
              onClick={() => {
                navigate(`/dashboard/generate-report/${report.cid}/valStep/${report.tenquesFilled ? "0" : "4"
                  }`)
                localStorage.setItem("valuation_id", report.cid);
                setnewReport(false);
                setValuationStep(10);
              }}
            >
              Continue
            </p>
          </div>
        }

      </div>
      <Popover
        id={popid}
        open={popopen}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div style={{ background: "white" }} className="popOver-rootTask p-2">
          {status === "continue" ? (
            <button
              className="popover_list_cta w_100 text-left"
              onClick={() => {
                navigate(`/dashboard/generate-report/${report.cid}/valStep/${report.tenquesFilled ? "0" : "4"
                  }`)

                localStorage.setItem("valuation_id", report.cid);
                setnewReport(false);
                setValuationStep(10);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                fill="none"
                viewBox="0 0 12 12"
                className="me-2"
              >
                <path
                  fill="#000"
                  d="M11.425.575a1.966 1.966 0 00-2.78 0L1.25 7.968c-.22.22-.38.493-.463.793l-.774 2.763a.375.375 0 00.462.462l2.762-.774c.3-.083.573-.243.794-.464l7.393-7.393a1.966 1.966 0 000-2.78zm-2.25.53a1.216 1.216 0 111.72 1.72l-.77.77-1.72-1.72.77-.77zm-1.3 1.3l1.72 1.72-6.094 6.093c-.129.13-.29.223-.465.272l-2.12.594.593-2.12c.05-.176.143-.336.272-.465l6.094-6.094z"
                ></path>
              </svg>
              Edit report
            </button>
          ) : null}
          {status === "completed" ? (
            <>
              {/* <button
                className="popover_list_cta w_100 text-left"
                onClick={() => handleOpenshare()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="none"
                  viewBox="0 0 13 12"
                  className="me-2"
                >
                  <path
                    fill="#000"
                    d="M8.019.842a.438.438 0 00-.707.346v1.744a4.7 4.7 0 00-1.469.3A4.106 4.106 0 004.01 4.61c-.545.723-.946 1.725-1.07 3.1a.438.438 0 00.745.35C4.725 7.02 5.758 6.506 6.52 6.251c.316-.105.585-.166.792-.2v1.7a.438.438 0 00.728.327l3.937-3.5a.437.437 0 00-.022-.673L8.02.842zM7.7 3.81h.003a.44.44 0 00.484-.435V2.082l2.817 2.191-2.816 2.503V5.563a.437.437 0 00-.438-.438h-.04a4.18 4.18 0 00-.398.038c-.263.038-.63.112-1.068.258a7.783 7.783 0 00-2.228 1.19c.17-.626.416-1.106.693-1.474.37-.493.872-.87 1.448-1.087a3.826 3.826 0 011.523-.242l.02.002zM2.937 1.625A2.188 2.188 0 00.75 3.813v5.25a2.187 2.187 0 002.188 2.187h5.25a2.188 2.188 0 002.187-2.187v-.875a.438.438 0 00-.875 0v.875a1.313 1.313 0 01-1.313 1.312h-5.25a1.313 1.313 0 01-1.312-1.312v-5.25A1.312 1.312 0 012.938 2.5h1.75a.437.437 0 100-.875h-1.75z"
                  ></path>
                </svg>
                Share
              </button> */}
              <button
                className="popover_list_cta w-100 text-left"
                onClick={() =>
                  handledownloadreport(reportdata.cid, reportdata.reportid)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="15"
                  height="15"
                  fill="none"
                  viewBox="0 0 14 14"
                  className="me-2"
                >
                  <path
                    fill="#000"
                    d="M.438 8.662a.437.437 0 01.437.438v2.187a.875.875 0 00.875.875h10.5a.875.875 0 00.875-.875V9.1a.438.438 0 01.875 0v2.187a1.75 1.75 0 01-1.75 1.75H1.75A1.75 1.75 0 010 11.287V9.1a.438.438 0 01.438-.438z"
                  ></path>
                  <path
                    fill="#000"
                    d="M6.69 10.372a.438.438 0 00.62 0l2.625-2.625a.438.438 0 00-.62-.62l-1.878 1.88V1.312a.437.437 0 10-.875 0v7.693L4.685 7.128a.438.438 0 00-.62.62l2.625 2.624z"
                  ></path>
                </svg>
                Download
              </button>
            </>
          ) : null}

          <button
            className="popover_list_cta w-100 text-left"
            onClick={() => {
              handleclonereport(
                report.companyName,
                report.currency,
                report.cid
              );
              handlePopClose();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 10 10"
              className="me-2"
            >
              <path
                fill="#000"
                fillRule="evenodd"
                d="M.615 3.016c0-.455.357-.837.851-.838h.813s-.021-.486-.021-.873.34-.775.834-.775c.494 0 5.075-.014 5.54.02.465.035.834.327.834.797L9.463 6.91c0 .431-.387.824-.867.829-.48.005-.805-.005-.805-.005s.017.545.017.88c0 .335-.389.82-.846.82-.457 0-5.092.03-5.535.03a.851.851 0 01-.831-.874c.019-.485.019-5.117.019-5.573zm2.519-1.558L3.13 6.827c0 .06.049.109.11.108l5.225-.028a.11.11 0 00.11-.11l-.004-5.32a.11.11 0 00-.11-.11L3.243 1.35a.109.109 0 00-.11.108zM1.46 3.238l.05 5.264a.11.11 0 00.11.109l5.148-.04a.217.217 0 00.216-.22l-.007-.609s-3.439.032-3.886.032c-.447 0-.844-.423-.844-.9 0-.476.032-3.757.032-3.757a.106.106 0 00-.108-.108l-.494.007a.22.22 0 00-.217.223z"
                clipRule="evenodd"
              ></path>
            </svg>
            Clone
          </button>
          <button
            className="popover_list_cta w-100 text-left"
            onClick={() => handleOpenc()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              fill="none"
              viewBox="0 0 12 12"
              className="me-2"
            >
              <path
                fill="#000"
                d="M7.05 1.8a1.05 1.05 0 00-2.1 0h-.7a1.75 1.75 0 113.5 0h3.15a.35.35 0 110 .7h-.388l-.907 7.86a1.4 1.4 0 01-1.39 1.24h-4.43a1.4 1.4 0 01-1.39-1.24L1.487 2.5H1.1a.35.35 0 01-.344-.287L.75 2.15a.35.35 0 01.35-.35h5.95zm2.757.7H2.193l.897 7.78a.7.7 0 00.696.62h4.428a.7.7 0 00.695-.62l.898-7.78zM4.95 4.25c.171 0 .315.108.344.251l.006.055v4.288c0 .169-.157.306-.35.306-.171 0-.315-.109-.344-.252L4.6 8.844V4.557c0-.17.157-.307.35-.307zm2.1 0c.171 0 .315.108.344.251l.006.055v4.288c0 .169-.157.306-.35.306-.171 0-.315-.109-.344-.252L6.7 8.844V4.557c0-.17.157-.307.35-.307z"
              ></path>
            </svg>
            Delete
          </button>
        </div>
      </Popover>
    </>
  );
};

export default ReportCard;
