import React from "react";
import "./pricing.css";
import securePayment from "../../../Assets/Images/ri_secure-payment-line.svg";
import download from "../../../Assets/Images/bi_filetype-pdf.svg";
import support from "../../../Assets/Images/ph_headset-bold.svg";
import {Link} from 'react-router-dom'
const Pricing = () => {
  const pricing = new Intl.NumberFormat('en-us', {
    currency: 'INR',
    style: 'currency',
    minimumFractionDigits: 0,
  })
  return (
    <div id="pricing">
      <div className="section_info">
        <h1 className="section_info--header">Pricing</h1>
        <p className="section_info--text">
          Get your business valued without breaking the bank. Our website offers
          affordable <br /> pricing options for businesses of all sizes. Start
          your valuation journey today!
        </p>

        <div className="pricing_feature_rows flex-responsive align-items-center justify-content-center">


          <div className="pricing_card pricing_card--main d-flex flex-column align-items-center">
            <p className="fw-400 heading_l lh-36"><del>{pricing.format(12000)}</del></p>
            {/* <div className="pricing_discount mt-5">
              <p className="color_primary font-inter lh-19 heading_xs fw-500">20% Discount</p>
            </div> */}
            <br/>
        
            <p className="fw-400 text_l lh-90 mt-4">{pricing.format(10000)}</p>
            <p className="font-inter fw-800 lh-19 font12 mb-8">(Excl. GST)</p>
            <p className="font-inter fw-500 heading_m lh-19">per report</p>
            <Link to="/signup">
              <button className="btn_pricing fw-600 heading_m lh-22 mt-5">
                Get started
              </button>
            </Link>
            <br/>
          </div>


          <div>

            <div className=" pricing_card pricing_desc_card">
              <div className="d-flex align-items-center">
                <img src={securePayment} alt="secure payment" />
                <h1 className="fw-700 heading_xs mx-2">Secure Payments</h1>
              </div>
              <div className="my-2">
                <p className="fw-500 font-inter color_accent text_xs lh-140">
                  We ensure your personal and financial information is protected
                  every step of the way.{" "}
                </p>
              </div>
            </div>

            <div className="pricing_card pricing_desc_card">
              <div className="d-flex align-items-center">
                <img src={download} alt="download" />
                <h1 className="fw-700 heading_xs mx-2">
                  Downloadable PDF reports
                </h1>
              </div>
              <div className="my-2">
                <p className="fw-500 font-inter color_accent text_xs lh-140">
                  Get access to in-depth insights and analysis with our
                  downloadable PDF reports.{" "}
                </p>
              </div>
            </div>

            <div className=" pricing_card pricing_desc_card">
              <div className="d-flex align-items-center">
                <img src={support} alt="support" />
                <h1 className="fw-700 heading_xs mx-2">customer support</h1>
              </div>
              <div className="my-2">
                <p className="fw-500 font-inter color_accent text_xs lh-140">
                  Full customer support available! Reach out anytime with
                  questions or concerns - we&apos;re here to help!
                </p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default Pricing;
