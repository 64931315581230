import React from "react";

const CheckSymbol = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="8"
      fill="none"
      viewBox="0 0 10 8"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M9.668 1.374L4.486 7.853.416 4.46l.768-.921 3.13 2.607L8.732.625l.936.749z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CheckSymbol;
