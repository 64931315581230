import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "./contact.css";

const Contact = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");

  const onsubmit = async (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      toast.error("Please fill all the details");
      return;
    }
    if (name && name.length > 50) {
      toast.error("Name too long");
      return;
    }
    if (email && email.length > 50) {
      toast.error("Email too long");
      return;
    }
    if (message && message.length > 400) {
      toast.error("Message too long");
      return;
    }
    await axios({
      method: "post",
      url: "https://sheet.best/api/sheets/d2e93803-635c-4533-894b-d01c63e6eba8",
      data: { name, email, message },
    }).then((res) => {
      if (res.status === 200)
        toast.success(
          "Thankyou for getting in touch, we will reach out to you shortly"
        );
      setemail("");
      setname("");
      setmessage("");
    });
  };

  return (
    <div className="section_info" id="contactUs">
      <div className=" d-flex flex-column align-items-center">
        <h1 className="section_info--header">Contact us</h1>
        <p className="section_info--text">
          Get in touch with us.
          {/* We&apos;re here to help with any questions
          <br />
          or concerns you may have. */}
        </p>

        <form onSubmit={onsubmit}>
          <div className="d-flex flex-column align-items-start justify-content-center">
            <input
              type="text"
              className="contact_input"
              placeholder="Full name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
            <input
              type="email"
              className="contact_input"
              placeholder="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <textarea
              type="text"
              className="contact_textarea"
              placeholder="message"
              value={message}
              onChange={(e) => setmessage(e.target.value)}
            />
            <label className="checkbox-container">
              <input name="subscribe" id="subscribe" type="checkbox" />
              <label className="mx-4" htmlFor="subscribe">
                Subscribe to our newsletter
              </label>
              <span className="checkbox-checkmark"></span>
            </label>
            <button className="header_btn--small" type="submit">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
