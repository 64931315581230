import React, { useEffect, useState } from 'react'
import ReportCard from './ReportCard'
import api from '../../../axiosAPI';
import emptyReports from '../../../Assets/emptyReports.svg'
const MyReports = () => {
     const [reports, setReports] = useState([])
     const fetchReport = () => {
          api.get('/valuation/fetchMyReports').then(res => {
               setReports(res.data.reports)
          })
     }
     useEffect(() => {
          fetchReport();
     }, [])
     return (
          <div className="dasboard_reports_root">
               <div className="dasboard_reports_max">
                    <div className="row">
                         {
                              reports && reports.length > 0 ? reports.map(report => {
                                   return <div className="col-lg-4 col-ms-6 col-sm-6 col-12 mb-24" key={report.cid}>

                                        <ReportCard report={report} />
                                   </div>
                              }) : <img src={emptyReports} alt="Empty Reports" />
                         }
                    </div>
               </div>
          </div>
     )
}

export default MyReports