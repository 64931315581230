/** @format */

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React, { useContext, useEffect } from "react";
import { FormContext } from "../Context/FormContext";
import PropTypes from "prop-types";
import "./select.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const Select = ({
  key,
  field_id,
  field_label,
  field_value,
  field_options,
  isDropDown,
  field_mandatory,
  field_variable,
  type,
}) => {
  const { handleChange } = useContext(FormContext);
  const defaultProps = {
    options: field_options.sort(function (a, b) {
      var textA = a.option_label.toUpperCase();
      var textB = b.option_label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }),
    getOptionLabel: (option) =>
      option.option_label.charAt(0).toUpperCase() +
      option.option_label.slice(1),
  };

  const [value, setValue] = React.useState(null);
  useEffect(() => {
    if (field_id === "19") {
      const o = field_options?.find((v) => v.id === field_value);
      if (o) setValue(o);
    } else if (field_id === "20") {
      const o = field_options?.find((v) => v.code === field_value);
      if (o) setValue(o);
    }
  }, [field_value]);

  return (
    <>
      {isDropDown ? (
        <>
          <div className="row mb-24">
            <div className="col-lg-7 col-md-6 col-12">
              <label className="valuation_label">
                {key} {field_label} <span className="text-danger fw-500" style={{ fontSize: '1.3rem' }}>*</span>
              </label>
            </div>
            <div className="col-lg-5 col-md-6 col-12">
              <Autocomplete
                {...defaultProps}
                id="disable-close-on-select"
                value={value}
                defaultValue={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  if (newValue)
                    handleChange(
                      field_id,
                      {
                        target: {
                          value:
                            field_id === "19" ? newValue.id : newValue.code,
                          name: field_variable,
                          type,
                        },
                      },
                      type
                    );
                }}
                disablePortal
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={field_id === "19" ? "Country" : "Industry"}
                  />
                )}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-24">
            <FormControl component="fieldset">
              <label htmlFor={field_id} className="form-label valuation_label">
                {field_label} <span className="text-danger fw-500" style={{ fontSize: '1.3rem' }}>*</span>
              </label>
              <RadioGroup
                name={field_variable}
                onChange={(event) => handleChange(field_id, event, type)}
              >
                {field_options.length > 0 &&
                  field_options.map((option, i) => (
                    <FormControlLabel
                      value={option.field_value}
                      name={field_variable}
                      key={i}
                      checked={option.checked}
                      control={
                        <Radio
                          sx={{
                            '&, &.Mui-checked': {
                              color: '#123769',
                            },
                          }}
                          required={
                            i === 0 && field_mandatory === "yes"
                              ? "required"
                              : ""
                          }
                        />
                      }
                      label={option.option_label}
                      className="select_label"
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </div>
        </>
      )}
    </>
  );
};

Select.propTypes = {
  field_id: PropTypes.number.isRequired,
  field_label: PropTypes.string.isRequired,
  field_placeholder: PropTypes.string.isRequired,
  field_value: PropTypes.any.isRequired,
  field_type: PropTypes.string.isRequired,
  key: PropTypes.number.isRequired,
  field_options: PropTypes.any.isRequired,
  isDropDown: PropTypes.bool.isRequired,
};

export default Select;
