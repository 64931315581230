import React, { useState } from 'react'
import { Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from '@mui/material'
import { ExpandLess, ExpandMore, } from "@mui/icons-material"
import { useAuth } from '../../Utils/Auth'
import './userInfo.css';
import { useNavigate } from 'react-router-dom';
const UserInfo = ({ isDashboard }) => {
     const navigate = useNavigate()
     const [open, setOpen] = useState(false)
     const anchorRef = React.useRef(null);

     const handleToggle = () => {
          setOpen((prevOpen) => !prevOpen);
     };

     const handleClose = () => {
          setOpen(false);
     }

     function handleListKeyDown(event) {
          if (event.key === "Tab") {
               event.preventDefault();
               setOpen(false);
          }
     }

     const { currentUser, logout } = useAuth();
     const navigateToDashboard = () => {
          navigate('/dashboard')
     }
     return (
          <div>
               <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className='color_white'
               >
                    {
                         currentUser && <>
                              <div className={`${isDashboard ? 'font-inter' : 'color_dark font-inter nav_elements'}`}>{currentUser.firstName} {currentUser.lastName}</div>
                              {open ? <div className='indicator'>
                                   <ExpandLess style={{ color: "#fff" }} />
                              </div> : <div className='indicator'>
                                   <ExpandMore style={{ color: "#fff" }} /></div>}
                         </>
                    }

               </Button>

               <Popper
                    open={open}
                    style={{ zIndex: "200" }}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
               >
                    {({ TransitionProps, placement }) => (
                         <Grow
                              {...TransitionProps}
                              style={{
                                   transformOrigin:
                                        placement === "bottom" ? "center top" : "center bottom",
                              }}
                         >
                              <Paper style={{ zIndex: "200", backgroundColor: "#222", fontFamily: 'Inter' }} className='color_white'>
                                   <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                             autoFocusItem={open}
                                             id="menu-list-grow"
                                             onKeyDown={handleListKeyDown}
                                        >
                                             {
                                                  isDashboard ? <>
                                                       <MenuItem
                                                            onClick={() => {
                                                                 navigate('/dashboard/my-profile')
                                                                 setOpen(false);
                                                            }}
                                                       >
                                                            <p className='font-inter'>My Profile</p>

                                                       </MenuItem>
                                                       <hr style={{ border: "1px solid rgba(202, 202, 202, 0.3)", margin: '0' }} />
                                                       <MenuItem
                                                            onClick={() => {
                                                                 navigate('/dashboard/payment-history')
                                                                 setOpen(false);
                                                            }}
                                                       >
                                                            <p className='font-inter'>Payment History</p>

                                                       </MenuItem>
                                                       <hr style={{ border: "1px solid rgba(202, 202, 202, 0.3)", margin: '0' }} />
                                                       <MenuItem onClick={logout}>
                                                            <p className='font-inter'>Logout</p>

                                                       </MenuItem>
                                                  </> : <>
                                                       <MenuItem
                                                            onClick={() => {
                                                                 navigateToDashboard()
                                                                 setOpen(false);
                                                            }}
                                                       >
                                                            <p className='font-inter'>My Dashboard</p>

                                                       </MenuItem>
                                                       <hr style={{ border: "1px solid rgba(202, 202, 202, 0.3)", margin: '0' }} />
                                                       <MenuItem onClick={logout}>
                                                            <p className='font-inter'>Logout</p>
                                                       </MenuItem>


                                                  </>
                                             }

                                        </MenuList>
                                   </ClickAwayListener>
                              </Paper>
                         </Grow>
                    )}
               </Popper>
          </div>
     )
}

export default UserInfo