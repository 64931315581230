import React from "react";
import { Navbar } from "../../Components/Layouts";
import {
  Header,
  HowItWorks,
  Contact,
  Footer,
  Pricing,
  Insights,
  Benefits,
} from "../../Components/HomeComponents";
import "./home.css";
// import el1 from '../../Assets/Images/Ellipse 1.svg';
// import el2 from '../../Assets/Images/Ellipse 2 (1).svg';
const Home = () => {
  return (
    <div className="home">
      {/* <img src={el1} className='el1' />
          <img src={el2} className='el2' /> */}
      <div className="home_moving">
        <Navbar />
        <Header />
        {/* <hr className="section_break" /> */}
        <Benefits />
        <hr className="section_break" />
        <HowItWorks />
        <hr className="section_break" />
        <Insights />
        <hr className="section_break" />
        <Pricing />
        <hr className="section_break" />
        <Contact />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
