import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Label,
} from "recharts";

const COLORS = [
  "#A4A5A5",
  "#494C4C",
  "#222222",
  "#404654",
  "#123769",
  "#000000",
];
const DANGERCOLORS = [
  "#ff7a7a",
  "#e66e6e",
  "#cc6262",
  "#b35555",
  "#994949",
  "#803d3d",
];

const chart = ({ shareholders, exceedingLimit }) => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    let newarr = [];
    if (shareholders && shareholders.length) {
      for (let i = 0; i < shareholders.length; i++) {
        newarr.push({
          name: String(shareholders[i].shareholdername),
          percentage: Number(shareholders[i].shareholderpercentage),
        });
      }
    }
    setdata(newarr);
  }, [shareholders]);

  return (
    <div style={{ width: "100%", height: 200 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx={150}
            cy={100}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="percentage"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  exceedingLimit
                    ? DANGERCOLORS[index % DANGERCOLORS.length]
                    : COLORS[index % COLORS.length]
                }
              />
            ))}
            <Label value="Shareholders" position="center" />
          </Pie>

          <Tooltip
            formatter={(v) => <span style={{ color: "#000" }}>{`${v} %`}</span>}
          />

          <Legend
            wrapperStyle={{
              fontSize: "16px",
              fontWeight: 400,
              width: '100%',
              height: "auto",
              bottom: "-50px"
            }}
            layout="horizontal"
            align="center"
            formatter={(value) => (
              <span style={{ color: "#000" }}>{value}</span>
            )}
          />


        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default chart;
