import React from 'react';
import { BackIcon } from '../../Icons';
import { useNavigate } from 'react-router-dom';

const Back = ({ classes, type }) => {
     const navigate = useNavigate();
     const handleBack = () => {
          navigate(-1)
     }
     return <div className={` ${classes}`}>
          {
               type === 'onlyIcon' ? <BackIcon fn={handleBack} color="#000" /> : <div className='d-flex align-items-center cursor_p'>
                    <BackIcon fn={handleBack} />
                    <p className='fw-600 text_sl lh-22 color_primary mt-1 mx-2' onClick={handleBack}>Back</p>
               </div>
          }
     </div>
}

export default Back;