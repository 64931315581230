import React from "react";
import { ToastContainer } from "react-toastify";
import { Route, Routes, } from "react-router-dom";
import { Home, Login, Signup, VerifyEmail, Dashboard, ForgotPassword, ResetPassword, Payment } from "./Pages";
import { MyReports, Analytics, ValuationMain } from "./Components/Valuation";

import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import { ProvideAuth } from './Utils/Auth'
import ProtectedRoute from "./Utils/ProtectedRoute";
import CheckUser from './Utils/CheckUser'
import { PaymentHistory, MyProfile } from "./Components/DashBoardComponents";
// import { useNavigate } from "react-router-dom";
function App() {
  // const navigate = useNavigate();

  return (
    <div className="App">
      <ToastContainer hideProgressBar />
      <ProvideAuth>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<Signup />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/payment" element={<Payment />} />
          <Route path='/checkUser' element={<CheckUser />} />

          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }>

            <Route path='my-reports' element={
              <ProtectedRoute>
                <MyReports />
              </ProtectedRoute>

            } />

            <Route path='payment-history' element={
              <ProtectedRoute>
                <PaymentHistory />
              </ProtectedRoute>

            } />

            <Route path='my-profile' element={
              <ProtectedRoute>
                <MyProfile />
              </ProtectedRoute>

            } />

            <Route path='analytics' element={
              <ProtectedRoute>
                <Analytics />
              </ProtectedRoute>

            } />

            <Route path="generate-report/:valuation_id/valStep/:step" element={
              <ProtectedRoute>
                <ValuationMain />
              </ProtectedRoute>
            } />

          </Route>
        </Routes>
      </ProvideAuth>
    </div>
  );
}

export default App;
