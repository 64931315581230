const { countrywithcode } = require("./Lists/Countries");
const { getindustrywithcode } = require("./Lists/Industries");

module.exports.questions = (currency) => {
  return [
    {
      page_label: "Questionnaire",
      Team: [
        {
          field_id: "01",
          field_label: "How many founders does the company have?",
          field_mandatory: "yes",
          field_placeholder: "1",
          field_type: "number",
          field_value: "",
          field_variable: "foundersnumber",
          tips: true,
          h: "FOUNDERS",
          p: "The entrepreneurs who started the company",
        },
        {
          field_id: "02",
          field_label: `How much did the founders invest in the company in terms of capital collectively so far?(in ${currency})`,
          field_mandatory: "yes",
          field_placeholder: "1000",
          field_type: "number",
          field_value: "",
          field_variable: "capitalinvested",
        },
        {
          field_id: "03",
          field_label:
            "Is the majority of the founders involved in other companies or occupations ?",
          field_value: "",
          field_mandatory: "yes",
          field_variable: "founderscommitment",
          field_options: [
            {
              option_label:
                "Yes, we see the company as a side project to our current occupation",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Yes, but we are committed part time on a continuous basis",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Yes, but we are committed part time on a continuous basis and plan to commit full time once the organizational structure of the company is fully operational",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "No, we are committed full time",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "04",
          field_label: "What is the average age of the founders ?",
          field_value: "",
          field_mandatory: "yes",
          field_variable: "entrage",
          field_options: [
            {
              option_label: "less than  25",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "25-34",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "35-45",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "greater than 45",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "05",
          field_label:
            "Has any of the founders previous entrepreneurial experience ?",
          field_value: "",
          field_mandatory: "yes",
          field_variable: "previousstartup",
          field_options: [
            {
              option_label:
                "This is the first entrepreneurial experience for all of us",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Yes, at least one of us previously founded a company",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Yes, and at least one of us successfully sold a company",
              checked: false,
              field_value: "type3",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "06",
          field_label:
            "How many employees work for the company? (excluding founders, interns and freelancers)",
          field_mandatory: "yes",
          field_placeholder: "5",
          field_type: "number",
          field_value: "",
          field_variable: "employeesnumber",
        },
        {
          field_id: "07",
          field_label:
            "How long have the members of the core team worked (or studied) together?",
          field_value: "",
          field_mandatory: "yes",
          field_variable: "knowbefore",
          tips: true,
          h: "CORE TEAM",
          p: "Key and long-term committed people for the company",
          field_options: [
            {
              option_label: "Less than 1 year",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Between 1 and 2 years",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Between 3 and 5 years",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "More than 5 years",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "08",
          field_label:
            "How many years of relevant industry experience does the core team have collectively?",
          field_mandatory: "yes",
          field_placeholder: "20",
          field_type: "number",
          field_variable: "yearsexperience",
        },
        {
          field_id: "09",
          field_label:
            "Does any member of the  core team have business and managerial background?",
          field_variable: "businessex",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "No",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "At least one of us has accomplished business-related studies",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "At least one of us has work experience",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "At least one of us has been employed as mid-level manager (e.g. Product manager, Sales manager)",
              checked: false,
              field_value: "type4",
            },
            {
              option_label:
                "At least one of us has been employed as a top-tier manager (VP and above positions) or has held a similar position in his or her own company which had more than 50 employees",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "10",
          field_label:
            "Does the core team have all the main technical skills? (e.g. web development for online services)",
          field_variable: "techskills",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "No",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "No, but we outsource all of them",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "We have some of the technical capabilities and outsource the rest",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "We have the majority of the technical capabilities and outsource the rest",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Yes",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
      ],
      BusinessModel: [
        {
          field_id: "11",
          field_label: "Is the business scalable?",
          field_variable: "scalability",
          field_mandatory: "yes",
          tips: true,
          h: "SCALABLE",
          p: "Can you significantly increase revenues without increasing costs?",
          field_options: [
            {
              option_label: "Yes",
              checked: false,
              field_value: "1",
            },
            {
              option_label: "No",
              checked: false,
              field_value: "0",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "12",
          field_label: "The company's main business model is:",
          field_variable: "sellingapproach",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "B2C (Business to Customers)",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "B2B (Business to Business)",
              checked: false,
              field_value: "type2",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "13",
          field_label: "Which stage of development are you facing now?",
          field_value: "",
          field_variable: "devstage",
          field_mandatory: "yes",
          field_options: [
            {
              option_label:
                "Idea stage: working on business plan and testing the initial problem-solution assumptions ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Development stage: working on the product and setting up operations",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Startup stage: already active with revenues up to € 200,000",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "Expansion stage: already active with revenues above € 200,000 and looking to scale up the operations",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "14",
          field_label: "Is the company sustainably breakeven?",
          field_value: "",
          field_variable: "profitability",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Yes",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "No",
              checked: false,
              field_value: "type2",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "15",
          field_label: "Does the company have a board of advisors?",
          field_variable: "boardpresence",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Yes",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "We can count on external advisors but they are not organized in a board yet",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "No",
              checked: false,
              field_value: "type3",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "16",
          field_label: "How many are the advisors?",
          field_mandatory: "yes",
          field_placeholder: "1",
          field_type: "number",
          field_variable: "external_advisors",
        },
        {
          field_id: "17",
          field_label:
            "Indicate which, if any, of the following entities are a shareholder of the company",
          field_variable: "investors",
          field_mandatory: "no",
          field_options: [
            {
              option_label: "Friends and Family",
              checked: false,
              field_value: "investors_type1",
            },
            {
              option_label: "Crowdfunding",
              checked: false,
              field_value: "investors_type2",
            },
            {
              option_label: "Incubator / accelerator",
              checked: false,
              field_value: "investors_type3",
            },
            {
              option_label: "Business angel",
              checked: false,
              field_value: "investors_type4",
            },
            {
              option_label: "Venture Capitalist",
              checked: false,
              field_value: "investors_type5",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "18",
          field_label:
            "Does the company offer valuable exit strategies to investors?",
          field_variable: "companyexit",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "We did not think about exit strategies so far",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "We considered some exit opportunities",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "We have multiple exit opportunities",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "Companies active in the market have already demonstrated interest in buying us",
              checked: false,
              field_value: "type4",
            },
            {
              option_label:
                "Big companies demonstrated strong interest and willingness to commit to the acquisition",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
      ],
      ProductMarket: [
        {
          field_id: "19",
          field_label: "In which country is the company based?",
          field_variable: "country",
          field_mandatory: "yes",
          field_options: countrywithcode(),
          field_type: "select",
          isDropDown: true,
        },
        {
          field_id: "20",
          field_label: "Industry",
          field_variable: "industry",
          field_mandatory: "yes",
          field_options: getindustrywithcode(),
          field_type: "select",
          isDropDown: true,
        },
        {
          field_id: "21",
          field_label:
            "How would you define the stage of the product/service roll-out ?",
          field_variable: "rollout",
          field_mandatory: "yes",
          field_options: [
            {
              option_label:
                "Planning:  the roll-out process has still to begin",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                'Prototype: we have the first version of the product (alpha or beta) for testing/display purposes, but it"s not ready for the market',
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "MVP: We have a minimum viable product (with just enough features to satisfy early customers) released on the market ",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "Market: the complete version of the product is offered to the market. ",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "22",
          field_label:
            "Has the demand for the proposed product/service been tested?",
          field_variable: "demandvalidation",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "No, we are still under testing stage",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Yes, the demand has been validated by our main competiton",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Yes, the demand has been extensively tested and validated ",
              checked: false,
              field_value: "type3",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "23",
          field_label:
            "What is the current size of your Total Addressable Market (TAM), in monetary terms?                ",
          field_mandatory: "yes",
          field_placeholder: "10000000",
          field_type: "number",
          field_variable: "tam",
          tips: true,
          h: "TAM",
          p: "Value of the potential customers all over the world with the need the company is addressing",
        },
        {
          field_id: "24",
          field_label: "What is the annual growth rate of this market?(in %)",
          field_mandatory: "yes",
          field_placeholder: "1",
          field_type: "number",
          field_variable: "tamgrowth",
        },
        {
          field_id: "25",
          field_label:
            "How high are the barriers to entry of the market the company is targeting for potential competitors?                ",
          field_variable: "entrybarriers",
          field_mandatory: "yes",
          tips: true,
          h: "BARRIERS TO ENTRY",
          p: "The effort/cost that must be sustained by a (potential) competitor to enter the market",
          field_options: [
            {
              option_label: "Very Low",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Low",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Modest",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "High",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Very High",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "26",
          field_label:
            "How would you define the level of competition in your targeted market?                ",
          field_variable: "industrycompetition",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Dominated by single player ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Dominated by several players",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Many small players",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "Some small players ",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Blue ocean (negligible competition)",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "27",
          field_label: " Existing competitive products/services are : ",
          field_variable: "competitiveproducts1",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Excellent",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Good",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Weak",
              checked: false,
              field_value: "type3",
            },
          ],
          field_type: "select",
        },

        {
          field_id: "28",
          field_label:
            "How would you define your product compared to the ones of existing competitors?                ",
          field_variable: "competitiveproducts2",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Comparable",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Comparable, but we innovate in terms of marketing proposition/USP ",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Comparable, but radically innovate in terms of execution (e.g. costs, manufacturing, etc.)  ",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "Current products solve the same needs with a non comparable solution  ",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "29",
          field_label: "How do you relate to international competition ?",
          field_variable: "relateintcomp",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Competition abroad is already established  ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Competition abroad is growing but foreign markets are still smaller than the one in our country  ",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Competition abroad is not yet developed  ",
              checked: false,
              field_value: "type3",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "30",
          field_label: "Do you plan international expansion ?",
          field_variable: "internationalex",
          field_mandatory: "yes",
          field_options: [
            {
              option_label:
                "We are a locally based business: international expansion is not an option for us",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "International expansion entails big challenges for us  ",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "We are considering internationalization but our focus now is on the local market  ",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "We already have a plan for international expansion  ",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Our main strategic focus is already global  ",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "31",
          field_label:
            "How is the average trend of feedback received so far from early adopters/industry experts?                ",
          field_variable: "feedbacktrend",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Mainly negative",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Fairly negative",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Fairly positive",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "Mainly positive",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "All positive",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },

        {
          field_id: "32",
          field_label:
            "How would you define the degree of loyalty of your customers?",
          field_variable: "loyaltydegree",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Still to be tested or under testing",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Low retention: the business model requires time to develop higher retention",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Average retention: already at good level and can be further improved",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "High retention: high level of loyalty already developed with customers",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "33",
          field_label:
            "How strong are the relationships with key strategic partners (including distributors, suppliers)?",
          field_variable: "partners",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "No partners contacted so far ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "We identified and contacted them",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Informal agreements already in place",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "Contracts signed",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Contracts signed and serving high volumes",
              checked: false,
              field_value: "type5",
            },
          ],
          field_type: "select",
        },
      ],
      IPLegal: [
        {
          field_id: "34",
          field_label: "When was the company started?",
          field_mandatory: "yes",
          field_placeholder: "2018",
          field_type: "number",
          field_variable: "startedyear",
        },
        {
          field_id: "35",
          field_label: " Has a legal entity been incorporated?",
          field_variable: "legalcomp",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Yes",
              checked: false,
              field_value: "1",
            },
            {
              option_label: "No",
              checked: false,
              field_value: "0",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "36",
          field_label: "Year of incorporation",
          field_mandatory: "yes",
          field_placeholder: "2015",
          field_type: "number",
          field_variable: "constyear",
        },
        {
          field_id: "37",
          field_label:
            "Can the company and its founders already count on the support of external legal consultants?",
          field_variable: "legalconsultants",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Yes",
              checked: false,
              field_value: "1",
            },
            {
              option_label: "No",
              checked: false,
              field_value: "0",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "38",
          field_label:
            "Which kind of Intellectual Property (IP) applies to the product/service?",
          field_variable: "iptype",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Trademark and/or domain names   ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Copyright ",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Patent (excluding US provisional patent)",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "Other",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "39",
          field_label:
            " How would you define the current stage of development of your IP?",
          field_variable: "ippatent",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Not applicable",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "IP pending for approval",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "IP protection secured at regional level",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "IP protection secured at global level ",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "40",
          field_label:
            " What is your relationship with the company being valued?        ",
          field_variable: "valuationparty",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Founder",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Employee from the finance/administration department",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Employee from another department",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "External consultant and/or advisor",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Current shareholder with no operating role",
              checked: false,
              field_value: "type5",
            },
            {
              option_label: "Interested investor/buyer",
              checked: false,
              field_value: "type6",
            },
            {
              option_label: "Other",
              checked: false,
              field_value: "type7",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "41",
          field_label: "The purpose of my valuation is:",
          field_variable: "reasonforvalonboarding",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Fundraising",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Selling the company",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Selling part of the shares",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "Planning and strategy",
              checked: false,
              field_value: "type4",
            },
            {
              option_label: "Shareholders reporting",
              checked: false,
              field_value: "type5",
            },
            {
              option_label: "Tax reporting and compliance",
              checked: false,
              field_value: "type6",
            },
            {
              option_label: "Other",
              checked: false,
              field_value: "type7",
            },
          ],
          field_type: "select",
        },
      ],
      quesFilled: false,
    },

    {
      page_head: "Financials",
      fields: [
        {
          row_label: "Revenues",
          field_variable: "estimatedrevenues",
          past_year: {
            id: "f1",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f2",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f3",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f4",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "Cost of goods sold",
          field_variable: "costgoods",
          past_year: {
            id: "f5",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f6",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f7",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f8",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "Salaries",
          field_variable: "salaries",
          past_year: {
            id: "f9",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f10",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f11",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f12",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "Operating Expenses",
          field_variable: "operatingexpenses",
          past_year: {
            id: "f13",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f14",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f15",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f16",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "EBITDA",
          field_variable: "ebitda",
          past_year: {
            id: "f81",
            value: 0,
            type: "input",
            static: true,
          },
          forcast: {
            id: "f82",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f83",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f84",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "D&A",
          field_variable: "depreciations",
          past_year: {
            id: "f21",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f22",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f23",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f24",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "EBIT",
          field_variable: "ebit",
          past_year: {
            id: "f85",
            value: 0,
            type: "input",
            static: true,
          },
          forcast: {
            id: "f86",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f87",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f88",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Interest",
          field_variable: "fingainloss",
          past_year: {
            id: "f29",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f30",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f31",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f32",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Taxes",
          field_variable: "taxliabilities",
          past_year: {
            id: "f33",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f34",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f35",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f36",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Net Profit",
          field_variable: "netprofit",
          past_year: {
            id: "f89",
            value: 0,
            type: "input",
            static: true,
          },
          forcast: {
            id: "f90",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f91",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f92",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Receivables",
          field_variable: "receivables",
          past_year: {
            id: "f41",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f42",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f43",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f44",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Inventories",
          field_variable: "inventory",
          past_year: {
            id: "f45",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f46",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f47",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f48",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Paybles",
          field_variable: "payables",
          past_year: {
            id: "f49",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f50",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f51",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f52",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Working Capital",
          field_variable: "workingcapital",
          past_year: {
            id: "f93",
            value: 0,
            type: "input",
            static: true,
          },
          forcast: {
            id: "f94",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f95",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f96",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Change in Working capital",
          field_variable: "workingcapitalchange",
          past_year: {
            id: "f97",
            value: 0,
            type: "input",
            disable: true,
          },
          forcast: {
            id: "f98",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f99",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f100",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },

        {
          row_label: "Capital Expenditures",
          field_variable: "longterminvestments",
          past_year: {
            id: "f61",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f62",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f63",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f64",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "Debt at the End of the Year",
          field_variable: "endingyeardebt",
          past_year: {
            id: "f65",
            value: 0,
            type: "input",
          },
          forcast: {
            id: "f66",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f67",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f68",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "Change in Outstanding Debt",
          field_variable: "changeindebt_default",
          past_year: {
            id: "f101",
            value: 0,
            type: "input",
            disable: true,
          },
          forcast: {
            id: "f102",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f103",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f104",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Free Cash flow to Equity",
          field_variable: "cashtoequity_default",
          past_year: {
            id: "f105",
            value: 0,
            type: "input",
            disable: true,
          },
          forcast: {
            id: "f106",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f107",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f108",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
        {
          row_label: "Fundraising Plan",
          field_variable: "fundraisingplan",
          past_year: {
            id: "f77",
            value: 0,
            type: "input",
            disable: true,
          },
          forcast: {
            id: "f78",
            value: 0,
            type: "input",
          },
          forcast1: {
            id: "f79",
            value: 0,
            type: "input",
          },
          forcast2: {
            id: "f80",
            value: 0,
            type: "input",
          },
          row_info: "",
        },
        {
          row_label: "Free Cash flow",
          field_variable: "changeincash_default",
          past_year: {
            id: "f109",
            value: 0,
            type: "input",
            disable: true,
          },
          forcast: {
            id: "f110",
            value: 0,
            type: "input",
            static: true,
          },
          forcast1: {
            id: "f111",
            value: 0,
            type: "input",
            static: true,
          },
          forcast2: {
            id: "f112",
            value: 0,
            type: "input",
            static: true,
          },
          row_info: "",
        },
      ],
      finFilled: false,
    },

    {
      page_title: "Transactions",
      contents: [
        {
          h1: "Capital Needed",
          p: "",
          fields: [
            {
              field_id: "39",
              field_variable: "capitalneeded",
              field_title: "",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "10000000",
              meta: "As a rule of thumb, you should raise enough capital to sustain operations for the next 18 months",
            },
          ],
        },
        {
          h1: "Use of Funds",
          p: "Describe here how the funds will be used. Salaries should be allocated based on the category they belong to.",
          fields: [
            {
              field_id: "40",
              field_variable: "uofproduct_dev",
              field_title: "Product and R & D *",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "",
              meta: "Building innovation and improvement of products or processes",
            },
            {
              field_id: "41",
              field_variable: "uofmarketing",
              field_title: "Sales and marketing *",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "",
              meta: "Creation and communication of the product value for customers, partners, and society at large",
            },
            {
              field_id: "42",
              field_variable: "uofinventory",
              field_title: "Inventory *",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "",
              meta: "Cumulated raw material for production and finished goods before sales",
            },
            {
              field_id: "43",
              field_variable: "uofoperating",
              field_title: "Operations *",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "",
              meta: "Day-to-day activities to run an ongoing company (e.g.: location, procurement, processes)",
            },
            {
              field_id: "44",
              field_variable: "uofcapex",
              field_title: "Capital Expenditures *",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "",
              meta: "Tangible and intangible asset acquisition (purchase of equipments, real estate, brands, patents)",
            },
            {
              field_id: "45",
              field_variable: "uofothers",
              field_title: "Others *",
              fieldvalue: "",
              fieldpercentage: 0,
              field_type: "number",
              field_required: "yes",
              field_placeholder: "",
              meta: "Any other activity not included in the ones described above",
            },
          ],
        },
      ],
      transFilled: false,
    },

    {
      page_head: "Last Available Balance Sheet",
      Assets: [
        {
          field_id: "asset1",
          field_label: "Cash and equivalents",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0cash",
        },
        {
          field_id: "asset2",
          field_label: "Of Which: Non Operating Cash",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0nonoperatingcash",
        },
        {
          field_id: "asset3",
          field_label: "Tangible assets",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0tangassets",
        },
        {
          field_id: "asset4",
          field_label: "Intangible assets",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0intangassets",
        },
        {
          field_id: "asset5",
          field_label: "Financial assets",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0finassets",
        },
        {
          field_id: "asset6",
          field_label: "Deferred tax assets",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0deftaxass",
        },
      ],
      Liabilities: [
        {
          field_id: "Liabilitie1",
          field_label: "Debts due within one year time",
          info: "(e.g. short-term bank loans)",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0currliab",
        },
        {
          field_id: "Liabilitie2",
          field_label: "Debt due beyond one year time",
          info: "(e.g. bond issues, long-term leases)",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "endingyeardebt_y1",
        },
        {
          field_id: "Liabilitie3",
          field_label: "Equity",
          info: "",
          field_mandatory: "yes",
          field_placeholder: "0",
          field_type: "number",
          field_variable: "y0equity",
        },
      ],
      labFilled: false,
    },
    {
      tenquestions: [
        {
          field_id: "01",
          field_label: "How many founders does the company have?",
          field_mandatory: "yes",
          field_placeholder: "eg. 1",
          field_type: "number",
          field_value: "",
          field_variable: "foundersnumber",
        },
        {
          field_id: "06",
          field_label:
            "How many employees work for the company? (excluding founders, interns and freelancers)",
          field_mandatory: "yes",
          field_placeholder: "eg. 20",
          field_type: "number",
          field_value: "",
          field_variable: "employeesnumber",
        },
        {
          field_id: "19",
          field_label: "In which country is the company based?",
          field_value: "",
          field_variable: "country",
          field_mandatory: "yes",
          field_options: countrywithcode(),
          field_type: "select",
          isDropDown: true,
        },

        {
          field_id: "20",
          field_label: "Industry",
          field_value: "",
          field_variable: "industry",
          field_mandatory: "yes",
          field_options: getindustrywithcode(),
          field_type: "select",
          isDropDown: true,
        },
        {
          field_id: "34",
          field_label: "When was the company started?",
          field_mandatory: "yes",
          field_placeholder: "eg. 2018",
          field_type: "number",
          field_value: "",
          field_variable: "startedyear",
        },
        {
          field_id: "13",
          field_label: "Which stage of development are you facing now?",
          field_value: "",
          field_variable: "devstage",
          field_mandatory: "yes",
          field_options: [
            {
              option_label:
                "Idea stage: working on business plan and testing the initial problem-solution assumptions ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label:
                "Development stage: working on the product and setting up operations",
              checked: false,
              field_value: "type2",
            },
            {
              option_label:
                "Startup stage: already active with revenues up to € 200,000",
              checked: false,
              field_value: "type3",
            },
            {
              option_label:
                "Expansion stage: already active with revenues above € 200,000 and looking to scale up the operations",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "38",
          field_label:
            "Which kind of Intellectual Property (IP) applies to the product/service?",
          field_value: "",
          field_variable: "iptype",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Trademark and/or domain names   ",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "Copyright ",
              checked: false,
              field_value: "type2",
            },
            {
              option_label: "Patent (excluding US provisional patent)",
              checked: false,
              field_value: "type3",
            },
            {
              option_label: "Other",
              checked: false,
              field_value: "type4",
            },
          ],
          field_type: "select",
        },
        {
          field_id: "14",
          field_label: "Is the company sustainably breakeven?",
          field_value: "",
          field_variable: "profitability",
          field_mandatory: "yes",
          field_options: [
            {
              option_label: "Yes",
              checked: false,
              field_value: "type1",
            },
            {
              option_label: "No",
              checked: false,
              field_value: "type2",
            },
          ],
          field_type: "select",
        },
        {
          row_label: "Revenues",
          field_variable: "inventory",
          past_year: {
            id: "f1",
            value: "",
            field_value: "",
            field_variable: "estimatedrevenues_y1",
            type: "input",
          },
          forcast: {
            id: "f2",
            value: "",
            type: "input",
            field_value: "",
            field_variable: "estimatedrevenues_y4",
          },

          row_info: "",
        },
      ],
    },
  ];
};
