import React, { useState } from 'react';
import './auth.css';
import AuthLayout from '../../Components/Layouts/Auth';
import { ForwardIcon } from '../../Components/Icons';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CryptoJS from "crypto-js";
import api from '../../axiosAPI';
import { toast } from 'react-toastify';
import { useNavigate, Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useAuth } from '../../Utils/Auth';
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
     const { loadCurrent, setLoadCurrent } = useAuth()
     const [showPassword, setShowPassword] = useState(false);

     const navigate = useNavigate()
     const [isLoading, setIsLoading] = useState(false);
     const validationSchema = Yup.object().shape({
          email: Yup.string()
               .email("Please enter correct email")
               .required("Email name is required"),
          password: Yup.string()
               .required("Passwort is required")
               .min(6, "Password too short! Must be at least 6 characters."),
     });

     const onSubmit = (values, actions) => {
          setIsLoading(true)
          const password = CryptoJS.AES.encrypt(values.password, "H~jP,/%6R*'Tc|xg").toString();
          const postData = { ...values, password }

          api.post('/user/login', postData, {
               withCredentials: true
          }).then(res => {
               if (res.data.success) {
                    setIsLoading(false)
                    setLoadCurrent(!loadCurrent);
                    toast.success(`You're successfully logged in!`);
                    actions.resetForm();
                    navigate(`/dashboard`);
               }
          }).catch(error => {
               toast.error(error.response.data.message, { position: "bottom-right" });
               setIsLoading(false)
               if (error.response.status === 400 && !error.response.data.user.isEmailVerified) {
                    navigate(`/verify-email?email=${error.response.data.user.email}`)
               }
          })

     }

     const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({ //isSubmitting
          initialValues: {
               email: "",
               password: "",
          },
          onSubmit,
          validationSchema: validationSchema
     })

     return <div>
          <AuthLayout authType="login">

               <form className='mt-40' autoComplete="off" onSubmit={handleSubmit}>
                    <div>
                         <label className='auth_label' htmlFor="email">Email</label>
                         <input
                              type="email"
                              id="email"
                              className={`auth_input mt-1 ${(touched.email && errors.email) && 'border-danger'}`}
                              placeholder='Enter your email here'
                              value={values.email}
                              onChange={handleChange('email')}
                              onBlur={handleBlur}
                         />
                         {
                              (touched.email && errors.email) && <div className='text-danger mt-1'>{errors.email}</div>
                         }

                    </div>

                    <div className='mt-4'>
                         <label className='auth_label' htmlFor="password">Password</label>
                         <div className='position-relative'>
                              <input type={showPassword ? 'text' : "password"}
                                   id="password"
                                   className={`auth_input mt-1 ${(touched.password && errors.password) && 'border-danger'}`}
                                   placeholder="Enter your password here"
                                   value={values.password}
                                   onChange={handleChange('password')}
                                   onBlur={handleBlur}
                              />
                              {
                                   (touched.password && errors.password) && <div className='text-danger mt-1'>{errors.password}</div>
                              }
                              <div
                                   className='position-absolute cursor_p'
                                   style={{ top: '30%', right: '5%' }}
                                   onClick={() => setShowPassword(!showPassword)}
                              >
                                   {showPassword ? (
                                        <VisibilityOff style={{ color: "#123769" }} />
                                   ) : (
                                        <Visibility style={{ color: "#123769" }} />
                                   )}
                              </div>
                         </div>


                    </div>

                    <div className='d-flex align-items-center justify-content-between mt-3'>
                         <label className="checkbox-container">
                              <input name="subscribe" id="subscribe" type="checkbox" checked />
                              <label className="mx-4 heading_xs font-inter cursor_p fw-400" htmlFor="subscribe" style={{ marginTop: '.1rem' }}>
                                   Remember Me
                              </label>
                              <span className="checkbox-checkmark"></span>
                         </label>
                         <p className='font-inter heading_xs color_secondary cursor_p fw-400'>
                              <Link to="/forgot-password">Forgot Password?</Link>
                         </p>
                    </div>
                    <button className='auth_btn mt-5' type='submit'>
                         {
                              isLoading ? <div className='d-flex justify-content-center w-100'>
                                   <CircularProgress style={{ color: "#fff" }} />
                              </div> : <>Login <ForwardIcon /></>
                         }

                    </button>
                    <div>
                         <p className='color_light_v2 text_xs mt-2 font-inter fw-400'>Don’t have an account? <Link to="/signup"><span className='color_secondary cursor_p'>Create Account</span></Link></p>
                    </div>
               </form>
          </AuthLayout>
     </div>
}

export default Login;