import React from "react";
import "./footer.css";
// import { ReactComponent as Logo } from '../../../Assets/Logos/vcbay_with_name_small.svg';
const Footer = () => {
  return (
    <div className="footer d-flex align-items-center">
      {/* <div className='flex-responsive align-items-center justify-content-between w-100 px-80'>
               <div >
                    <Logo />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. A faucibus non massa erat ac, ullamcorper ultrices nullam. Vel vestibulum in.</p>
                    <p>company mail adress</p>
               </div>
               <div>
                    <div className='row align-items-center'>
                         <div className='col-3'>
                              <p>Newsletter</p>
                         </div>
                         <div className='col-9'>
                              <div className='d-flex'>
                                   <input className='input_newsletter' />
                                   <button className='newsletter_btn'>Subscribe</button>
                              </div>

                         </div>
                    </div>
                    <hr style={{ background: '#D9D9D9', margin: '32px 0' }} />
                    <div className='row align-items-center'>
                         <div className='col-3'>
                              <p>Legal</p>
                         </div>
                         <div className='col-9'>
                              <div className='d-flex'>
                                   <p className='footer_item'>Privacy and Policy</p>
                                   <p className='footer_item'>Imprints</p>
                                   <p className='footer_item'>Teams and Conditions</p>
                              </div>

                         </div>
                    </div>
                    <div className='row align-items-center mt-3'>
                         <div className='col-3'>
                              <p>Connect</p>
                         </div>
                         <div className='col-9'>
                              <div className='d-flex'>
                                   <p className='footer_item'>LinkedIn</p>
                                   <p className='footer_item'>Twitter</p>
                                   <p className='footer_item'>Youtube</p>
                              </div>

                         </div>
                    </div>
               </div>
          </div> */}
      <div className="company-rights">
        <p>© VCBay. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
