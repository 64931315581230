import React, { useEffect, useState } from 'react'
// import DashNav from '../DashboardNav/DashNav'
import api from '../../../axiosAPI'
import './payment.css'
import { DataGrid } from '@mui/x-data-grid';
import { format } from 'date-fns'
const PaymentHistory = () => {
     const [paymentHistory, setPaymentHistory] = useState([]) //paymentHistory

     const pricing = new Intl.NumberFormat('en-us', {
          currency: 'INR',
          style: 'currency',
          minimumFractionDigits: 0,
     })
     const fetchPayments = async () => {
          const payments = await api.get('/payment/fetchPayments');
          const rows = payments.data.paymentHistory.map(payment => {
               return { "id": payment.payment_id, "billingDate": format(new Date(payment.createdAt), 'MMM dd yy'), "amount": pricing.format(payment.amount) }
          })
          setPaymentHistory(rows)
     }
     const columns = [
          { field: 'id', headerName: 'Payment Id', width: 230 },
          { field: 'billingDate', headerName: 'Billing Date', width: 230 },
          { field: 'amount', headerName: 'Amount', width: 230 },
     ];
     // const rows = [
     //      { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
     //      { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
     //      { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
     //      { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
     //      { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
     //      { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
     //      { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
     //      { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
     //      { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
     // ];
     useEffect(() => {
          fetchPayments()
     }, [])
     return (
          <>
               <div className='dasboard_payment_history_root'>
                    <div className='dasboard_payment_history_max white-background p-32'>
                         <p className='fw-500 heading_s'>Billing History</p>
                         <div style={{ height: 400, width: '100%' }}>
                              <DataGrid
                                   rows={paymentHistory}
                                   columns={columns}
                                   hideFooter
                              />
                         </div>
                    </div>
               </div>

          </>
     )
}

export default PaymentHistory