import React, { useState } from 'react';
import AuthLayout from '../../Components/Layouts/Auth';
import api from '../../axiosAPI';
import { ForwardIcon } from '../../Components/Icons';
import { useFormik } from "formik";
import * as Yup from 'yup';
import useQuery from '../../Utils/queryParamsExtract';
import { toast } from 'react-toastify';
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
     const navigate = useNavigate()
     const query = useQuery();
     const email = query.get('email');

     const [isLoading, setIsLoading] = useState(false);
     const validationSchema = Yup.object().shape({
          email: Yup.string()
               .email("Please enter your email")
               .required("Email name is required"),

     });

     const onSubmit = (values, actions) => {
          setIsLoading(true)

          api.post('/user/forgot-password', values).then(res => {
               if (res.data.success) {
                    setIsLoading(false)
                    toast.success(`Reset link sent!`);
                    actions.resetForm();
                    navigate(`/forgot-password?email=${values.email}&sent=true`)
               }
          }).catch(error => {
               setIsLoading(false)
               toast.error(error.response.data.message, { position: "bottom-right" });
          })

     }
     const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({ //isSubmitting
          initialValues: {
               email: "",
          },
          onSubmit,
          validationSchema: validationSchema
     })
     return <div>
          <AuthLayout authType={email ? "checkMail" : "forgotPass"}>
               {
                    email ? <div style={{ maxWidth: '457px' }} className='my-160'>
                         <p className='font-inter fw-500 heading_s'>We have sent a password reset link to <span className='color_secondary'>{email}</span></p>
                    </div> : <>
                         <div style={{ maxWidth: '457px' }} className='mt-5'>
                              <p className='fw-500 text_s lh-36'>Enter the email associated with your account and we’ll send a mail with link to reset your password</p>
                         </div>
                         <form className='mt-40' autoComplete="off" onSubmit={handleSubmit}>

                              <div>
                                   <label className='auth_label' htmlFor="email">Email</label>
                                   <input
                                        type="email"
                                        id="email"
                                        className={`auth_input mt-1 ${(touched.email && errors.email) && 'border-danger'}`}
                                        placeholder='Enter your Email here'
                                        value={values.email}
                                        onChange={handleChange('email')}
                                        onBlur={handleBlur}
                                   />
                                   <div className='text-danger mt-1'>{errors.email}</div>
                              </div>

                              <button className='auth_btn mt-5' type='submit'>
                                   {
                                        isLoading ? <div className='d-flex justify-content-center w-100'>
                                             <CircularProgress style={{ color: "#fff" }} />
                                        </div> : <>Send <ForwardIcon /></>
                                   }

                              </button>
                         </form>
                    </>
               }


          </AuthLayout>
     </div>
}

export default ForgotPassword;