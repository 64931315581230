import React, { useEffect, useState, useRef } from 'react';
import './valuation.css'
import { useParams, useNavigate } from 'react-router-dom';
// import useQuery from '../../../Utils/queryParamsExtract';
import Tenques from './Tenques/Tenques';
import api from '../../../axiosAPI';
import { toast } from 'react-toastify'
import { useAuth } from '../../../Utils/Auth';
import { FormContext } from "./Context/FormContext";
import { getequidamvariables } from "./Lists/Variables";
import { getcurrencylist } from "./Lists/Currency";
import { countrywithcode } from "./Lists/Countries";
import { questions } from './Ques';
import { FinanceIcon, CircleIcon, FundingIcon, SavedValuation, Step2Icon, Step1Icon } from '../../Icons/index'
import InitiateCircle from "../../../Assets/initite_circle.svg";
import redCircle from "../../../Assets/redCircle.svg"
import ValuationCurrencyFormat from "./Lists/ValuationCurrencyFormat";
import { CustomModal } from '../../Layouts';
import CompanyInfo from './CompanyInfo'
import FundingRounds from './FundingRounds/FundingRound'
import Financials from './Financials/Financial'
import Questionnare from './Questionare/Questionare'
import { CircularProgress } from '@mui/material';

const ValuationMain = () => {
     const navigate = useNavigate()
     // let query = useQuery()

     const companyInfoRef = useRef();
     const quesRef = useRef();
     const finRef = useRef();
     const transRef = useRef();

     const teamRef = useRef();
     const busiRef = useRef();
     const prodRef = useRef();
     const iPRef = useRef();
     let { valuation_id, step } = useParams();
     step = Number(step)
     const { setSessionExpire, loadCurrent, setLoadCurrent } = useAuth();
     const [
          companyInfo,
          //  setcompanyInfo
     ] = useState(null);
     const [Team, setteamQues] = useState(null);
     const [BusinessModel, setbusiQues] = useState(null);
     const [ProductMarket, setprodQues] = useState(null);

     const [IPLegal, setipQues] = useState(null);
     const [Finantial, setFinantial] = useState(null); //Finantial
     const [balanceSheet, setBalanceSheet] = useState(null);
     const [transactions, setTransactions] = useState(null); //transactions
     const [tenquestions, settenquestions] = useState([]);
     const [tenquestionsFilled, settenquestionsFilled] = useState();
     const [currency, setcurrency] = useState();
     const [quesstionnaireComplete, setquesstionnaireComplete] = useState(false); //
     const [financialsFilled, setfinancialsFilled] = useState(false); //
     const [, settransactionsFilled] = useState(false);

     const [reportTitle, setreportTitle] = useState("");
     const [finDate, setfinDate] = useState(new Date());
     const [, setbringnewdata] = useState(false); //bringnewdata
     // //
     const [companymongoDocument, setcompanymongoDocument] = useState();
     const [companyname, setcompanyname] = useState("");
     const [description, setdescription] = useState("");

     const [website, setwebsite] = useState("");
     const [linkedin, setlinkedin] = useState("");
     const [twitter, settwitter] = useState("");
     const [facebook, setfacebook] = useState("");
     const [address, setaddress] = useState("");
     const [latestvaluation, setlatestvaluation] = useState();
     const [valuationstatus, setvaluationstatus] = useState();
     const [competitors, setcompetitors] = useState([
          { competitorname: "", competitorurl: "" },
     ]);
     const [shareholders, setshareholders] = useState([
          { shareholdername: "", shareholderpercentage: "" },
     ]);
     const [oldcompetitors, setoldcompetitors] = useState([
          { competitorname: "", competitorurl: "" },
     ]);
     const [oldshareholders, setoldshareholders] = useState([
          { shareholdername: "", shareholderpercentage: "" },
     ]);
     const [oldlogo, setoldlogo] = useState(null);
     const [logo, setlogo] = useState(null);

     const [tenquestionsEquidam, settenquestionsEquidam] = useState(
          getequidamvariables("tenques")
     );
     const [questtionnaireEquidam, setquesttionnaireEquidam] = useState(
          getequidamvariables("ques")
     );
     const [financialsEquidam, setfinancialsEquidam] = useState(
          getequidamvariables("financials")
     );
     const [transactionsEquidam, settransactionsEquidam] = useState(
          getequidamvariables("transactions")
     );
     const [tenquesLoader, settenquesLoader] = useState(false);
     const [opentensubmit, setopentensubmit] = useState(false);
     const [, setOpen] = React.useState(false); //open
     // const [selectedref, setselectedref] = React.useState();
     const [oldquesttionnaireEquidam, setoldquesttionnaireEquidam] = useState();
     const [oldfinancialsEquidam, setoldfinancialsEquidam] = useState();
     const [oldtransactionsEquidam, setoldtransactionsEquidam] = useState();
     const [companyInfofilled, setcompanyInfofilled] = useState(false); //
     const [oldcompanyInfoEquidam, setoldcompanyInfoEquidam] = useState();
     const [unsavedchanges, setunsavedchanges] = useState(false); //unsavedchanges
     const [fundingRow, setFundingRows] = useState([]);
     const [isSaving, setisSaving] = useState(false); //
     const [value, setValue] = useState(0);
     const [valuet, setValuet] = useState(0);
     const [negativeModal, setNegativeModal] = useState(false);
     const [opendownload, setOpendownload] = useState(false);
     const [reportdownloadtitle, setreportdownloadtitle] = useState("");
     const [calltimer, setcalltimer] = useState(false);
     const [reportready, setreportready] = useState(false);
     const [reportid, setreportid] = useState();

     const [isdownloading, setisdownloading] = useState(false);
     const handleopentensubmit = () => {
          setopentensubmit(true);
     };

     const handleChangeStep = (step) => {
          navigate(`/dashboard/generate-report/${valuation_id}/valStep/${step}`)
     }
     const handleopenNegative = () => {
          setNegativeModal(true);
     };
     const handleCloseNegative = () => {
          setNegativeModal(false);
     };
     const handleQuesRef = (val) => {
          if (val === 1) {
               teamRef.current?.scrollIntoView({
                    behaviour: "smooth",
               });
          }
          if (val === 2) {
               busiRef.current?.scrollIntoView({
                    behaviour: "smooth",
               });
          }
          if (val === 3) {
               prodRef.current?.scrollIntoView({
                    behaviour: "smooth",
               });
          }
          if (val === 4) {
               iPRef.current?.scrollIntoView({
                    behaviour: "smooth",
               });
          }
     };
     const getcompanyLatestValuation = async (t) => {
          if ((tenquestionsFilled && valuation_id) || t) {
               api.get(`/valuation/companies/${valuation_id}/valuations/latest`).then((response) => {
                    if (response.data.success) {
                         setlatestvaluation(response.data.message.valuation);
                    }
               }).catch((error) => {
                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               })
          }
     };
     const getvaluationStatus = async () => {
          if (tenquestionsFilled && valuation_id) {
               api.get(`/valuation/companies/${valuation_id}/status`).then((response) => {
                    if (response.data.success) {
                         setvaluationstatus(response.data.message.status);
                    }
               }).catch((error) => {
                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               })
          }
     };
     const handleOpendownload = () => {
          if (!tenquestionsFilled) {
               transRef.current?.scrollIntoView({
                    behaviour: "smooth",
               });
               toast.error("Ten questions pending");
               handleChangeStep(4);
               return;
          }
          if (!companyInfofilled) {
               transRef.current?.scrollIntoView({
                    behaviour: "smooth",
               });
               toast.error("Company details is pending");
               handleChangeStep(0);

               return;
          }
          if (!quesstionnaireComplete) {
               toast.error("Questionnare is pending");
               handleChangeStep(1);

               return;
          }
          if (!financialsFilled) {
               toast.error("Financials is pending");
               handleChangeStep(2);
               return;
          }

          if (valuationstatus?.valuation_negative) {
               handleopenNegative();
               return;
          }

          getvaluationStatus();
          setOpendownload(true);
     };
     const handleClosedownload = () => {
          setOpendownload(false);
          setreportdownloadtitle("");
          setcalltimer(false);
          setreportready(false);
          setreportid();
     };

     const handledownloadreport = async () => {
          try {
               setisdownloading(true);
               const response = await api.get(`/valuation/companies/${valuation_id}/reports/${reportid}`)
               if (response.data.success) {
                    const url = window.URL.createObjectURL(
                         new Blob([new Uint8Array(response.data.message.data).buffer])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute(
                         "download",
                         `${response.data.eqdata.reportTitle}.pdf`
                    );
                    document.body.appendChild(link);
                    link.click();
                    setisdownloading(false);
                    navigate(`/dashboard/my-reports`)
               }
          } catch (error) {
               setisdownloading(false);

               if (error.response && error.response.status === 401) {
                    setSessionExpire();
                    return;
               }
               if (error.response && error.response.data) {
                    return toast.error(error.response.data.error);
               }
          }
     };
     const handlegetinfoready = async () => {
          if (reportid)
               api.get(`/valuation/companies/${valuation_id}/reports/${reportid}/reportinfo`).then((response) => {
                    if (response.data.success) {
                         if (response.data.message.tobegenerated) {

                              setreportready(!response.data.message.tobegenerated);
                              setcalltimer(true);
                              handlegetinfoready();

                         } else {
                              setreportready(!response.data.message.tobegenerated);
                              setcalltimer(false);
                         }
                    }
               })
                    .catch((error) => {
                         if (error.response && error.response.status === 401) {
                              setSessionExpire();
                              return;
                         }
                         if (error.response && error.response.data) {
                              return toast.error(error.response.data.error);
                         }
                    });
     };

     const handleGenerateReport = async () => {
          if (reportdownloadtitle.trim() === "")
               return toast.warning("Enter Report title");

          setcalltimer(true);
          const data = {
               report_name: reportdownloadtitle,
          }
          api.post(`/valuation/companies/${valuation_id}/reports`, data).then((response) => {
               if (response.data.success) {
                    setreportid(response.data.message?.id);
               }
          })
               .catch((error) => {
                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return error.response.data.error;
                    }
               });
     };

     // const handleOpen = (i) => {
     //      setselectedref(i);
     //      getcompanyLatestValuation();
     //      setOpen(true);
     // };
     const [fundingStatus, setFundingStatus] = useState({
          id: new Date().getTime().toString(),
          equity: "",
          capitalRaised: "",
          valuation: "",
          closingDate: "",
     });
     const handleChangeFinancial = (id, event) => {
          let newState = { ...financialsEquidam };

          const newFinancials = { ...Finantial };
          newFinancials.fields.forEach((field) => {
               const { past_year, forcast, forcast1, forcast2, field_variable } = field;

               if (id === past_year["id"]) {
                    past_year["value"] = event.target.valueAsNumber;
               }
               if (id === forcast.id) {
                    forcast.value = event.target.valueAsNumber;
               }
               if (id === forcast1.id) {
                    forcast1.value = event.target.valueAsNumber;
               }
               if (id === forcast2.id) {
                    forcast2.value = event.target.valueAsNumber;
               }
               if (newState["projections"][field_variable]) {
                    newState["projections"][field_variable][0] = past_year.value;
                    newState["projections"][field_variable][1] = forcast.value;
                    newState["projections"][field_variable][2] = forcast1.value;
                    newState["projections"][field_variable][3] = forcast2.value;
               }
          });

          setfinancialsEquidam(newState);
          setFinantial(newFinancials);
     };
     const handletransaction = (id, event) => {
          const { name } = event.target;
          let perc = 0;
          let newState = { ...transactionsEquidam };
          if (name === "capitalneeded") {
               newState.cInfo[name] = event.target.valueAsNumber;
               for (const c of newState.cInfo.useOfFunds) {
                    perc = +((c.amount / event.target.valueAsNumber) * 100).toFixed(2);
                    c.percentage = perc;
               }
          } else {
               for (const c of newState.cInfo.useOfFunds) {
                    if (c.key === name) {
                         c.amount = event.target.valueAsNumber;
                         perc = +(
                              (event.target.valueAsNumber / newState.cInfo.capitalneeded) *
                              100
                         ).toFixed(2);
                         c.percentage = perc;
                    }
               }
          }
          settransactionsEquidam(newState);
          const newTransactions = { ...transactions };
          newTransactions.contents.forEach((content) => {
               const { fields } = content;

               fields.forEach((field) => {
                    if (id === field.field_id) {
                         field.fieldvalue = event.target.valueAsNumber;
                         field.fieldpercentage = perc;
                    }
                    if (id === "39") {
                         field.fieldpercentage = +(
                              (field.fieldvalue / event.target.valueAsNumber) *
                              100
                         ).toFixed(2);
                    }
               });
          });

          setTransactions(newTransactions);
     };
     const handleFundingStatus = (e) => {
          const { name, valueAsNumber, type, value } = e.target;
          if (type === "number")
               setFundingStatus({ ...fundingStatus, [name]: valueAsNumber });
          else setFundingStatus({ ...fundingStatus, [name]: value });
     };
     const AddFundingRow = () => {
          setFundingRows((prev) => [...prev, fundingStatus]);
          setFundingStatus({
               id: new Date().getTime().toString(),
               equity: "",
               capitalRaised: "",
               valuation: "",
               closingDate: "",
          });
     };
     const deleteFundingRow = (id) => {
          const newArr = fundingRow.filter((row) => {
               return id !== row.closeddate;
          });
          setFundingRows(newArr);
     };
     const handleClose = () => {
          setOpen(false);
     };
     const handleClosetensubmit = () => {
          setopentensubmit(false);
     };

     const [, setselectedref] = React.useState(); //selectedref

     const handleOpen = (i) => {
          setselectedref(i);
          getcompanyLatestValuation();
          setOpen(true);
     };

     const submittenquestionsToEquidam = async (e) => {
          e.preventDefault();
          try {
               settenquesLoader(true);
               const {
                    estimatedrevenues_y1,
                    estimatedrevenues_y4,
                    foundersnumber,
                    employeesnumber,
                    startedyear,
                    country,
                    industry,
                    iptype,
                    devstage,
                    profitability,
               } = tenquestionsEquidam;
               if (foundersnumber < 1) {
                    settenquesLoader(false);

                    return toast.error("Founders Number is invalid");
               }
               if (!country) {
                    settenquesLoader(false);

                    return toast.error("Country cannot be empty");
               }
               if (!industry) {
                    settenquesLoader(false);

                    return toast.error("Industry cannot be empty");
               }
               const date = new Date().getFullYear();
               if (startedyear > date) {
                    settenquesLoader(false);

                    return toast.warning("Started year cannot be of future");
               }
               if (startedyear < 1950) {
                    settenquesLoader(false);

                    return toast.warning("Started year too old");
               }
               if (foundersnumber > 10) {
                    settenquesLoader(false);

                    return toast.warning("Number of Founders is large");
               }

               const response = await api.post(
                    `/valuation/companies/${valuation_id}/tenq`,
                    {
                         estimatedrevenues_y1: +estimatedrevenues_y1,
                         estimatedrevenues_y4: +estimatedrevenues_y4,
                         foundersnumber: +foundersnumber,
                         employeesnumber: +employeesnumber,
                         startedyear: +startedyear,
                         currency,
                         country,
                         industry,
                         iptype,
                         devstage,
                         profitability,
                    }
               );
               if (response.data.success) {
                    settenquesLoader(false);

                    getcompanyLatestValuation(true);
                    handleopentensubmit();
               }
          } catch (error) {
               settenquesLoader(false);
               handleClose();

               if (error.response && error.response.status === 401) {
                    setSessionExpire();
                    return;
               }
               if (error.response && error.response.data) {
                    return toast.error(error.response.data.error);
               }
          }
     };
     function formatDate(date) {
          var d = new Date(date),
               month = "" + (d.getMonth() + 1),
               day = "" + d.getDate(),
               year = d.getFullYear();

          if (month.length < 2) month = "0" + month;
          if (day.length < 2) day = "0" + day;

          return [day, month, year].join("-");
     }
     const handleChange = (id, event, questype) => {
          const { value, name, type } = event.target;

          if (questype === "tenques") {
               let newState = { ...tenquestionsEquidam };
               if (type === "number") {
                    newState[name] = event.target.valueAsNumber;
               } else {
                    newState[name] = value;
               }

               const newtenquestions = [...tenquestions];
               newtenquestions.forEach((field) => {
                    const {
                         field_type,
                         field_id,
                         field_options,
                         past_year,
                         row_label,
                         forcast = [],
                    } = field;
                    if (row_label === "Revenues") {
                         if (id === past_year["id"]) {
                              past_year["value"] = event.target.value;
                         }
                         if (id === forcast.id) {
                              forcast.value = event.target.value;
                         }
                    }
                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });
               settenquestions(newtenquestions);
               settenquestionsEquidam(newState);
          } else if (questype === "ques") {
               let newState = { ...questtionnaireEquidam };
               if (type === "number") {
                    newState[name] = event.target.value;
               } else if (name === "investors") {
                    newState["investors_type1"] = 0;
                    newState["investors_type2"] = 0;
                    newState["investors_type3"] = 0;
                    newState["investors_type4"] = 0;
                    newState["investors_type5"] = 0;
                    newState[value] = event.target.checked ? 1 : 0;
               } else {
                    newState[name] = value;
               }
               newState["currency_symbol"] = getcurrencylist().find(
                    (v) => v.code === currency
               )?.symbol;
               newState["countryextended"] = countrywithcode().find(
                    (v) => v.id === newState["country"]
               )?.option_label;
               setquesttionnaireEquidam(newState);
               const newTeamElements = Team;

               newTeamElements.forEach((field) => {
                    const { field_type, field_id, field_options = [] } = field;
                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });

               setteamQues(newTeamElements);

               // const newElements1 = { ...questionnare }
               const newBusiElements = BusinessModel;
               newBusiElements.forEach((field) => {
                    const { field_type, field_id, field_options = [] } = field;
                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });
               setbusiQues(newBusiElements);

               const newProdElements = ProductMarket;
               newProdElements.forEach((field) => {
                    const { field_type, field_id, field_options = [] } = field;
                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });
               setprodQues(newProdElements);

               const newIPElements = IPLegal;
               newIPElements.forEach((field) => {
                    const { field_type, field_id, field_options = [] } = field;
                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });
               setipQues(newIPElements);

               // setQuestionnare(newElements);
          } else if (questype === "finan") {
               let newState = { ...financialsEquidam };
               if (type === "number") {
                    newState["balance_sheet"][name] = event.target.valueAsNumber;
               } else {
                    newState["balance_sheet"][name] = value;
               }

               setfinancialsEquidam(newState);
               const { Assets = [], Liabilities = [] } = balanceSheet;
               Assets.forEach((field) => {
                    const { field_type, field_id, field_options = [] } = field;

                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });

               Liabilities.forEach((field) => {
                    const { field_type, field_id, field_options = [] } = field;
                    if (id === field_id) {
                         switch (field_type) {
                              case "checkbox":
                                   field["field_value"] = event.target.checked;
                                   break;
                              case "select":
                                   field["field_value"] = event.target.value;
                                   field_options.forEach((option) => {
                                        if (option.field_value === event.target.value) {
                                             option.checked = true;
                                        } else {
                                             option.checked = false;
                                        }
                                   });
                                   break;
                              default:
                                   field["field_value"] = event.target.value;
                                   break;
                         }
                         return;
                    }
               });
               setBalanceSheet({ Assets, Liabilities });
          }
     };
     const handleresetfinancials = async () => {
          api.post(`/valuation/companies/${valuation_id}/financials/resetfinancial`)
               .then((response) => {
                    if (response.data.success) {
                         getfinancials();
                    }
               })
               .catch((error) => {
                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               });
     };
     const getlogo = async () => {
          try {
               if (tenquestionsFilled && valuation_id) {
                    const response = await api.get(`/valuation/companies/${valuation_id}/logo`);
                    if (response.data.success) {
                         if (
                              response.data.message.logo &&
                              response.data.message.logo !== "default.jpg"
                         ) {
                              setlogo(response.data.message.url);
                              setoldlogo(response.data.message.url);
                         }
                         //  else if (
                         //   companymongoDocument &&
                         //   companymongoDocument.clonedReport === false
                         // ) {
                         //   setlogo(profile.logo);
                         //   setoldlogo(profile.logo);
                         // }
                    }
               }
          } catch (error) {
               if (error.response && error.response.status === 401) {
                    setSessionExpire();
                    return;
               }
               if (error.response && error.response.data) {
                    return toast.error(error.response.data.error);
               }
          }
     };
     const getcompanyinfo = async () => {
          try {
               if (tenquestionsFilled && valuation_id) {
                    const response = await api.get(`/valuation/companies/${valuation_id}/info/info`);
                    if (response.data.success) {
                         if (response.data.message && response.data.message) {
                              setcompanyInfofilled(response.data.eqdata.compnanyInfoFilled);
                              if (response.data.message.data) {
                                   let eqinfo = response.data.message.data;
                                   setoldcompanyInfoEquidam(eqinfo);
                                   if (eqinfo.startup_name) setcompanyname(eqinfo.startup_name);
                                   if (eqinfo.corebusiness) setdescription(eqinfo.corebusiness);
                                   if (eqinfo.address) setaddress(eqinfo.address);
                                   if (eqinfo.facebookid) setfacebook(eqinfo.facebookid);
                                   if (eqinfo.linkedinid) setlinkedin(eqinfo.linkedinid);
                                   if (eqinfo.twitterid) settwitter(eqinfo.twitterid);
                                   if (eqinfo.website) setwebsite(eqinfo.website);
                              }
                         }
                    }
               }
          } catch (error) {
               if (error.response && error.response.status === 401) {
                    setSessionExpire();
                    return;
               }
               if (error.response && error.response.data) {
                    return toast.error(error.response.data.error);
               }
          }
     };
     const getcompetitors = async () => {
          if (tenquestionsFilled) {
               const response = await api.get(`/valuation/companies/${valuation_id}/competitors`);
               if (response.data.success) {
                    if (response.data.message && response.data.message.competitors) {
                         let savedcompetitors = [];
                         for (const s of response.data.message.competitors) {
                              savedcompetitors.push({
                                   competitorname: s.name,
                                   competitorurl: s.url,
                                   id: s.id,
                              });
                         }
                         if (response.data.message.competitors.length === 0) {
                              setcompetitors([{ competitorname: "", competitorurl: "" }]);
                              setoldcompetitors(
                                   JSON.parse(
                                        JSON.stringify([{ competitorname: "", competitorurl: "" }])
                                   )
                              );
                         } else {
                              savedcompetitors.push({ competitorname: "", competitorurl: "" });
                              setcompetitors(savedcompetitors);
                              setoldcompetitors(JSON.parse(JSON.stringify(savedcompetitors)));
                         }
                    }
               }
          }
     };

     const getshareholders = async () => {
          if (tenquestionsFilled) {
               const response = await api.get(`/valuation/companies/${valuation_id}/shareholders`);
               if (response.data.success) {
                    if (response.data.message && response.data.message.shareholders) {
                         let savedcompetitors = [];
                         for (const s of response.data.message.shareholders) {
                              savedcompetitors.push({
                                   shareholdername: s.name,
                                   shareholderpercentage: s.percentage,
                                   id: s.id,
                              });
                         }
                         if (response.data.message.shareholders.length === 0) {
                              setshareholders([
                                   { shareholdername: "", shareholderpercentage: "" },
                              ]);
                              setoldshareholders(
                                   JSON.parse(
                                        JSON.stringify([
                                             { shareholdername: "", shareholderpercentage: "" },
                                        ])
                                   )
                              );
                         } else {
                              savedcompetitors.push({
                                   shareholdername: "",
                                   shareholderpercentage: "",
                              });
                              setshareholders(savedcompetitors);
                              setoldshareholders(JSON.parse(JSON.stringify(savedcompetitors)));
                         }
                    }
               }
          }
     };
     const getquestionnaire = async () => {
          if (tenquestionsFilled && valuation_id) {
               api.get(`/valuation/companies/${valuation_id}/questionnaires`).then((response) => {
                    if (response.data.success) {
                         setquesstionnaireComplete(response.data.eqdata.quesFilled);

                         if (
                              response.data.message &&
                              response.data.message.questionnaire &&
                              Team &&
                              BusinessModel &&
                              ProductMarket &&
                              IPLegal
                         ) {
                              const updatedQues = response.data.message.questionnaire;

                              setquesttionnaireEquidam(updatedQues);
                              setoldquesttionnaireEquidam(updatedQues);

                              const newTeamElements = [...Team];

                              newTeamElements.forEach((field) => {
                                   const {
                                        field_type,
                                        field_options,
                                        field_variable = [],
                                   } = field;

                                   switch (field_type) {
                                        case "select":
                                             field["field_value"] = updatedQues[field_variable];
                                             field_options.forEach((option) => {
                                                  if (option.field_value === updatedQues[field_variable]) {
                                                       option.checked = true;
                                                  } else {
                                                       option.checked = false;
                                                  }
                                             });
                                             break;
                                        default:
                                             field["field_value"] = updatedQues[field_variable];
                                             break;
                                   }
                                   return;
                              });

                              setteamQues(newTeamElements);

                              // const newElements1 = { ...questionnare }
                              const newBusiElements = [...BusinessModel];
                              newBusiElements.forEach((field) => {
                                   const {
                                        field_type,
                                        field_options,
                                        field_variable = [],
                                   } = field;

                                   switch (field_type) {
                                        case "select":
                                             field["field_value"] = updatedQues[field_variable];
                                             field_options.forEach((option) => {
                                                  if (field_variable === "investors") {
                                                       if (updatedQues[option.field_value] === 1) {
                                                            option.checked = true;
                                                       } else {
                                                            option.checked = false;
                                                       }
                                                  } else {
                                                       if (
                                                            option.field_value === updatedQues[field_variable]
                                                       ) {
                                                            option.checked = true;
                                                       } else {
                                                            option.checked = false;
                                                       }
                                                  }
                                             });
                                             break;
                                        default:
                                             field["field_value"] = updatedQues[field_variable];
                                             break;
                                   }
                                   return;
                              });
                              setbusiQues(newBusiElements);

                              const newProdElements = [...ProductMarket];
                              newProdElements.forEach((field) => {
                                   const {
                                        field_type,
                                        field_options,
                                        field_variable = [],
                                   } = field;

                                   switch (field_type) {
                                        case "select":
                                             field["field_value"] = updatedQues[field_variable];
                                             field_options.forEach((option) => {
                                                  if (option.field_value === updatedQues[field_variable]) {
                                                       option.checked = true;
                                                  } else {
                                                       option.checked = false;
                                                  }
                                             });
                                             break;
                                        default:
                                             field["field_value"] = updatedQues[field_variable];
                                             break;
                                   }
                                   return;
                              });
                              setprodQues(newProdElements);

                              const newIPElements = [...IPLegal];
                              newIPElements.forEach((field) => {
                                   const {
                                        field_type,
                                        field_options,
                                        field_variable = [],
                                   } = field;

                                   switch (field_type) {
                                        case "select":
                                             field["field_value"] = updatedQues[field_variable];
                                             field_options.forEach((option) => {
                                                  if (option.field_value === updatedQues[field_variable]) {
                                                       option.checked = true;
                                                  } else {
                                                       option.checked = false;
                                                  }
                                             });
                                             break;
                                        default:
                                             field["field_value"] = updatedQues[field_variable];
                                             break;
                                   }
                                   return;
                              });
                              setipQues(newIPElements);

                              // setQuestionnare(newElements);
                         }
                    }
               }).catch(error => {
                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               })
          }
     };
     const getfinancials = async () => {
          if (tenquestionsFilled && valuation_id) {
               api.get(`/valuation/companies/${valuation_id}/financials`)
                    .then((response) => {
                         if (response.data.success) {
                              setfinancialsFilled(response.data.eqdata.financialsFilled);

                              if (
                                   response.data.message &&
                                   response.data.message.financials &&
                                   balanceSheet
                              ) {
                                   const updatedQues = response.data.message.financials;
                                   setfinDate(new Date(updatedQues.startdate));
                                   updatedQues.projections.year_index_count =
                                        updatedQues.year_index_count;
                                   updatedQues.projections.profitmeasurements_default =
                                        updatedQues.profitmeasurements_default;
                                   delete updatedQues.projections.startdate;
                                   delete updatedQues.startdate;
                                   delete updatedQues.subscribed;
                                   delete updatedQues.year_index_count;
                                   delete updatedQues.profitmeasurements_default;

                                   setfinancialsEquidam(updatedQues);
                                   const modifiedUsers = JSON.parse(JSON.stringify(updatedQues));
                                   setoldfinancialsEquidam(modifiedUsers);

                                   const newFinancials = { ...Finantial };
                                   newFinancials.fields.forEach((field) => {
                                        const {
                                             past_year,
                                             forcast,
                                             forcast1,
                                             forcast2,
                                             field_variable,
                                        } = field;
                                        if (updatedQues["projections"][field_variable]) {
                                             past_year.value = updatedQues["projections"][
                                                  field_variable
                                             ][0]
                                                  ? updatedQues["projections"][field_variable][0]
                                                  : 0;
                                             forcast.value = updatedQues["projections"][field_variable][1]
                                                  ? updatedQues["projections"][field_variable][1]
                                                  : 0;
                                             forcast1.value = updatedQues["projections"][field_variable][2]
                                                  ? updatedQues["projections"][field_variable][2]
                                                  : 0;
                                             forcast2.value = updatedQues["projections"][field_variable][3]
                                                  ? updatedQues["projections"][field_variable][3]
                                                  : 0;
                                        }
                                   });

                                   setFinantial(newFinancials);
                                   const { Assets = [], Liabilities = [] } = balanceSheet;
                                   Assets.forEach((field) => {
                                        const {
                                             field_type,
                                             field_variable,
                                             field_options = [],
                                        } = field;

                                        switch (field_type) {
                                             case "select":
                                                  field["field_value"] =
                                                       updatedQues.balance_sheet[field_variable];
                                                  field_options.forEach((option) => {
                                                       if (
                                                            option.field_value ===
                                                            updatedQues.balance_sheet[field_variable]
                                                       ) {
                                                            option.checked = true;
                                                       } else {
                                                            option.checked = false;
                                                       }
                                                  });
                                                  break;
                                             default:
                                                  field["field_value"] = updatedQues.balance_sheet[
                                                       field_variable
                                                  ]
                                                       ? updatedQues.balance_sheet[field_variable]
                                                       : 0;
                                                  break;
                                        }
                                        return;
                                   });

                                   Liabilities.forEach((field) => {
                                        const {
                                             field_type,
                                             field_variable,
                                             field_options = [],
                                        } = field;
                                        switch (field_type) {
                                             case "select":
                                                  field["field_value"] =
                                                       updatedQues.balance_sheet[field_variable];
                                                  field_options.forEach((option) => {
                                                       if (
                                                            option.field_value ===
                                                            updatedQues.balance_sheet[field_variable]
                                                       ) {
                                                            option.checked = true;
                                                       } else {
                                                            option.checked = false;
                                                       }
                                                  });
                                                  break;
                                             default:
                                                  field["field_value"] = updatedQues.balance_sheet[
                                                       field_variable
                                                  ]
                                                       ? updatedQues.balance_sheet[field_variable]
                                                       : 0;
                                                  break;
                                        }
                                        return;
                                   });
                                   setBalanceSheet({ Assets, Liabilities });
                              }
                         }
                    })
                    .catch((error) => {
                         console.log(error);
                         if (error.response && error.response.status === 401) {
                              setSessionExpire();
                              return;
                         }
                         if (error.response && error.response.data) {
                              return toast.error(error.response.data.error);
                         }
                    });
          }
     };
     const gettransactions = async () => {
          if (tenquestionsFilled && valuation_id) {
               api.get(`/valuation/companies/${valuation_id}/transactions/info`)
                    .then((response) => {
                         if (response.data.success && transactions) {
                              settransactionsFilled(response.data.eqdata.transFilled);

                              if (response.data.message) {
                                   let newtr = response.data.message.data;

                                   let newState = { ...transactionsEquidam };
                                   newState.cInfo = newtr;
                                   if (newState.cInfo.capitalneeded === false) {
                                        newState.cInfo.capitalneeded = 0;
                                   }
                                   let perc = 0;
                                   for (const c of newState.cInfo.useOfFunds) {
                                        if (newState.cInfo.capitalneeded && c.amount) {
                                             perc = +(
                                                  (c.amount / newState.cInfo.capitalneeded) *
                                                  100
                                             ).toFixed(2);
                                             c.percentage = perc;
                                        } else c.percentage = 0;
                                   }
                                   settransactionsEquidam(newState);
                                   const modifiedUsers = JSON.parse(JSON.stringify(newState));
                                   setoldtransactionsEquidam(modifiedUsers);
                                   const newTransactions = { ...transactions };

                                   newTransactions.contents.forEach((content) => {
                                        const { fields } = content;

                                        fields.forEach((field) => {
                                             for (const c of newtr.useOfFunds) {
                                                  if (c.key === field.field_variable) {
                                                       field.fieldvalue = c.amount;
                                                       field.fieldpercentage = +(
                                                            (c.amount / newState.cInfo.capitalneeded) *
                                                            100
                                                       ).toFixed(2);
                                                  }

                                                  if (field.field_id === "39") {
                                                       field.fieldvalue = newState.cInfo[field.field_variable];
                                                  }
                                             }
                                        });
                                   });

                                   setTransactions(newTransactions);
                              }
                         }
                    })
                    .catch((error) => {
                         if (error.response && error.response.status === 401) {
                              setSessionExpire();
                              return;
                         }
                         if (error.response && error.response.data) {
                              return toast.error(error.response.data.error);
                         }
                    });
          }
     };
     const getfundingrounds = async () => {
          if (tenquestionsFilled && valuation_id) {
               api.get(`/valuation/companies/${valuation_id}/transactions/closedrounds`)
                    .then((response) => {
                         if (response.data.success) {
                              if (
                                   response.data.message &&
                                   response.data.message.data !== "null"
                              ) {
                                   let newtr = JSON.parse(response.data.message.data);

                                   let newState = { ...transactionsEquidam };
                                   newState.closedrounds = newtr;
                                   for (const c of newtr) {
                                        c.closeddatestring = new Date(c.closeddate);
                                        c.closeddate = new Date(c.closeddate).toISOString();
                                   }
                                   setFundingRows(newtr);
                                   settransactionsEquidam(newState);
                                   const modifiedUsers = JSON.parse(JSON.stringify(newState));
                                   setoldtransactionsEquidam(modifiedUsers);
                              }
                         }
                    })
                    .catch((error) => {
                         if (error.response && error.response.status === 401) {
                              setSessionExpire();
                              return;
                         }
                         if (error.response && error.response.data) {
                              return toast.error(error.response.data.error);
                         }
                    });
          }
     };
     const setJsonFields = () => {
          // setQuestionnare(questions(currency)[0]);

          setteamQues(questions(currency)[0].Team);
          setbusiQues(questions(currency)[0].BusinessModel);
          setprodQues(questions(currency)[0].ProductMarket);
          setipQues(questions(currency)[0].IPLegal);
          setFinantial(questions(currency)[1]);
          setTransactions(questions(currency)[2]);
          setBalanceSheet(questions(currency)[3]);
          settenquestions(questions()[4].tenquestions);
          setbringnewdata(true);
     };

     const checkCompanyInfoChange = () => {
          return (
               JSON.stringify(oldcompanyInfoEquidam) !==
               JSON.stringify({
                    website: website.trim() === "" ? false : website,
                    linkedinid:
                         linkedin && linkedin.trim() != ""
                              ? linkedin
                              : oldcompanyInfoEquidam.linkedinid,
                    twitterid:
                         twitter && twitter.trim() != ""
                              ? twitter
                              : oldcompanyInfoEquidam.twitterid,
                    facebookid:
                         facebook && facebook.trim() != ""
                              ? facebook
                              : oldcompanyInfoEquidam.facebookid,
                    angellistid: oldcompanyInfoEquidam.angellistid,
                    crunchbaseid: oldcompanyInfoEquidam.crunchbaseid,
                    address: address.trim() === "" ? false : address,
                    report_custom: oldcompanyInfoEquidam.report_custom,
                    report_primary_color: oldcompanyInfoEquidam.report_primary_color,
                    startup_name: companyname,
                    corebusiness: description,
                    elevpitch: oldcompanyInfoEquidam.elevpitch,
                    currency_symbol: oldcompanyInfoEquidam.currency_symbol,
               }) ||
               oldlogo !== logo ||
               JSON.stringify(oldshareholders) !== JSON.stringify(shareholders) ||
               JSON.stringify(competitors) !== JSON.stringify(oldcompetitors)
          );
     };

     const checkunsaved = () => {
          if (
               (oldcompanyInfoEquidam && step === 0 && checkCompanyInfoChange()) ||
               (step === 1 &&
                    JSON.stringify(oldquesttionnaireEquidam) !==
                    JSON.stringify(questtionnaireEquidam)) ||
               (step === 2 &&
                    JSON.stringify(oldfinancialsEquidam) !==
                    JSON.stringify(financialsEquidam)) ||
               (step === 3 &&
                    JSON.stringify(oldtransactionsEquidam) !==
                    JSON.stringify(transactionsEquidam))
          ) {
               setunsavedchanges(true);
          } else {
               setunsavedchanges(false);
          }
     };

     const handleNext = () => {
          if (step === 0) {
               handleChangeStep(1)
          } else if (step === 1) {
               handleChangeStep(2)
          } else if (step === 2) {
               handleChangeStep(3)
          }
          // console.log('next step')

     };
     const submitcompanyinfo = async (e) => {

          e.preventDefault();
          setisSaving(true);
          if (!companyname || companyname === "") {
               setisSaving(false);
               handleClose();

               return toast.warning("Company Name cannot be empty");
          }

          if (description.length >= 200) {
               setisSaving(false);
               handleClose();

               return toast.warning("Description too long");
          }

          if (!logo) {
               setisSaving(false);
               handleClose();

               return toast.warning("Upload company logo");
          }
          // console.log(imageUrlToBase64(logo));
          const data = {
               website: website,
               linkedinid: linkedin,
               twitterid: twitter,
               facebookid: facebook,
               angellistid: "",
               crunchbaseid: "",
               address,
               report_custom: "f",
               report_primary_color: null,
               startup_name: companyname,
               corebusiness: description,
               elevpitch: "",
               currency_symbol: getcurrencylist().find((v) => v.code === currency)
                    ?.symbol,
               cid: valuation_id,
               logo: logo,
          }
          api.post(`/valuation/companies/${valuation_id}/info/info`, data).then((res) => {
               if (res.data) {
                    setisSaving(false);

                    toast.success("Saved Successfully");
                    handleClose();
                    getcompanyinfo();
                    getCompanyData();
                    getlogo();
                    handleNext();
               }
          })
               .catch((error) => {
                    setisSaving(false);
                    handleClose();

                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               });
     };
     const submitCompyAndShareHolder = async (type) => {
          if (type === "c") {
               let newcompetitors = [];
               for (const c of competitors) {
                    if (c.competitorname.length > 0 && c.competitorurl.length > 0)
                         newcompetitors.push({ name: c.competitorname, url: c.competitorurl });
               }

               await api.post(`/valuation/companies/${valuation_id}/competitors`,
                    newcompetitors
               );
               getcompetitors();
          } else if (type === "s") {
               let newcompetitors = [];
               for (const c of shareholders) {
                    if (c.shareholdername.length > 0 && c.shareholderpercentage > 0)
                         newcompetitors.push({
                              name: c.shareholdername,
                              percentage: +c.shareholderpercentage,
                         });
               }

               await api.post(`/valuation/companies/${valuation_id}/shareholders`, { shareholders: newcompetitors });
               getshareholders();
          }
     };

     const submitquesstionaire = (e) => {
          e.preventDefault();
          setisSaving(true);
          const date = new Date().getFullYear();
          if (questtionnaireEquidam.startedyear > date) {
               setisSaving(false);
               handleClose();

               return toast.warning("Started year cannot be of future");
          }
          if (questtionnaireEquidam.startedyear < 1950) {
               setisSaving(false);
               handleClose();

               return toast.warning("Started year too old");
          }
          if (questtionnaireEquidam.foundersnumber > 10) {
               setisSaving(false);
               handleClose();

               return toast.warning("Number of Founders is large");
          }
          if (!questtionnaireEquidam.country) {
               setisSaving(false);
               handleClose();

               return toast.warning("Country cannot be empty");
          }
          if (!questtionnaireEquidam.industry) {
               setisSaving(false);
               handleClose();

               return toast.warning("Industry cannot be empty");
          }
          const data = {
               questtionnaireEquidam,
          }
          api.post(`/valuation/companies/${valuation_id}/questionnaires`, data).then((res) => {
               if (res.data.success) {
                    setisSaving(false);
                    getCompanyData();
                    toast.success("Saved Successfully");
                    handleNext();
                    getquestionnaire();
                    const d = res.data.message;
                    handleClose();

                    if (d.response && d.response.valuation) {
                         setlatestvaluation(d.response.valuation);
                    }
                    if (d.status) {
                         setvaluationstatus(d.status);
                    }
               }
          })
               .catch((error) => {
                    setisSaving(false);
                    handleClose();

                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               });
     };
     const submitFinancials = async (e) => {
          e.preventDefault();
          setisSaving(true);

          let financialdata = { ...financialsEquidam };
          financialdata.currency = getcurrencylist().find(
               (v) => v.code === currency
          )?.code;
          financialdata.projections.currency = getcurrencylist().find(
               (v) => v.code === currency
          )?.code;
          financialdata.projections.startdate = formatDate(finDate);
          const data = {
               financialdata,
          }

          api.post(`/valuation/companies/${valuation_id}/financials`, data).then((res) => {
               handleClose();

               if (res.data.success) {
                    setisSaving(false);
                    getCompanyData();
                    toast.success("Saved Successfully");
                    handleNext();

                    setValue(1);
                    const d = res.data.message;
                    if (d && d.valuation) {
                         setlatestvaluation(d.valuation);
                    }
                    if (d && d.status) {
                         setvaluationstatus(d.status);
                    }
                    getfinancials();
               }
          })
               .catch((error) => {
                    setisSaving(false);

                    handleClose();
                    if (error && error.response && error.response.data) {
                         let edata = error.response.data.error;
                         if (edata.invalid && edata.invalid[0]) {
                              if (edata.invalid[0]?.id === "estimatedrevenues") {
                                   return toast.error("Final year revenues cannot be empty");
                              } else if (edata.invalid[0])
                                   return toast.error(
                                        `${edata.invalid[0].id === "costgoods"
                                             ? "Cost of goods sold"
                                             : edata.invalid[0].id
                                        } cannot be empty`
                                   );
                         } else return toast.error(edata.message);
                    }
               });
     };
     const submitCurrentFunding = async (e) => {
          e.preventDefault();
          setisSaving(true);
          const data = {
               transactionsEquidam,
               transFilled: true,
          }
          api.post(`/valuation/companies/${valuation_id}/transactions`, data).then((res) => {
               if (res.data.success) {
                    setisSaving(false);
                    handleClose();

                    setValuet(1);
                    const d = res.data.message;
                    if (d.cInfo && d.cInfo.valuation) {
                         setlatestvaluation(d.cInfo.valuation);
                    }
                    if (d.cInfo && d.cInfo.status) {
                         setvaluationstatus(d.cInfo.status);
                    }
                    getCompanyData();

                    toast.success("Saved Successfully");
                    handleNext();

                    gettransactions();
                    getfundingrounds();
               }
          })
               .catch((error) => {
                    handleClose();

                    if (error.response && error.response.status === 401) {
                         setSessionExpire();
                         return;
                    }
                    if (error.response && error.response.data) {
                         return toast.error(error.response.data.error);
                    }
               });
     };
     const handlesave = (e) => {
          e.preventDefault();
          if (step === 0) {
               submitcompanyinfo(e);
               submitCompyAndShareHolder("c");
               submitCompyAndShareHolder("s");
          } else if (step === 1) {
               submitquesstionaire(e);
          } else if (step === 2) {
               submitFinancials(e);
          } else if (step === 3) {
               submitCurrentFunding(e);
          }
     };



     function getStepContent(step) {

          step = Number(step)

          switch (step) {
               case 0:
                    return (
                         <CompanyInfo
                              tenquestionsFilled={tenquestionsFilled}
                              getlogo={getlogo}
                              // setValuationStep={setValuationStep}
                              handleNext={handleNext}
                              companyInfoRef={companyInfoRef}
                              companyInfo={companyInfo}
                              currency={currency}
                              companyname={companyname}
                              setcompanyname={setcompanyname}
                              description={description}
                              setdescription={setdescription}
                              website={website}
                              setwebsite={setwebsite}
                              linkedin={linkedin}
                              setlinkedin={setlinkedin}
                              twitter={twitter}
                              settwitter={settwitter}
                              facebook={facebook}
                              setfacebook={setfacebook}
                              address={address}
                              setaddress={setaddress}
                              competitors={competitors}
                              setcompetitors={setcompetitors}
                              shareholders={shareholders}
                              setshareholders={setshareholders}
                              logo={logo}
                              setlogo={setlogo}
                              handlesave={handlesave}
                              setoldcompanyInfoEquidam={setoldcompanyInfoEquidam}
                              setoldlogo={setoldlogo}
                              companymongoDocument={companymongoDocument}
                              getshareholders={getshareholders}
                              getcompetitors={getcompetitors}
                              AddFundingRow={submitCompyAndShareHolder}
                         />
                    );
               case 1:
                    return (
                         <FormContext.Provider value={{ handleChange }}>
                              <Questionnare
                                   reportTitle={reportTitle}
                                   getquestionnaire={getquestionnaire}
                                   handleChange={handleChange}
                                   Team={Team}
                                   BusinessModel={BusinessModel}
                                   IPLegal={IPLegal}
                                   ProductMarket={ProductMarket}
                                   handleNext={handleNext}
                                   // setValuationStep={setValuationStep}
                                   // setActiveStep={setActiveStep}
                                   quesRef={quesRef}
                                   teamRef={teamRef}
                                   busiRef={busiRef}
                                   prodRef={prodRef}
                                   iPRef={iPRef}
                                   questtionnaireEquidam={questtionnaireEquidam}
                                   setquesttionnaireEquidam={setquesttionnaireEquidam}
                                   type="ques"
                                   setteamQues={setteamQues}
                                   setbusiQues={setbusiQues}
                                   setprodQues={setprodQues}
                                   setipQues={setipQues}
                                   handlesave={handlesave}
                              />
                         </FormContext.Provider>
                    );
               case 2:
                    return (
                         <FormContext.Provider value={{ handleChange, handleChangeFinancial }}>
                              <Financials
                                   reportTitle={reportTitle}
                                   handleresetfinancials={handleresetfinancials}
                                   Finantial={Finantial}
                                   balanceSheet={balanceSheet}
                                   // handleNext={handleNext}
                                   // setActiveStep={setActiveStep}
                                   currency={currency}
                                   setFinantial={setFinantial}
                                   finRef={finRef}
                                   finDate={finDate}
                                   setfinDate={setfinDate}
                                   type="finan"
                                   financialsEquidam={financialsEquidam}
                                   handlesave={handlesave}
                                   value={value}
                              />
                         </FormContext.Provider>
                    );
               case 3:
                    return (
                         <FormContext.Provider value={{ handletransaction }}>
                              <FundingRounds
                                   reportTitle={reportTitle}
                                   transactions={transactions}
                                   // handleNext={handleNext}
                                   // setValuationStep={setValuationStep}
                                   fundingStatus={fundingStatus}
                                   handleFundingStatus={handleFundingStatus}
                                   AddFundingRow={AddFundingRow}
                                   setFundingRows={setFundingRows}
                                   setFundingStatus={setFundingStatus}
                                   fundingRow={fundingRow}
                                   deleteFundingRow={deleteFundingRow}
                                   // setActiveStep={setActiveStep}
                                   // title={title}
                                   // setQuestionnare={setQuestionnare}
                                   setteamQues={setteamQues}
                                   setbusiQues={setbusiQues}
                                   setprodQues={setprodQues}
                                   setipQues={setipQues}
                                   setTransactions={setTransactions}
                                   currency={currency}
                                   transRef={transRef}
                                   transactionsEquidam={transactionsEquidam}
                                   settransactionsEquidam={settransactionsEquidam}
                                   value={valuet}
                                   handlesave={handlesave}
                              />
                         </FormContext.Provider>
                    );
               case 4:
                    return (
                         <FormContext.Provider value={{ handleChange }}>
                              <Tenques
                                   tenquestions={tenquestions}
                                   submittenquestionsToEquidam={submittenquestionsToEquidam}
                                   currency={currency}
                                   type="tenques"
                                   tenquesLoader={tenquesLoader}
                                   tenquestionsFilled={tenquestionsFilled}
                                   handleChangeStep={handleChangeStep}
                              />
                         </FormContext.Provider>
                    );

               default:
                    return "Unknown step";
          }
     }

     const getCompanyData = () => {

          api.get(`/valuation/companiesdata/${valuation_id}`).then((res) => {
               const eqdata = res.data.message;
               setcompanymongoDocument(res.data.message);
               setreportTitle(res.data.message?.companyName);

               settenquestionsFilled(eqdata.tenquesFilled);
               setcurrency(eqdata.currency);

               setcompanyInfofilled(eqdata.compnanyInfoFilled);
               setquesstionnaireComplete(eqdata.quesFilled);
               setfinancialsFilled(eqdata.financialsFilled);
               settransactionsFilled(eqdata.transFilled);
          }).catch((error) => {
               if (error.response && error.response.status === 401) {
                    setSessionExpire(false);
                    return;
               }
               if (error.response && error.response.data) {
                    return toast.error(error.response.data.error);
               }
          })
     }

     useEffect(() => {
          getCompanyData();
     }, [])

     useEffect(() => {
          checkunsaved();
     }, [
          questtionnaireEquidam,
          oldquesttionnaireEquidam,
          oldcompanyInfoEquidam,
          financialsEquidam,
          oldfinancialsEquidam,
          transactionsEquidam,
          oldtransactionsEquidam,
          companyname,
          description,
          address,
          facebook,
          linkedin,
          twitter,
          website,
          logo,
          oldlogo,
          companymongoDocument,
          oldshareholders,
          shareholders,
          competitors,
          oldcompetitors,
     ]);

     useEffect(() => {
          if (step === 0) {
               getlogo();
               getcompanyinfo();
               getcompetitors();
               getshareholders();
          } else if (step === 1) {
               getquestionnaire();
          } else if (step === 2) {
               getfinancials();
          } else if (step === 3) {
               gettransactions();
               getfundingrounds();
          }


          getcompanyLatestValuation();
          getvaluationStatus();
     }, [step, tenquestionsFilled]); //bringnewdata



     useEffect(() => {
          if (currency) {
               setJsonFields();
          }

     }, [valuation_id, currency]);



     useEffect(() => {
          handlegetinfoready();
     }, [reportid]);


     useEffect(() => {
          if (reportready) {
               setLoadCurrent(!loadCurrent)
          }
     }, [reportready])

     return <>
          <div className="d-flex justify-content-between align-items-center" style={{ margin: "88px 38px 38px 74px" }}>
               {step !== 4 && (
                    <h6 className='fw-600 heading_s mx-3 mt-1'>
                         <svg
                              onClick={() => {
                                   if (unsavedchanges) {
                                        handleOpen(5);
                                   } else
                                        navigate('/dashboard')
                                   // history.push(
                                   //      `/dashboard/${user.type}?tab=${user.type === "institute" ||
                                   //           user.type === "academiauser"
                                   //           ? "6"
                                   //           : "3"
                                   //      }&valuate=1`
                                   // );
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="19"
                              height="16"
                              fill="none"
                              viewBox="0 0 19 16"
                              className="me-4 pointer"
                         >
                              <path
                                   fill="#000"
                                   d="M19 7H4.414l5.293-5.293L8.293.293.586 8l7.707 7.707 1.414-1.414L4.414 9H19V7z"
                              ></path>
                         </svg>{" "}
                         {companymongoDocument?.companyName}
                    </h6>
               )}

               {step !== 4 && (
                    <div className="d-flex align-items-center">
                         {/* {isSaving ? (
                      <span className="mr-8">
                        <CircularProgress style={{ color: "#4ad7d1" }} />
                      </span>
                    ) : null} */}

                         {isSaving ? (
                              <div>
                                   <CircularProgress style={{ color: "#000" }} />
                              </div>

                         ) : (
                              <button
                                   className={`submit_btn-outlined d-flex align-items-center`}
                                   type="submit"
                                   form={
                                        step === 0
                                             ? "companyinfo"
                                             : step === 1
                                                  ? "questionnaire"
                                                  : step === 2
                                                       ? "financials"
                                                       : step === 3
                                                            ? "transactions"
                                                            : ""
                                   }
                                   value="Submit"
                              >
                                   {unsavedchanges && (
                                        <span
                                             className={`unsaved_dot ${unsavedchanges && "active_dot_save"
                                                  } me-1`}
                                        ></span>
                                   )}
                                   Save
                              </button>
                         )}

                         <button
                              type="button"
                              className={
                                   companymongoDocument && companymongoDocument.tenquesFilled &&
                                        companymongoDocument.compnanyInfoFilled &&
                                        companymongoDocument.quesFilled &&
                                        companymongoDocument.financialsFilled
                                        ? "submit_btn ml-10"
                                        : "submit_btn submit_btn_disabled ml-10"
                              }
                              disabled={
                                   !(
                                        companymongoDocument &&
                                        companymongoDocument.tenquesFilled &&
                                        companymongoDocument.compnanyInfoFilled &&
                                        companymongoDocument.quesFilled &&
                                        companymongoDocument.financialsFilled
                                   )
                              }
                              onClick={() => {
                                   if (unsavedchanges) {
                                        handleOpen(step + 1);
                                   } else handleOpendownload();
                              }}
                         >
                              Submit
                         </button>
                    </div>
               )}
          </div>
          <div className='d-flex justify-content-center flex-wrap mt-24' style={{ margin: "24px 38px 38px 74px" }}>
               {step !== 4 && (
                    <div className="valuation_tool_left_root">

                         <div className="valuation_tool_left_main">
                              <div
                                   className={`d-flex align-items-center`}
                                   onClick={() => {
                                        if (unsavedchanges) {
                                             handleOpen(1);
                                        } else {
                                             navigate(`/dashboard/generate-report/${valuation_id}/valStep/0`)
                                        }
                                   }}
                              >
                                   <div>
                                        <span
                                             className={`mr-8 ${step === 0 && "val_left_icon_active"}`}
                                        >
                                             {companymongoDocument && companymongoDocument.compnanyInfoFilled ? (
                                                  <SavedValuation />
                                             ) : (
                                                  <Step1Icon />
                                             )}
                                        </span>
                                   </div>
                                   <div>
                                        <p className={`body2 cursor_p fw-400 ${step === 0
                                             ? "fw-600 "
                                             : ""
                                             }`}>
                                             Company Info
                                        </p>
                                   </div>
                              </div>


                              <span className="valuation_tool_left_line"></span>



                              <div
                                   className={`d-flex align-items-center`}
                                   onClick={() => {
                                        if (unsavedchanges) {
                                             handleOpen(2);
                                        } else {
                                             navigate(`/dashboard/generate-report/${valuation_id}/valStep/1`)
                                        }
                                   }}
                              >
                                   <div>
                                        <span
                                             className={`mr-8 ${step === 1 && "val_left_icon_active"
                                                  }`}
                                        >
                                             {companymongoDocument && companymongoDocument.quesFilled ? (
                                                  <SavedValuation />
                                             ) : (
                                                  <Step2Icon />
                                             )}
                                        </span>
                                   </div>
                                   <div>
                                        <p
                                             className={`body2 cursor_p fw-400 ${step === 1
                                                  ? "fw-600 "
                                                  : ""
                                                  }`}
                                        >
                                             Questionnaire
                                        </p>
                                   </div>
                              </div>


                              <span className="valuation_tool_left_line"></span>


                              {step === 1 && (
                                   <div className="d-flex flex-column ml-12">
                                        <p
                                             className={`textstyle2 pointer ${step === 1 ? "active_sub_tab_left " : ""
                                                  }`}
                                             onClick={() => handleQuesRef(1)}
                                        >
                                             <CircleIcon />
                                             &nbsp; Team
                                        </p>

                                        <span className="valuation_tool_left_line2"></span>

                                        <p
                                             className={`textstyle2 pointer ${step === 1 ? "active_sub_tab_left " : ""
                                                  }`}
                                             onClick={() => handleQuesRef(2)}
                                        >
                                             <CircleIcon />
                                             &nbsp; Business Model
                                        </p>

                                        <span className="valuation_tool_left_line2"></span>

                                        <p
                                             className={`textstyle2 pointer ${step === 1 ? "active_sub_tab_left " : ""
                                                  }`}
                                             onClick={() => handleQuesRef(3)}
                                        >
                                             <CircleIcon />
                                             &nbsp; Product Market
                                        </p>

                                        <span className="valuation_tool_left_line2"></span>

                                        <p
                                             className={`textstyle2 pointer ${step === 1 ? "active_sub_tab_left" : ""
                                                  }`}
                                             onClick={() => handleQuesRef(4)}
                                        >
                                             <CircleIcon />
                                             &nbsp; IP Legal
                                        </p>

                                        <span className="valuation_tool_left_line2"></span>
                                   </div>
                              )}

                              <div
                                   className={`d-flex align-items-center`}
                                   onClick={() => {
                                        if (unsavedchanges) {
                                             handleOpen(3);
                                        } else {
                                             navigate(`/dashboard/generate-report/${valuation_id}/valStep/2`)
                                        }
                                   }}
                              >
                                   <div>

                                        <span
                                             className={`mr-8 ${step === 2 && "val_left_icon_active"
                                                  }`}
                                        >
                                             {companymongoDocument && companymongoDocument.financialsFilled ? (
                                                  <SavedValuation />
                                             ) : (
                                                  <FinanceIcon />
                                             )}
                                        </span>
                                   </div>

                                   <div>
                                        <p
                                             className={`body2 cursor_p fw-400 ${step === 2
                                                  ? "fw-600"
                                                  : ""
                                                  }`}
                                        >
                                             Financials
                                        </p>
                                   </div>
                              </div>
                              <span className="valuation_tool_left_line"></span>
                              <div
                                   className={`d-flex align-items-center`}
                                   onClick={() => {
                                        if (unsavedchanges) {
                                             handleOpen(4);
                                        } else {
                                             // handleSelectRef(4);
                                             navigate(`/dashboard/generate-report/${valuation_id}/valStep/3`)
                                        }
                                   }}
                              >
                                   <div>
                                        <span
                                             className={`mr-8 ${step === 3 && "val_left_icon_active"
                                                  }`}
                                        >
                                             {companymongoDocument && companymongoDocument.transFilled ? (
                                                  <SavedValuation />
                                             ) : (
                                                  <FundingIcon />
                                             )}
                                        </span>
                                   </div>
                                   <div>
                                        <p
                                             className={`body2 cursor_p fw-400  ${step === 3
                                                  ? "fw-600"
                                                  : ""
                                                  }`}
                                        >
                                             Transactions & Funding
                                        </p>
                                   </div>
                              </div>
                         </div>
                         {valuationstatus && (
                              <div className="cvaluation_root mt-24">
                                   {valuationstatus?.valuation_negative ? (
                                        <>
                                             <div className="d-flex">
                                                  <span
                                                       className="pointer mr-8"
                                                       onClick={handleopenNegative}
                                                  >
                                                       <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                       >
                                                            <path
                                                                 fill="#FF5D5B"
                                                                 d="M12 1.5a10.5 10.5 0 100 21 10.5 10.5 0 000-21zm-1.304 5.25a1.312 1.312 0 112.625 0v6.029a1.312 1.312 0 01-2.625 0V6.75zM12 18.563a1.505 1.505 0 110-3.011 1.505 1.505 0 010 3.01z"
                                                            ></path>
                                                       </svg>
                                                  </span>
                                                  <p className="title mb-8">Negative valuation</p>
                                             </div>
                                             <p className="body2 mt-16">
                                                  The calculation resulted in a negative valuation.
                                                  {/* <span className="ml-12 redAstrick cVal_p">
                                                  {ValuationCurrencyFormat(latestvaluation.vc?.value)}
                                                  </span> */}
                                             </p>
                                        </>
                                   ) : (
                                        latestvaluation && (
                                             <div className='current_val d-flex justify-content-center mt-10'>
                                                  <div>
                                                       <p className="heading_s fw-500 lh-22">Current Valuation</p>
                                                       <p className='mt-20'>
                                                            <span className="caption36 color_light_v3">
                                                                 {
                                                                      getcurrencylist().find(
                                                                           (v) => v.code === currency
                                                                      )?.code
                                                                 }
                                                            </span>{" "}
                                                            <span className="caption36">
                                                                 {ValuationCurrencyFormat(
                                                                      latestvaluation.valuation_avg?.value
                                                                 )}
                                                            </span>
                                                       </p>
                                                  </div>
                                             </div>
                                        )
                                   )}
                              </div>
                         )}
                    </div>
               )}
               {

                    step === 4 ?
                         (<div className="row justify-content-center w_100">
                              <div className="col-lg-9 col-md-10 col-sm-11 col-12">
                                   {getStepContent(step)}
                              </div>
                         </div>)
                         : (
                              <div className="valuation_tool_right_main">
                                   <div className="valuationForm">
                                        {getStepContent(step)}
                                   </div>
                              </div>
                         )
               }
          </div>


          <CustomModal open={opentensubmit}
               handleClose={handleClosetensubmit} width={600}>
               <div className="d-flex justify-content-between">
                    <div
                         className="initital_est_modal_left_main"
                         style={{ backgroundImage: `url(${InitiateCircle})` }}
                    >
                         {valuationstatus?.valuation_negative ? (
                              <p className="title1 grey_58">Negative valuation</p>
                         ) : (
                              latestvaluation && (
                                   <>
                                        <p className="title3">
                                             {
                                                  getcurrencylist().find(
                                                       (v) => v.code === currency
                                                  )?.symbol
                                             }
                                             {`${ValuationCurrencyFormat(
                                                  latestvaluation.range?.low
                                             )} - ${ValuationCurrencyFormat(
                                                  latestvaluation.range?.high
                                             )}`}
                                        </p>
                                        <p
                                             className="body3 mt-8 pt-8"
                                             style={{ borderTop: "0.5px solid #adadad" }}
                                        >
                                             Initial Estimate
                                        </p>
                                   </>
                              )
                         )}
                    </div>
                    <div className="initital_est_modal_right_main">
                         <h6 className="title2">Initial Estimate</h6>
                         <p className="body3 mt-20 ">
                              The estimate now takes into account the estimate
                              performance and cashflow your company estimates to
                              generate in future
                         </p>
                         <div className="mt-40 d-flex align-items-center">
                              <button
                                   className="cta_lets_start"
                                   onClick={() => {
                                        handleClosetensubmit();
                                        getCompanyData();
                                        handleChangeStep(0);
                                        // setValuationStep(10);
                                        localStorage.setItem(
                                             "valuation_id",
                                             valuation_id
                                        );
                                   }}
                              >
                                   Valuate Now
                              </button>
                         </div>
                    </div>
               </div>
          </CustomModal>
          <CustomModal open={negativeModal}
               handleClose={handleCloseNegative}>
               <div className="position-relative d-flex">
                    <div className="nagetive_modal_right_root mr-40">
                         <p className="title1">
                              <span
                                   className="pointer mr-8"
                                   onClick={handleopenNegative}
                              >
                                   <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                   >
                                        <path
                                             fill="#FF5D5B"
                                             d="M12 1.5a10.5 10.5 0 100 21 10.5 10.5 0 000-21zm-1.304 5.25a1.312 1.312 0 112.625 0v6.029a1.312 1.312 0 01-2.625 0V6.75zM12 18.563a1.505 1.505 0 110-3.011 1.505 1.505 0 010 3.01z"
                                        ></path>
                                   </svg>
                              </span>{" "}
                              Attention!
                         </p>
                         <p className="title1 mt-32">
                              The calculation resulted in a negative valuation
                         </p>
                         <div className="mt-24">
                              <p className="body1">
                                   Reasons may be several, but in general this is caused
                                   by:
                              </p>
                              <ul>
                                   <li className="body1 mt-16">
                                        The EBITDA of the last projected year is negative in
                                        this case, please add more years, until you reach 1
                                        profitable year.
                                   </li>
                                   <li className="mt-24 body1">
                                        The future positive cash flows do not compensate for
                                        the negative ones This may be due to the working
                                        capital defaults, or too conservative projections,
                                        thus please double-check them.
                                   </li>
                              </ul>
                         </div>
                         <p className="mt-23 title2">
                              In any case, we are here to help!
                         </p>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                         <div
                              className="initital_est_modal_left_main"
                              style={{
                                   backgroundImage: `url(${redCircle})`,
                                   marginRight: 0,
                              }}
                         >
                              {valuationstatus?.valuation_negative && (
                                   <p className="title2">
                                        {`${getcurrencylist().find((v) => v.code === currency)
                                             ?.symbol
                                             } ... - ...`}
                                   </p>
                              )}
                         </div>
                         <div className="mt-24">
                              <button
                                   className="cta_lets_start"
                                   onClick={() => {
                                        handleCloseNegative();
                                        navigate(`/dashboard/generate-report/${valuation_id}/valStep/2`)
                                   }}
                              >
                                   GO TO FINANCIAL
                              </button>
                         </div>
                    </div>
               </div>
          </CustomModal>
          <CustomModal open={opendownload} handleClose={handleClosedownload}>
               <div className="position-relative">
                    <div>
                         <p className="body2">Generate valuation reports</p>
                         <p className="body1 mt-16">
                              A clear and detailed company valuation report is the
                              best way to communicate your value to potential
                              investors, buyers, stakeholders or employees.
                         </p>
                    </div>
                    <div className="d-flex flex-column mt-32">
                         <label htmlFor="reportTitle" className="valuation_label">
                              Report Title
                         </label>
                         <input
                              type="text"
                              id="reportTitle"
                              className="valuation_input"
                              placeholder="eg: xyz Report 1"
                              value={reportdownloadtitle}
                              onChange={(e) => setreportdownloadtitle(e.target.value)}
                         />
                    </div>
                    <div className="mt-16">
                         {calltimer ? (
                              <div className="d-flex align-items-center">
                                   <CircularProgress style={{ color: "#000" }} />{" "}
                                   <span className="ml-8">Generating report...</span>
                              </div>
                         ) : null}
                         {isdownloading ? (
                              <div className="d-flex mb-2 align-items-center">
                                   <CircularProgress style={{ color: "#000" }} />{" "}
                                   <span className="ml-8">Downloading Report...</span>
                              </div>
                         ) : null}

                         {reportready ? (
                              <>
                                   <p>Report generated and ready to download</p>

                                   <div className="mt-24 d-flex align-items-center">
                                        <button
                                             type="button"
                                             className="cta_lets_start"
                                             disabled={isdownloading}
                                             onClick={() => {
                                                  handledownloadreport();
                                             }}
                                        >
                                             Download Report
                                        </button>
                                   </div>
                              </>
                         ) : (
                              <>
                                   {" "}
                                   <div className="report_title-main">
                                        {valuationstatus ? (
                                             <>
                                                  {!valuationstatus.questionnaire_complete &&
                                                       !valuationstatus.financials_complete ? (
                                                       <p>
                                                            {" "}
                                                            Questtionaire and Financials are pending
                                                       </p>
                                                  ) : !valuationstatus.questionnaire_complete ? (
                                                       <p> Questtionaire is pending</p>
                                                  ) : !valuationstatus.financials_complete ? (
                                                       <p> Financials is pending</p>
                                                  ) : !valuationstatus.fundraising_complete ? (
                                                       <p> Transactions is pending</p>
                                                  ) : valuationstatus.valuation_negative ? (
                                                       <p className="redAstrick">
                                                            {" "}
                                                            Negative valuation
                                                       </p>
                                                  ) : (
                                                       ""
                                                  )}
                                             </>
                                        ) : null}
                                   </div>
                                   <div className="mt-40">
                                        <button
                                             type="button"
                                             className={
                                                  !(
                                                       valuationstatus &&
                                                       valuationstatus.financials_complete &&
                                                       valuationstatus.fundraising_complete &&
                                                       valuationstatus.questionnaire_complete &&
                                                       !valuationstatus.valuation_negative &&
                                                       !reportready &&
                                                       reportdownloadtitle.trim() !== ""
                                                  )
                                                       ? "cta_lets_start cta_lets_start_disabled"
                                                       : "cta_lets_start"
                                             }
                                             disabled={
                                                  !(
                                                       valuationstatus &&
                                                       valuationstatus.financials_complete &&
                                                       valuationstatus.fundraising_complete &&
                                                       valuationstatus.questionnaire_complete &&
                                                       !valuationstatus.valuation_negative &&
                                                       !reportready &&
                                                       reportdownloadtitle.trim() !== ""
                                                  )
                                             }
                                             onClick={() => {
                                                  handleGenerateReport();
                                             }}
                                        >
                                             Generate Report
                                        </button>
                                   </div>
                              </>
                         )}
                    </div>
               </div>
          </CustomModal>
     </>
}

export default ValuationMain;