import React from 'react';
import authPattern from '../../../Assets/Images/authPattern.svg'
import { ReactComponent as Logo } from '../../../Assets/Logos/vcbay_with_name.svg'

import AuthImage from './authImage'
import Back from '../Back';
// import { ForwardIcon } from '../../Icons'
import { useMediaQuery } from 'react-responsive';
const AuthLayout = ({ authType, children }) => {
     const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
     return <div className='row m-0 p-0'>

          <div className='col-lg-6 col-sm-12 col-sx-12 d-flex flex-column align-items-center auth_left_content'>
               <div>
                    <div className='mt-40'>
                         <Logo />
                    </div>
                    <Back classes="mt-40" />
                    {
                         children
                    }
               </div>
          </div>
          {
               isDesktop && <div className='col-lg-6 m-0 p-0'>
                    <div className='background d-flex flex-column justify-content-center align-items-center'>
                         <img src={authPattern} alt="pattern" className='auth_pattern' />


                         <h1 className='fw-600 heading_l lh-36'>
                              {
                                   authType === 'login' && "Login to your Account"
                              }
                              {
                                   authType === 'signup' && "Create an Account"
                              }
                              {
                                   authType === 'emailVerify' && "Check your mail"
                              }
                              {
                                   authType === "forgotPass" && "Forgot Password ?"
                              }
                              {
                                   authType === "checkMail" && "Check your mail"
                              }
                              {
                                   authType === 'resetPass' && "Reset Password"
                              }
                         </h1>
                         <AuthImage />


                    </div>
               </div>
          }

     </div>
}

export default AuthLayout;