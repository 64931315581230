import React from "react";
import fin_projections from "../../../Assets/Images/fin_projections.svg";
import round_info from "../../../Assets/Images/round_info.svg";
import val_details from "../../../Assets/Images/val_details.svg";
import Val_summary from "../../../Assets/Images/Val_summary.svg";
import "./insights.css";
const Insights = () => {
  return (
    <div id="insights" className="home_insights_root section_info">
      <div className="">
        <h1 className="section_info--header">Report Insights</h1>
        <p className="section_info--text">
          A number, without context, is meaningless. During negotiations, it’s
          important to <br /> put all information on the table so that both
          parties can fairly be on-board.
        </p>
      </div>
      <div className="">
        <div className="timeline">
          <div className="container left">
            <div className="image">
              <h1 className="timeline_number">01</h1>
              <img src={Val_summary} alt="Val_summary" />
            </div>
            <div className="content">
              <h2 className="fw-400 lh-90 color_primary heading_l">
                Valuation Summary
              </h2>
              <ul className="timeline_list">
                <li>Valuation</li>
                <li>Valuation low bound and high bound</li>
                <li>Breakdown of the valuation by method</li>
              </ul>
            </div>
          </div>

          <div className="container right">
            <div className="content">
              <h1 className="timeline_number">02</h1>
              <h2 className="fw-400 lh-90 color_primary heading_l">
                Round information
              </h2>
              <ul className="timeline_list">
                <li>Amount of capital needed</li>
                <li>Percentage of shares with low and high bound</li>
                <li>Use of funds</li>
              </ul>
            </div>
            <div className="image">
              <img src={round_info} alt="round_info" />
            </div>
          </div>

          <div className="container left">
            <div className="image">
              <h1 className="timeline_number">03</h1>
              <img src={val_details} alt="val_details" />
            </div>
            <div className="content">
              <h2 className="fw-400 lh-90 color_primary heading_l">
                Valuation Details
              </h2>
              <ul className="timeline_list">
                <li>One page deep dive into each of the 5 methods</li>
                <li>
                  Clearly stated assumptions, data sources and calculations
                </li>
              </ul>
            </div>
          </div>

          <div className="container right">
            <div className="content">
              <h1 className="timeline_number">04</h1>
              <h2 className="fw-400 lh-90 color_primary heading_l">
                Complete Financial Projections
              </h2>
              <ul className="timeline_list">
                <li>Thorough and easy-to-read profit and loss</li>
                <li>Cash flow forecasts</li>
              </ul>
            </div>
            <div className="image">
              <img src={fin_projections} alt="fin_projections" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insights;
