module.exports.getcurrencylist = () => {
     return [
          { code: "INR", symbol: "₹", name: "Indian rupee" },
          { code: "USD", symbol: "$", name: "United States dollar" },
          { code: "EUR", symbol: "€", name: "European euro" },
          { code: "GBP", symbol: "£", name: "British Pound" },
          { code: "ALL", symbol: "Lek", name: "Albanian lek" },
          { code: "ARS", symbol: "$", name: "Argentine peso" },
          { code: "AWG", symbol: "ƒ", name: "Aruban florin" },
          { code: "AUD", symbol: "$", name: "Australian dollar" },
          { code: "BSD", symbol: "$", name: "Bahamian dollar" },
          { code: "BHD", symbol: "د.ب.‏", name: "Bahraini dinar" },
          { code: "BBD", symbol: "$", name: "Barbadian dollar" },
          { code: "BMD", symbol: "$", name: "Bermudian dollar" },
          { code: "BOB", symbol: "$b", name: "Bolivian boliviano" },
          {
               code: "BAM",
               symbol: "KM",
               name: "Bosnia and Herzegovina convertible mark",
          },
          { code: "BRL", symbol: "R$", name: "Brazilian real" },
          { code: "BGN", symbol: "лв.", name: "Bulgarian lev" },
          { code: "CAD", symbol: "$", name: "Canadian dollar" },
          { code: "KYD", symbol: "$", name: "Cayman Islands dollar" },
          { code: "CLP", symbol: "$", name: "Chilean peso" },
          { code: "CNY", symbol: "¥", name: "Chinese Yuan Renminbi" },
          { code: "COP", symbol: "$", name: "Colombian peso" },
          { code: "CRC", symbol: "₡", name: "Costa Rican colon" },
          { code: "HRK", symbol: "kn", name: "Croatian kuna" },
          { code: "CZK", symbol: "Kč", name: "Czech koruna" },
          { code: "DKK", symbol: "kr.", name: "Danish krone" },
          { code: "DOP", symbol: "RD$", name: "Dominican peso" },
          { code: "EGP", symbol: "ج.م.‏", name: "Egyptian pound" },
          { code: "GIP", symbol: "£", name: "Gibraltar pound" },
          { code: "HKD", symbol: "HK$", name: "Hong Kong dollar" },
          { code: "HUF", symbol: "Ft", name: "Hungarian forint" },
          { code: "ISK", symbol: "kr.", name: "Icelandic krona" },

          { code: "IDR", symbol: "Rp", name: "Indonesian rupiah" },
          { code: "ILS", symbol: "₪", name: "Israeli new shekel" },
          { code: "JMD", symbol: "J$", name: "Jamaican dollar" },
          { code: "JPY", symbol: "¥", name: "Japanese yen" },
          { code: "JOD", symbol: "JOD", name: "Jordanian dinar" },
          { code: "KES", symbol: "KSh", name: "Kenyan Shillings" },
          { code: "KWD", symbol: "د.ك.‏", name: "Kuwaiti dinar" },
          { code: "MYR", symbol: "RM", name: "Malaysian ringgit" },
          { code: "IMP", symbol: "£", name: "Manx pound" },
          { code: "MUR", symbol: "₨", name: "Mauritian rupee" },
          { code: "MXN", symbol: "$", name: "Mexican peso" },
          { code: "TWD", symbol: "NT$", name: "New Taiwan dollar" },
          { code: "NZD", symbol: "$", name: "New Zealand dollar" },
          { code: "NGN", symbol: "₦", name: "Nigerian naira" },
          { code: "NOK", symbol: "kr", name: "Norwegian krone" },
          { code: "PYG", symbol: "Gs", name: "Paraguayan guarani" },
          { code: "PEN", symbol: "S/.", name: "Peruvian sol" },
          { code: "PHP", symbol: "PhP", name: "Philippine peso" },
          { code: "PLN", symbol: "zł", name: "Polish zloty" },
          { code: "QAR", symbol: "ر.ق.‏", name: "Qatari riyal" },
          { code: "RON", symbol: "lei", name: "Romanian leu" },
          { code: "RUB", symbol: "р.", name: "Russian ruble" },
          { code: "SAR", symbol: "SAR‏", name: "Saudi Arabian riyal" },
          { code: "RSD", symbol: "Din.", name: "Serbian dinar" },
          { code: "SGD", symbol: "S$", name: "Singapore dollar" },
          { code: "ZAR", symbol: "R", name: "South African rand" },
          { code: "KRW", symbol: "₩", name: "South Korean won" },
          { code: "SEK", symbol: "kr", name: "Swedish krona" },
          { code: "CHF", symbol: "fr.", name: "Swiss franc" },
          { code: "TTD", symbol: "TT$", name: "Trinidad and Tobago dollar" },
          { code: "TND", symbol: "د.ت.‏", name: "Tunisian dinar" },
          { code: "TRY", symbol: "TL", name: "Turkish lira" },
          { code: "AED", symbol: "د.إ.‏", name: "UAE dirham" },
          { code: "UAH", symbol: "₴", name: "Ukrainian hryvnia" },
          { code: "UYU", symbol: "$U", name: "Uruguayan peso" },
          { code: "XOF", symbol: "CFA", name: "West African CFA franc" },
     ];
};
