import React, { useState } from 'react';
import AuthLayout from '../../Components/Layouts/Auth';
import { ForwardIcon } from '../../Components/Icons';
import { useFormik } from "formik";
import * as Yup from 'yup';
import CryptoJS from "crypto-js";
import api from '../../axiosAPI';
import { toast } from 'react-toastify';
import useQuery from '../../Utils/queryParamsExtract';
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from "@mui/icons-material";
const ResetPassword = () => {
     const navigate = useNavigate()
     const query = useQuery();
     const tk = query.get('tk');
     const [isLoading, setIsLoading] = useState(false)
     const [showPassword, setShowPassword] = useState(false)
     const [showConfirmPassword, setShowConfirmPassword] = useState(false)
     const validationSchema = Yup.object().shape({
          password: Yup.string()
               .required("Password is required")
               .min(6, "Password too short! Must be at least 6 characters."),
          confirmPassword: Yup.string().required("Confirm password is required")
               .oneOf([Yup.ref('password'), null], 'Passwords must match')
     });

     const onSubmit = (values, actions) => {
          setIsLoading(true)
          const password = CryptoJS.AES.encrypt(values.password, "H~jP,/%6R*'Tc|xg").toString();
          const postData = { password, token: tk }

          api.post('/user/reset-password', postData).then(res => {
               if (res.data.success) {
                    setIsLoading(false)
                    toast.success(`Password reset successfully`);
                    actions.resetForm();
                    navigate('/login')
               }
          }).catch(error => {
               toast.error(error.response.data.message, { position: "bottom-right" });
               setIsLoading(false)

          })

     }

     const { values, errors, handleChange, touched, handleSubmit, handleBlur } = useFormik({ //isSubmitting
          initialValues: {
               password: "",
               confirmPassword: ""
          },
          onSubmit,
          validationSchema: validationSchema
     })
     return <AuthLayout authType="resetPass">
          <div>
               <form autoComplete="off" onSubmit={handleSubmit}>
                    <div className='mt-5'>
                         <label className='auth_label' htmlFor="password">Password</label>
                         <div className='position-relative'>
                              <input type={showPassword ? 'text' : "password"}
                                   id="password"
                                   className={`auth_input mt-1 ${(errors.password && touched.password) && 'border-danger'}`}
                                   placeholder="Enter your Password here"
                                   value={values.password}
                                   onChange={handleChange('password')}
                                   onBlur={handleBlur}
                              />
                              {
                                   (touched.password && errors.password) && <div className='text-danger mt-1'>{errors.password}</div>
                              }


                              <div
                                   className='position-absolute cursor_p'
                                   style={{ top: '30%', right: '5%' }}
                                   onClick={() => setShowPassword(!showPassword)}
                              >
                                   {showPassword ? (
                                        <VisibilityOff style={{ color: "#123769" }} />
                                   ) : (
                                        <Visibility style={{ color: "#123769" }} />
                                   )}
                              </div>
                         </div>

                    </div>

                    <div className='mt-4'>
                         <label className='auth_label' htmlFor="confirmPassword">Confirm password</label>
                         <div className='postition-relative'>

                              <input type={showConfirmPassword ? 'text' : "password"}
                                   id="confirmPassword"
                                   className={`auth_input mt-1 ${(errors.confirmPassword && touched.confirmPassword) && 'border-danger'}`}
                                   placeholder="Enter your Password here"
                                   value={values.confirmPassword}
                                   onChange={handleChange('confirmPassword')}
                                   onBlur={handleBlur}
                              />
                              {
                                   (errors.confirmPassword && touched.confirmPassword) && <div className='text-danger mt-1'>{errors.confirmPassword}</div>
                              }
                              <div
                                   className='position-absolute cursor_p'
                                   style={{ top: '30%', right: '5%' }}
                                   onClick={() => setShowConfirmPassword(!showPassword)}
                              >
                                   {showConfirmPassword ? (
                                        <VisibilityOff style={{ color: "#123769" }} />
                                   ) : (
                                        <Visibility style={{ color: "#123769" }} />
                                   )}
                              </div>
                         </div>

                    </div>
                    <button className='auth_btn mt-5' type='submit'>
                         {
                              isLoading ? <div className='d-flex justify-content-center w-100'>
                                   <CircularProgress style={{ color: "#fff" }} />
                              </div> : <>Reset <ForwardIcon /></>
                         }

                    </button>
               </form>

          </div>
     </AuthLayout>
}
export default ResetPassword;