import React from 'react';


const AuthImage = () => {
     return <svg width="500" height="452" viewBox="0 0 528 480" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_b_10_67)">
               <ellipse cx="268" cy="239.804" rx="240" ry="239.804" fill="white" fillOpacity="0.1" />
          </g>
          <ellipse cx="97" cy="122.971" rx="35" ry="34.9714" fill="white" />
          <path d="M97 161.943C118.536 161.943 136 144.498 136 122.971C136 101.445 118.536 84 97 84C75.464 84 58 101.445 58 122.971C58 144.498 75.464 161.943 97 161.943Z" stroke="white" strokeOpacity="0.18" strokeWidth="8" />
          <g clipPath="url(#clip0_10_67)">
               <path d="M90.9883 105.893C88.1476 106.498 85.968 108.565 85.2086 111.363C85.0328 112.003 85.0187 112.179 85.0187 113.297C85.0258 114.331 85.0469 114.612 85.1734 115.069C85.7992 117.347 87.1492 119.02 89.1601 120.012C90.0812 120.462 90.7281 120.652 91.7406 120.764C93.1609 120.912 94.4828 120.673 95.8398 120.012C97.8226 119.048 99.2008 117.34 99.8265 115.069C99.9531 114.612 99.9812 114.331 99.9812 113.297C99.9812 112.186 99.9601 112.003 99.7914 111.363C99.032 108.544 96.8312 106.477 93.9625 105.886C93.2664 105.745 91.6703 105.745 90.9883 105.893Z" fill="url(#paint0_linear_10_67)" />
               <path d="M84.6742 123.886C81.8266 124.505 79.6469 126.712 79.0984 129.525C79.0211 129.926 79 130.777 79 133.287C79 136.402 79.007 136.542 79.1406 136.767C79.218 136.894 79.3797 137.055 79.5063 137.133C79.7383 137.273 79.8859 137.273 89.4836 137.273H99.2359L99.2711 134.475C99.2992 131.859 99.3133 131.648 99.4539 131.156C99.8477 129.834 100.783 128.674 101.943 128.091C102.259 127.929 102.273 127.908 102.273 127.612C102.273 127.043 102.421 126.227 102.632 125.623C102.752 125.292 102.829 124.983 102.808 124.934C102.759 124.8 101.535 124.223 100.832 124.005L100.199 123.809L92.6758 123.795C86.2703 123.787 85.082 123.802 84.6742 123.886Z" fill="url(#paint1_linear_10_67)" />
               <path d="M107.202 123.886C106.64 124.034 106.169 124.315 105.627 124.821C105.255 125.173 105.086 125.398 104.875 125.841C104.516 126.586 104.453 126.994 104.453 128.491V129.729L103.94 129.764C103.005 129.827 102.231 130.327 101.774 131.17L101.535 131.613V135.023V138.434L101.774 138.877C102.062 139.404 102.498 139.826 103.033 140.072L103.434 140.262H108.25H113.066L113.467 140.072C114.002 139.826 114.438 139.404 114.726 138.877L114.965 138.434V135.023V131.613L114.726 131.17C114.269 130.327 113.495 129.827 112.56 129.764L112.047 129.729V128.491C112.047 126.994 111.984 126.586 111.625 125.841C111.175 124.905 110.247 124.139 109.27 123.879C108.749 123.745 107.709 123.745 107.202 123.886ZM108.855 126.206C109.178 126.361 109.558 126.804 109.649 127.127C109.691 127.275 109.727 127.922 109.727 128.569V129.75H108.25H106.773V128.569C106.773 127.922 106.809 127.275 106.851 127.127C106.935 126.811 107.315 126.368 107.638 126.206C107.927 126.066 108.552 126.066 108.855 126.206Z" fill="url(#paint2_linear_10_67)" />
          </g>
          <ellipse cx="43" cy="239.971" rx="35" ry="34.9714" fill="white" />
          <path d="M43 278.943C64.536 278.943 82 261.498 82 239.971C82 218.445 64.536 201 43 201C21.464 201 4 218.445 4 239.971C4 261.498 21.464 278.943 43 278.943Z" stroke="white" strokeOpacity="0.18" strokeWidth="8" />
          <g clipPath="url(#clip1_10_67)">
               <path d="M42.2266 222.57C40.7992 222.787 39.2594 223.659 38.3805 224.749C37.5086 225.825 37.1008 226.816 36.9531 228.223C36.8758 228.982 36.932 229.327 37.1641 229.453C37.2344 229.495 37.7688 229.523 38.3453 229.523C39.6109 229.523 39.6531 229.502 39.7305 228.715C39.8219 227.737 40.1102 227.091 40.75 226.409C41.4672 225.635 42.3883 225.255 43.3797 225.326C44.8984 225.424 46.1641 226.472 46.5508 227.941C46.6281 228.244 46.6563 228.715 46.6563 229.643V230.93H41.5445C36.5523 230.93 36.4188 230.937 36.1094 231.077C35.7297 231.253 35.582 231.373 35.343 231.738L35.1602 232.02L35.1391 237.033C35.118 242.025 35.125 242.039 35.2727 242.391C35.4484 242.805 35.7648 243.101 36.1867 243.262C36.468 243.368 37.1078 243.375 43.2531 243.361L50.0102 243.34L50.3547 243.129C50.5656 242.995 50.7766 242.784 50.9102 242.573L51.1211 242.229L51.1422 237.3C51.1563 234.023 51.1422 232.287 51.093 232.118C50.9172 231.513 50.2211 230.93 49.6656 230.93H49.4828L49.4547 229.397C49.4266 228.131 49.3984 227.78 49.2719 227.33C48.6461 225.037 46.8953 223.266 44.6945 222.696C44.0617 222.527 42.8734 222.471 42.2266 222.57ZM43.5555 234.867C44.4414 235.12 44.9266 236.133 44.5539 236.941C44.4836 237.096 44.3078 237.335 44.1672 237.462L43.9141 237.708V238.924V240.141L43.7102 240.366C43.5344 240.562 43.457 240.598 43.1828 240.598C42.9297 240.598 42.8242 240.555 42.6695 240.401L42.4727 240.211L42.4516 238.966L42.4305 237.722L42.1492 237.412C41.8047 237.04 41.6641 236.723 41.6641 236.33C41.6711 235.359 42.6484 234.607 43.5555 234.867Z" fill="url(#paint3_linear_10_67)" />
               <path d="M28.0375 248.107C27.8969 248.255 27.8828 248.346 27.8828 249.056C27.8828 249.492 27.8617 249.844 27.8406 249.844C27.8195 249.844 27.5313 249.689 27.2008 249.492C26.568 249.12 26.2727 249.056 25.9984 249.253C25.8859 249.33 25.8438 249.429 25.8438 249.633C25.8438 249.963 25.9141 250.041 26.6242 250.448C26.9266 250.624 27.1938 250.793 27.2148 250.828C27.2359 250.863 26.9688 251.053 26.6172 251.25C26.2656 251.447 25.9492 251.672 25.9141 251.742C25.7945 251.96 25.8297 252.291 25.9914 252.452C26.2094 252.67 26.5398 252.6 27.25 252.178L27.8477 251.827L27.8688 252.656C27.8898 253.465 27.8969 253.5 28.0727 253.641C28.3047 253.823 28.5156 253.823 28.7477 253.641C28.9234 253.5 28.9305 253.465 28.9516 252.656L28.9727 251.827L29.6406 252.206C30.0063 252.417 30.3859 252.586 30.4914 252.586C30.7938 252.586 31.0047 252.368 30.9977 252.066C30.9906 251.728 30.9273 251.665 30.1961 251.243C29.8516 251.046 29.5844 250.856 29.6055 250.828C29.6195 250.8 29.9219 250.617 30.2664 250.413C31.0469 249.963 31.2016 249.668 30.85 249.26C30.6602 249.042 30.2945 249.112 29.6406 249.492C29.3102 249.682 29.0219 249.844 28.9938 249.844C28.9586 249.844 28.9375 249.478 28.9375 249.035C28.9375 248.318 28.9234 248.212 28.7969 248.086C28.6 247.889 28.2273 247.903 28.0375 248.107Z" fill="url(#paint4_linear_10_67)" />
               <path d="M37.7336 248.128C37.607 248.29 37.5859 248.416 37.5859 249.077C37.5859 249.499 37.5719 249.844 37.5578 249.844C37.5438 249.844 37.2625 249.682 36.932 249.492C36.2641 249.098 35.9266 249.042 35.6875 249.281C35.5188 249.45 35.5047 249.752 35.6523 249.963C35.7086 250.048 36.0461 250.28 36.3977 250.484L37.0375 250.849L36.4539 251.18C36.1305 251.362 35.793 251.573 35.7086 251.658C35.4906 251.862 35.4977 252.192 35.7297 252.424C35.9758 252.67 36.2078 252.621 36.9602 252.178L37.5508 251.827L37.5859 252.607C37.607 253.043 37.6492 253.444 37.6844 253.514C37.7758 253.711 38.1133 253.809 38.3523 253.711C38.6336 253.598 38.7109 253.338 38.7109 252.509V251.819L39.3648 252.199C40.0961 252.628 40.3211 252.67 40.5742 252.41C40.9469 252.044 40.75 251.714 39.843 251.187L39.2594 250.849L39.9484 250.434C40.7219 249.97 40.8414 249.809 40.6797 249.429C40.6234 249.281 40.525 249.19 40.3703 249.148C40.1102 249.077 39.9414 249.141 39.2383 249.548L38.7461 249.837L38.7109 249.042C38.6688 248.107 38.5844 247.945 38.1414 247.945C37.9375 247.945 37.8461 247.987 37.7336 248.128Z" fill="url(#paint5_linear_10_67)" />
               <path d="M47.4578 248.093C47.3453 248.212 47.3172 248.388 47.2891 249.042L47.2539 249.837L46.7617 249.548C46.0586 249.141 45.8899 249.077 45.6297 249.148C45.475 249.19 45.3766 249.281 45.3203 249.429C45.1586 249.809 45.2781 249.97 46.0516 250.434L46.7406 250.849L46.157 251.187C45.25 251.714 45.0531 252.044 45.4258 252.41C45.6789 252.67 45.9039 252.628 46.6352 252.199L47.2891 251.819V252.509C47.2891 253.338 47.3664 253.598 47.6477 253.711C47.8867 253.809 48.2242 253.711 48.3156 253.514C48.3508 253.444 48.393 253.043 48.4141 252.607L48.4492 251.827L49.0399 252.178C49.7922 252.621 50.0242 252.67 50.2703 252.424C50.4672 252.227 50.5094 251.918 50.3688 251.721C50.3195 251.651 49.982 251.426 49.6164 251.222L48.9625 250.842L49.5109 250.533C49.8133 250.371 50.1508 250.146 50.2563 250.041C50.5797 249.731 50.5024 249.288 50.0875 249.148C49.8414 249.063 49.7922 249.077 48.8922 249.591L48.4141 249.858V249.084C48.4141 248.416 48.393 248.29 48.2664 248.128C48.0766 247.889 47.6617 247.868 47.4578 248.093Z" fill="url(#paint6_linear_10_67)" />
               <path d="M57.2031 248.086C57.0766 248.212 57.0625 248.318 57.0625 249.035C57.0625 249.478 57.0484 249.844 57.0273 249.844C57.0133 249.844 56.6898 249.675 56.3102 249.464C55.7898 249.169 55.5719 249.084 55.4313 249.119C55.2133 249.162 55.0164 249.352 55.0094 249.534C54.9813 249.9 55.0867 250.027 55.7266 250.399C56.0781 250.61 56.3734 250.8 56.3945 250.821C56.4086 250.849 56.2117 250.997 55.9516 251.144C55.0164 251.679 54.8477 251.904 55.0656 252.312C55.2625 252.698 55.5297 252.677 56.3594 252.199L57.0273 251.812L57.0484 252.649C57.0695 253.591 57.1398 253.739 57.5969 253.739C58.0117 253.739 58.075 253.605 58.1172 252.656L58.1523 251.827L58.8203 252.206C59.3688 252.53 59.5305 252.586 59.7273 252.558C60.0016 252.516 60.1492 252.347 60.1563 252.073C60.1563 251.749 60.0508 251.63 59.3828 251.243C59.0383 251.046 58.75 250.863 58.75 250.835C58.75 250.814 58.982 250.666 59.2563 250.512C60.0156 250.09 60.1563 249.949 60.1563 249.64C60.1563 249.464 60.107 249.33 60.0227 249.253C59.7766 249.028 59.4672 249.091 58.8063 249.478C58.4688 249.682 58.1805 249.844 58.1594 249.844C58.1383 249.844 58.1172 249.478 58.1172 249.035C58.1172 248.107 58.0398 247.945 57.5898 247.945C57.4352 247.945 57.2875 248.002 57.2031 248.086Z" fill="url(#paint7_linear_10_67)" />
               <path d="M25.1125 255.862C24.9367 256.031 24.9508 257.234 25.1266 257.388C25.2461 257.494 25.5766 257.508 28.4313 257.508C32.2 257.508 31.8906 257.585 31.8906 256.629C31.8906 255.673 32.2 255.75 28.4172 255.75C25.6047 255.75 25.2109 255.764 25.1125 255.862Z" fill="url(#paint8_linear_10_67)" />
               <path d="M34.8227 255.877C34.6399 256.073 34.6539 257.227 34.8297 257.388C34.9492 257.494 35.2797 257.508 38.1203 257.508C40.8695 257.508 41.2984 257.494 41.4391 257.395C41.5797 257.297 41.5938 257.234 41.5938 256.636C41.5938 256.095 41.5727 255.961 41.4672 255.87C41.3477 255.764 41.0172 255.75 38.1344 255.75C35.125 255.75 34.9281 255.757 34.8227 255.877Z" fill="url(#paint9_linear_10_67)" />
               <path d="M44.5258 255.877C44.4344 255.975 44.4062 256.151 44.4062 256.65C44.4062 257.234 44.4203 257.297 44.5609 257.395C44.7016 257.494 45.1305 257.508 47.8937 257.508C50.875 257.508 51.0719 257.501 51.1773 257.381C51.3602 257.184 51.3461 256.031 51.1703 255.87C51.0508 255.764 50.7203 255.75 47.8375 255.75C44.8281 255.75 44.6312 255.757 44.5258 255.877Z" fill="url(#paint10_linear_10_67)" />
               <path d="M54.25 255.891C54.1305 256.01 54.1094 256.123 54.1094 256.629C54.1094 257.585 53.8 257.508 57.5758 257.508C60.5781 257.508 60.775 257.501 60.8805 257.381C61.0492 257.198 61.0492 256.024 60.8875 255.862C60.7891 255.764 60.3953 255.75 57.5828 255.75C54.482 255.75 54.3836 255.757 54.25 255.891Z" fill="url(#paint11_linear_10_67)" />
          </g>
          <ellipse cx="97" cy="356.71" rx="35" ry="34.9714" fill="white" />
          <path d="M97 395.681C118.536 395.681 136 378.236 136 356.71C136 335.183 118.536 317.738 97 317.738C75.464 317.738 58 335.183 58 356.71C58 378.236 75.464 395.681 97 395.681Z" stroke="white" strokeOpacity="0.18" strokeWidth="8" />
          <g clipPath="url(#clip2_10_67)">
               <path d="M82.4664 340.582C81.2219 340.856 80.0266 341.805 79.457 342.973C78.9648 343.964 79 342.966 79 357C79 371.034 78.9648 370.036 79.457 371.027C80.0406 372.223 81.2219 373.151 82.5086 373.425C83.2117 373.573 110.802 373.566 111.498 373.418C112.771 373.151 113.966 372.216 114.543 371.027C115.035 370.029 115.007 371.048 114.986 356.817L114.965 343.957L114.803 343.521C114.304 342.192 113.242 341.152 111.906 340.673L111.449 340.512L97.1758 340.498C85.3844 340.491 82.825 340.505 82.4664 340.582ZM111.977 358.561C111.977 365.796 111.955 369.213 111.899 369.396C111.808 369.727 111.428 370.162 111.077 370.331C110.823 370.458 110.31 370.465 97 370.465C83.6898 370.465 83.1766 370.458 82.9234 370.331C82.5719 370.162 82.1922 369.727 82.1008 369.396C82.0445 369.213 82.0234 365.796 82.0234 358.561V348H97H111.977V358.561Z" fill="url(#paint12_linear_10_67)" />
               <path d="M90.0039 352.626C89.2375 352.915 88.6047 353.491 88.2391 354.223C88.0492 354.616 88.0352 354.694 88.0352 355.488C88.0352 356.304 88.0422 356.353 88.2672 356.81C88.8648 358.019 90.1727 358.709 91.4664 358.491C92.1484 358.371 92.6687 358.111 93.1398 357.64C94.6164 356.156 94.2016 353.695 92.3102 352.767C91.8812 352.556 91.7687 352.535 91.0938 352.514C90.5102 352.5 90.2781 352.528 90.0039 352.626Z" fill="url(#paint13_linear_10_67)" />
               <path d="M106.745 355.523C106.633 355.559 106.464 355.657 106.373 355.748C106.063 356.037 106 356.241 106 356.993V357.696L103.525 357.717L101.043 357.738L100.712 357.935C100.227 358.216 99.9883 358.652 99.9883 359.25C99.9883 359.848 100.227 360.284 100.712 360.565L101.043 360.762L103.525 360.783L106 360.804V361.528C106 362.323 106.084 362.548 106.513 362.864C106.787 363.068 107.462 363.068 107.737 362.864C107.983 362.688 110.261 360.059 110.402 359.798C110.458 359.693 110.5 359.447 110.5 359.25C110.5 359.053 110.458 358.807 110.402 358.702C110.268 358.448 107.983 355.819 107.758 355.65C107.547 355.502 107.027 355.432 106.745 355.523Z" fill="url(#paint14_linear_10_67)" />
               <path d="M88.3727 360.03C87.1211 360.241 85.968 361.12 85.3984 362.308C85.0609 363.019 84.9766 363.426 84.9766 364.39C84.9766 365.283 85.0891 365.606 85.4898 365.852C85.7289 366 85.7711 366 90.9953 366C96.1281 366 96.2687 365.993 96.4937 365.859C96.9297 365.592 97 365.374 97 364.333C97 363.539 96.9719 363.328 96.8242 362.85C96.3812 361.458 95.1719 360.361 93.7305 360.058C93.3508 359.981 92.718 359.96 90.9883 359.96C89.7508 359.967 88.5695 359.995 88.3727 360.03Z" fill="url(#paint15_linear_10_67)" />
          </g>
          <path d="M213.5 227.314H263.5V252.294H215L209 257.789V341.721L187 369.198H138C141 359.706 140.5 354.71 137.5 343.22L178 342.72L184 338.224V257.789L179.5 252.294H84C86.3243 242.995 87.2144 237.651 84 226.815H179.5L184 221.319V140.386L178.5 135.39H138.133C140.677 126.37 140.648 120.92 138.165 110.41H187L209 131.393V221.319L213.5 227.314Z" fill="url(#paint16_linear_10_67)" fillOpacity="0.4" />
          <rect x="264" y="95.9219" width="264" height="287.764" rx="12" fill="#F1F5F9" />
          <g filter="url(#filter1_d_10_67)">
               <rect x="283" y="188.848" width="227" height="51.9575" rx="4" fill="white" />
          </g>
          <rect x="345" y="204.832" width="66" height="5.99509" rx="2.99755" fill="#A6B1C4" />
          <rect x="345" y="218.82" width="129" height="5.99509" rx="2.99755" fill="#E3E9F0" />
          <g filter="url(#filter2_d_10_67)">
               <rect x="283" y="252.793" width="227" height="51.9575" rx="4" fill="white" />
          </g>
          <rect x="345" y="268.781" width="66" height="5.99509" rx="2.99755" fill="#A6B1C4" />
          <rect x="345" y="282.77" width="129" height="5.99509" rx="2.99755" fill="#E3E9F0" />
          <g filter="url(#filter3_d_10_67)">
               <rect x="283" y="316.742" width="227" height="51.9575" rx="4" fill="white" />
          </g>
          <ellipse cx="315" cy="342.721" rx="16" ry="15.9869" fill="#E3E9F0" />
          <path d="M315.727 344.447H314.273V340.813H315.727M315.727 347.353H314.273V345.9H315.727M307 349.533H323L315 335.727L307 349.533Z" fill="#FFBC49" />
          <rect x="345" y="332.727" width="66" height="5.99509" rx="2.99755" fill="#A6B1C4" />
          <rect x="345" y="346.715" width="129" height="5.99509" rx="2.99755" fill="#E3E9F0" />
          <path d="M264 107.922C264 101.294 269.373 95.9219 276 95.9219H516C522.627 95.9219 528 101.294 528 107.922V131.892H264V107.922Z" fill="white" />
          <ellipse cx="283" cy="112.91" rx="5" ry="4.99591" fill="#F25C5D" />
          <ellipse cx="297" cy="112.91" rx="5" ry="4.99591" fill="#FFD438" />
          <ellipse cx="311" cy="112.91" rx="5" ry="4.99591" fill="#69DE85" />
          <rect x="442" y="110.91" width="72" height="4.99591" rx="2.49795" fill="#7F7F7F" />
          <rect x="442" y="110.91" width="72" height="4.99591" rx="2.49795" fill="#E3E9F0" />
          <rect x="283" y="149.879" width="113" height="23.9804" rx="4" fill="#E2E8ED" />
          <rect x="307" y="158.871" width="66" height="5.99509" rx="2.99755" fill="#A6B1C4" />
          <rect x="423" y="158.871" width="66" height="5.99509" rx="2.99755" fill="#A6B1C4" />
          <ellipse cx="315" cy="214.823" rx="16" ry="15.9869" fill="#E3E9F0" />
          <path d="M315 206.082C313.269 206.082 311.578 206.595 310.139 207.555C308.7 208.516 307.578 209.882 306.916 211.479C306.254 213.077 306.081 214.835 306.418 216.531C306.756 218.226 307.589 219.784 308.813 221.007C310.037 222.23 311.596 223.062 313.293 223.4C314.99 223.737 316.75 223.564 318.348 222.902C319.947 222.24 321.314 221.12 322.275 219.682C323.237 218.244 323.75 216.554 323.75 214.825C323.75 212.506 322.828 210.282 321.187 208.643C319.546 207.003 317.321 206.082 315 206.082ZM320.715 211.891L313.529 219.065L309.285 214.825C309.14 214.68 309.059 214.483 309.059 214.278C309.059 214.073 309.14 213.877 309.285 213.732C309.43 213.587 309.627 213.506 309.832 213.506C310.037 213.506 310.234 213.587 310.379 213.732L313.54 216.89L319.632 210.809C319.704 210.737 319.789 210.68 319.883 210.641C319.977 210.602 320.077 210.582 320.179 210.582C320.28 210.582 320.381 210.602 320.475 210.641C320.569 210.68 320.654 210.737 320.726 210.809C320.798 210.88 320.855 210.966 320.893 211.059C320.932 211.153 320.952 211.254 320.952 211.355C320.952 211.457 320.932 211.557 320.893 211.651C320.855 211.745 320.798 211.83 320.726 211.901L320.715 211.891Z" fill="#36B729" />
          <ellipse cx="315" cy="278.772" rx="16" ry="15.9869" fill="#E3E9F0" />
          <path d="M315 270.031C313.269 270.031 311.578 270.544 310.139 271.505C308.7 272.466 307.578 273.831 306.916 275.429C306.254 277.026 306.081 278.784 306.418 280.48C306.756 282.176 307.589 283.734 308.813 284.956C310.037 286.179 311.596 287.012 313.293 287.349C314.99 287.687 316.75 287.513 318.348 286.852C319.947 286.19 321.314 285.069 322.275 283.632C323.237 282.194 323.75 280.504 323.75 278.774C323.75 276.456 322.828 274.232 321.187 272.592C319.546 270.953 317.321 270.031 315 270.031ZM320.715 275.84L313.529 283.015L309.285 278.774C309.14 278.629 309.059 278.433 309.059 278.228C309.059 278.023 309.14 277.826 309.285 277.681C309.43 277.537 309.627 277.455 309.832 277.455C310.037 277.455 310.234 277.537 310.379 277.681L313.54 280.84L319.632 274.758C319.704 274.686 319.789 274.629 319.883 274.591C319.977 274.552 320.077 274.532 320.179 274.532C320.28 274.532 320.381 274.552 320.475 274.591C320.569 274.629 320.654 274.686 320.726 274.758C320.798 274.83 320.855 274.915 320.893 275.009C320.932 275.103 320.952 275.203 320.952 275.305C320.952 275.406 320.932 275.506 320.893 275.6C320.855 275.694 320.798 275.779 320.726 275.851L320.715 275.84Z" fill="#36B729" />
          <defs>
               <filter id="filter0_b_10_67" x="24" y="-4" width="488" height="487.607" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_10_67" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_10_67" result="shape" />
               </filter>
               <filter id="filter1_d_10_67" x="283" y="188.848" width="231" height="55.9575" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_67" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_67" result="shape" />
               </filter>
               <filter id="filter2_d_10_67" x="283" y="252.793" width="231" height="55.9575" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_67" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_67" result="shape" />
               </filter>
               <filter id="filter3_d_10_67" x="283" y="316.742" width="231" height="55.9575" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_10_67" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_10_67" result="shape" />
               </filter>
               <linearGradient id="paint0_linear_10_67" x1="92.5" y1="120.806" x2="92.5" y2="105.781" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint1_linear_10_67" x1="90.9056" y1="137.273" x2="90.9056" y2="123.793" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint2_linear_10_67" x1="108.25" y1="140.262" x2="108.25" y2="123.78" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint3_linear_10_67" x1="43.1388" y1="243.366" x2="43.1388" y2="222.521" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint4_linear_10_67" x1="28.4357" y1="253.778" x2="28.4357" y2="247.946" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint5_linear_10_67" x1="38.1513" y1="253.75" x2="38.1513" y2="247.945" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint6_linear_10_67" x1="47.852" y1="253.75" x2="47.852" y2="247.936" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint7_linear_10_67" x1="57.5684" y1="253.739" x2="57.5684" y2="247.945" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint8_linear_10_67" x1="28.4421" y1="257.509" x2="28.4421" y2="255.748" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint9_linear_10_67" x1="38.1427" y1="257.508" x2="38.1427" y2="255.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint10_linear_10_67" x1="47.8573" y1="257.508" x2="47.8573" y2="255.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint11_linear_10_67" x1="57.5556" y1="257.509" x2="57.5556" y2="255.75" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint12_linear_10_67" x1="96.9974" y1="373.532" x2="96.9974" y2="340.496" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint13_linear_10_67" x1="91.033" y1="358.531" x2="91.033" y2="352.511" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint14_linear_10_67" x1="105.244" y1="363.017" x2="105.244" y2="355.483" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint15_linear_10_67" x1="90.9883" y1="366" x2="90.9883" y2="359.96" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <linearGradient id="paint16_linear_10_67" x1="173.75" y1="110.41" x2="173.75" y2="369.198" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#1958AC" />
                    <stop offset="1" stopColor="#123769" />
               </linearGradient>
               <clipPath id="clip0_10_67">
                    <rect width="36" height="36" fill="white" transform="translate(79 105)" />
               </clipPath>
               <clipPath id="clip1_10_67">
                    <rect width="36" height="36" fill="white" transform="translate(25 222)" />
               </clipPath>
               <clipPath id="clip2_10_67">
                    <rect width="36" height="36" fill="white" transform="translate(79 339)" />
               </clipPath>
          </defs>
     </svg>

}

export default AuthImage;