import React from "react";

export default function Information() {
  return (
    <div className="white-background d-flex align-items-center p-2 my-3" style={{ border: '1px solid #870015', borderRadius: '4px' }}>

      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.47768 0 0 4.47768 0 10C0 15.5223 4.47768 20 10 20C15.5223 20 20 15.5223 20 10C20 4.47768 15.5223 0 10 0ZM10.7143 14.8214C10.7143 14.9196 10.6339 15 10.5357 15H9.46429C9.36607 15 9.28571 14.9196 9.28571 14.8214V8.75C9.28571 8.65179 9.36607 8.57143 9.46429 8.57143H10.5357C10.6339 8.57143 10.7143 8.65179 10.7143 8.75V14.8214ZM10 7.14286C9.71963 7.13713 9.45267 7.02174 9.25641 6.82143C9.06014 6.62112 8.95022 6.35186 8.95022 6.07143C8.95022 5.791 9.06014 5.52174 9.25641 5.32143C9.45267 5.12112 9.71963 5.00572 10 5C10.2804 5.00572 10.5473 5.12112 10.7436 5.32143C10.9399 5.52174 11.0498 5.791 11.0498 6.07143C11.0498 6.35186 10.9399 6.62112 10.7436 6.82143C10.5473 7.02174 10.2804 7.13713 10 7.14286Z" fill="#870015" />
      </svg>
      <p className="mx-2">The tool follows the english number system. Please don’t use any punctuation.</p>

    </div>
  );
}
