import React from "react";
import PropTypes from "prop-types";
import Element from "../Elements";
// import Axios from "axios";
// import { toast } from "react-toastify";
// import { useHistory, useLocation } from "react-router-dom";
// import { useAuth } from "../../../../Utils/Auth";
import Information from "../Information";
// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }
const Questionare = (props) => {
  // let query = useQuery();
  // const history = useHistory();
  // const { user } = useAuth();
  const {
    Team,
    BusinessModel,
    ProductMarket,
    IPLegal,
    quesRef,
    teamRef,
    busiRef,
    prodRef,
    iPRef,
    type,

    handlesave,
  } = props;

  React.useEffect(() => {
    if (quesRef) {
      quesRef.current?.scrollIntoView({
        behaviour: "smooth",
      });
    }
  }, [quesRef]);
  return (
    <>
      <div className="main-tab-content">
        <div className="" ref={quesRef}>
          <div className="">
            <div>
              <h6 className="title2" id="valuation-head-id">Questionnaire</h6>
              <p className="my-10">
                The qualitative aspects of a company are valuable as guarantee of
                future success, especially in early stage companies.
              </p>
              <Information />
            </div>
          </div>
        </div>

        <div className="tabs-content">
          <form
            id="questionnaire"
            className="questinare-form-root "
            onSubmit={handlesave}
          >
            <div className="dashboard02_block white_bg">
              <div ref={teamRef} className="w_100 flex_col py-0">
                <h3 className="title3">Team Details</h3>
                <p>
                  This is one of the most important aspects for investors.
                  Industry experience, complementarity and affinity of the
                  members build up a team strength
                </p>
              </div>
              <hr style={{ margin: "24px 0" }} />

              {Team
                ? Team.map((field, i) => (
                  <div key={i} className="row">
                    <div className="col-9 my-3">
                      <Element field={field} type={type} />
                    </div>
                    {field.tips ? (
                      <div className="col-3">
                        <div
                          style={{
                            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
                            padding: 16,
                            borderRadius: 12,
                            border: "1px solid #EFF0F7",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                width: 4,
                                background: "#123769",
                                marginRight: 12,
                                borderRadius: "0px 2px 2px 0px",
                                height: 18,
                              }}
                            ></span>
                            <p className="text_s fw-600">{field.h} </p>
                          </div>
                          <p>{field.p}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="col-3"></div>
                    )}
                  </div>
                ))
                : null}
            </div>

            {/* </TabPanel> */}
            {/* <TabPanel value={value} index={1} className="tabpanelcontent"> */}

            <div className="dashboard02_block white_bg mt-40">
              <div className="w_100 flex_col py-0 " ref={busiRef}>
                <h3 className="title3">Business Model</h3>
                <p> You can specify here more information on the business model
                  and current status of operations
                </p>
              </div>
              <hr style={{ margin: "24px 0" }} />
              {BusinessModel
                ? BusinessModel.map((field, i) => (
                  <div key={i} className="row">
                    <div className="col-9 my-3">
                      <Element field={field} type={type} />
                    </div>
                    {field.tips ? (
                      <div className="col-3">
                        <div
                          style={{
                            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
                            padding: 16,
                            borderRadius: 12,
                            border: "1px solid #EFF0F7",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                width: 4,
                                background: "#123769",
                                marginRight: 12,
                                borderRadius: "0px 2px 2px 0px",
                                height: 18,
                              }}
                            ></span>
                            <p className="text_s fw-600">{field.h} </p>
                          </div>
                          <p>{field.p}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-3"></div>
                      </>
                    )}
                  </div>
                ))
                : null}
            </div>

            <div className="dashboard02_block white_bg mt-40">
              <div className="w_100 flex_col py-0" ref={prodRef}>
                <h3 className="title3">Product Market</h3>
                <p>
                  You can specify here more information on the product/service
                  and on the market you are addressing
                </p>
              </div>
              <hr style={{ margin: "24px 0" }} />
              {ProductMarket
                ? ProductMarket.map((field, i) => (
                  <div key={i} className="row">
                    <div className="col-9 my-3">
                      <Element field={field} type={type} />
                    </div>
                    {field.tips ? (
                      <div className="col-3">
                        <div
                          style={{
                            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
                            padding: 16,
                            borderRadius: 12,
                            border: "1px solid #EFF0F7",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                width: 4,
                                background: "#123769",
                                marginRight: 12,
                                borderRadius: "0px 2px 2px 0px",
                                height: 18,
                              }}
                            ></span>
                            <p className="text_s fw-600">{field.h} </p>
                          </div>
                          <p>{field.p}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-3"></div>
                      </>
                    )}
                  </div>
                ))
                : null}
            </div>

            <div className="dashboard02_block white_bg mt-40">
              <div className="w_100 flex_col py-0" ref={iPRef}>
                <h3 className="title3">IP Legal</h3>
                <p>
                  Although the monetary value of IP and patents is integrated in
                  the financial projections, they are also valuable in terms of
                  barrier to entry
                </p>
              </div>
              <hr style={{ margin: "24px 0" }} />
              {IPLegal
                ? IPLegal.map((field, i) => (
                  <div key={i} className="row">
                    <div className="col-9 my-3">
                      <Element field={field} type={type} />
                    </div>
                    {field.tips ? (
                      <div className="col-3">
                        <div
                          style={{
                            boxShadow: "0px 5px 16px rgba(8, 15, 52, 0.06)",
                            padding: 16,
                            borderRadius: 12,
                            border: "1px solid #EFF0F7",
                            backgroundColor: "white",
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <span
                              style={{
                                width: 4,
                                background: "#4ad7d1",
                                marginRight: 12,
                                borderRadius: "0px 2px 2px 0px",
                                height: 18,
                              }}
                            ></span>
                            <p className="body1 fw-6">{field.h} </p>
                          </div>
                          <p className="mt-16 body2 grey_75">{field.p}</p>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="col-3"></div>
                      </>
                    )}
                  </div>
                ))
                : null}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
Questionare.propTypes = {
  questionnare: PropTypes.any,
  handleNext: PropTypes.any,
};

export default Questionare;
