import React, { useState, useEffect } from 'react'
import { CustomModal } from '../../Layouts'
import { useAuth } from '../../../Utils/Auth'
import api from '../../../axiosAPI';
import { toast } from 'react-toastify'
import { getcurrencylist } from './Lists/Currency';
import { CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { ForwardIcon } from '../../Icons';
const LetsStart = ({
     open,
     handleClose,
}) => {
     const navigate = useNavigate()

     const { setSessionExpire, currentUserOrganization } = useAuth()

     const [oldCompanies, setOldCompanies] = useState([]);
     const [valuationCurrency, setValuationCurrency] = useState("")
     const [companyIdToClone, setCompanyIdToClone] = useState("")
     const [companyTitle, setCompanyTitle] = useState(currentUserOrganization);
     const [isCreating, setIsCreating] = useState(false);
     // const [newReport, setNewReport] = useState(false);

     const getOldCompanies = () => {
          try {
               api.get('/valuation/oldcompanies').then((res) => {
                    if (res.data.success) {
                         setOldCompanies(res.data.data);
                    }
               }).catch((err) => {
                    if (err.response) {
                         if (err.response.status === 401) {
                              setSessionExpire(true);
                         }
                    }
               })
          } catch (error) {
               if (error.response && error.response.data)
                    toast.error(error.response.data.error);
          }
     }



     const createReport = async () => {
          setIsCreating(true);
          if (companyTitle.length === 0) {
               setIsCreating(false);

               return toast.error("Report title cannot be empty");

          } else if (valuationCurrency.length === 0) {

               setIsCreating(false);

               return toast.error("Currency cannot be empty");

          } else {
               const postData = {
                    reportTitle: companyTitle,
                    currency: valuationCurrency,
                    cidtoclone: companyIdToClone,
                    clone: companyIdToClone === "" ? false : true,
               }
               api.post('/valuation/companies', postData).then((res) => {
                    if (res.data.success) {
                         // setNewReport(true);
                         setIsCreating(false);
                         navigate(`/dashboard/generate-report/${res.data.cid}/valStep/4`)
                         handleClose();
                    }
               }).catch((error) => {
                    setIsCreating(false);

                    if (error.response && error.response.data) toast.error(error.response.data.error);
               })
          }


     };

     useEffect(() => {
          if (open) {
               setValuationCurrency("");
               getOldCompanies();
          }
     }, [open])
     return (
          <div>
               <CustomModal open={open} handleClose={handleClose} header={'Create Report'}>
                    <div>
                         <div className="row">
                              <div className="col-12">
                                   <div className="d-flex flex-column">
                                        <label htmlFor="reportTitle" className="input_label">
                                             Company Name
                                        </label>
                                        <input
                                             type="text"
                                             id="reportTitle"
                                             placeholder="eg: xyz company"
                                             value={companyTitle}
                                             className="input mt-6"
                                             onChange={(e) => setCompanyTitle(e.target.value)}
                                        />
                                   </div>
                              </div>
                         </div>
                         <div className="row mt-24">
                              <div className="col-lg-6 col-md-6 col-12">
                                   <div className="d-flex flex-column">
                                        <label htmlFor="currency" className="input_label">
                                             Currency
                                        </label>
                                        <select
                                             onChange={(e) => setValuationCurrency(e.target.value)}
                                             value={valuationCurrency}
                                             className="input mt-6"
                                             id='currency'
                                        >
                                             <option value="">Choose Currency</option>
                                             {getcurrencylist().map((v, i) => {
                                                  return (
                                                       <option key={i} value={v.code}>
                                                            {`${v.name} (${v.symbol})`}
                                                       </option>
                                                  );
                                             })}
                                        </select>
                                   </div>
                              </div>
                              {oldCompanies.length > 0 ? (
                                   <>
                                        <div className="col-lg-6 col-md-6 col-12">
                                             <div className="d-flex flex-column">
                                                  <label htmlFor="cloneData" className="input_label">
                                                       Clone Data
                                                  </label>
                                                  <select
                                                       disabled={!oldCompanies.length}
                                                       onChange={(e) => setCompanyIdToClone(e.target.value)}
                                                       value={companyIdToClone}
                                                       className="input mt-6"
                                                       id="cloneData"
                                                  >
                                                       <option value="">Choose Company</option>
                                                       {oldCompanies.map((v, i) => {
                                                            return (
                                                                 <option key={i} value={v.cid}>
                                                                      {`${v.companyName}`}
                                                                 </option>
                                                            );
                                                       })}
                                                  </select>
                                             </div>
                                        </div>
                                   </>
                              ) : (
                                   null
                              )}
                         </div>
                         <div className="text-left mt-48 d-flex align-items-center">
                              <button
                                   className={
                                        companyTitle?.trim() !== "" && valuationCurrency !== ""
                                             ? "submit_btn"
                                             : " submit_btn submit_btn_disabled"
                                   }
                                   disabled={!(companyTitle?.trim() !== "" && valuationCurrency !== "")}
                                   onClick={createReport}
                              >
                                   Let&apos;s Start
                                   <div>
                                        <ForwardIcon />
                                   </div>
                              </button>
                         </div>
                         <div className='d-flex justify-content-center mt-4'>
                              {isCreating ? (
                                   <span className="ml-8">
                                        <CircularProgress style={{ color: "#000" }} />
                                   </span>
                              ) : null}
                         </div>
                    </div>
               </CustomModal>
          </div>
     )
}

export default LetsStart