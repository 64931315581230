import React, { useState } from "react";
import "./benefits.css";
import vl_screen from "../../../Assets/Images/val_screen.png";

const Benefits = () => {
  const [activeTab, setactiveTab] = useState(1);
  return (
    <div className="section_info">
      <div className=" home_benefits_root">
        <h1 className="section_info--header">Benefits</h1>
        <p className="section_info--text">
          Valuation can be complex, but we&apos;ve simplified the process for
          you.
          <br />
          Discover how it works on our platform..
        </p>
      </div>
      <div className="row align-items-center justify-content-center w-100">
        <div className="col-sm-12 col-lg-5 home_benefits_images">
          {activeTab === 1 ? (
            <>
              <img src={vl_screen} />
            </>
          ) : activeTab === 2 ? (
            <>
              <img src={vl_screen} />
            </>
          ) : activeTab === 3 ? (
            <>
              <img src={vl_screen} />
            </>
          ) : null}
        </div>
        <div className="col-sm-12 col-lg-4 home_benefits_content_rigth">
          <div
            onClick={() => setactiveTab(1)}
            className={`${activeTab === 1 ? "border_active" : ""}`}
          >
            <h2 className={`${activeTab === 1 ? "" : "disable"}`}>
              Clear and Actionable Insights
            </h2>
            {activeTab === 1 ? (
              <p>
                Our platform provides easy-to-understand and actionable insights
                to help you make informed decisions about your business.
              </p>
            ) : null}
          </div>
          <div
            onClick={() => setactiveTab(2)}
            className={`${activeTab === 2 ? "border_active" : ""}`}
          >
            <h2 className={`${activeTab === 2 ? "" : "disable"}`}>
              Trusted Valuation Metrics
            </h2>
            {activeTab === 2 ? (
              <p>
                Discover the true worth of your assets with our valuation
                tool&apos;s reliable and trusted metrics.
              </p>
            ) : null}
          </div>
          <div
            onClick={() => setactiveTab(3)}
            className={`${activeTab === 3 ? "border_active" : ""}`}
          >
            <h2 className={`${activeTab === 3 ? "" : "disable"}`}>
              Save Time and Money
            </h2>
            {activeTab === 3 ? (
              <p>
                Revolutionize your valuation process with our tool, saving you
                time and money while providing accurate results.
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
