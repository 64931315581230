module.exports.countrywithcode = () => {
  return [
    {
      id: "al",
      option_label: "Albania",
    },
    {
      id: "ar",
      option_label: "Argentina",
    },
    {
      id: "aw",
      option_label: "Aruba",
    },
    {
      id: "au",
      option_label: "Australia",
    },
    {
      id: "at",
      option_label: "Austria",
    },
    {
      id: "bs",
      option_label: "Bahamas",
    },
    {
      id: "bh",
      option_label: "Bahrain",
    },
    {
      id: "bb",
      option_label: "Barbados",
    },
    {
      id: "be",
      option_label: "Belgium",
    },
    {
      id: "bm",
      option_label: "Bermuda",
    },
    {
      id: "bo",
      option_label: "Bolivia",
    },
    {
      id: "ba",
      option_label: "Bosnia and Herzegovina",
    },
    {
      id: "br",
      option_label: "Brazil",
    },
    {
      id: "bg",
      option_label: "Bulgaria",
    },
    {
      id: "bf",
      option_label: "Burkina Faso",
    },
    {
      id: "ca",
      option_label: "Canada",
    },
    {
      id: "ky",
      option_label: "Cayman Islands",
    },
    {
      id: "cl",
      option_label: "Chile",
    },
    {
      id: "cn",
      option_label: "China",
    },
    {
      id: "co",
      option_label: "Colombia",
    },
    {
      id: "cr",
      option_label: "Costa Rica",
    },
    {
      id: "hr",
      option_label: "Croatia",
    },
    {
      id: "cy",
      option_label: "Cyprus",
    },
    {
      id: "cz",
      option_label: "Czech Republic",
    },
    {
      id: "dk",
      option_label: "Denmark",
    },
    {
      id: "do",
      option_label: "Dominican Republic",
    },
    {
      id: "ec",
      option_label: "Ecuador",
    },
    {
      id: "eg",
      option_label: "Egypt",
    },
    {
      id: "ee",
      option_label: "Estonia",
    },
    {
      id: "fi",
      option_label: "Finland",
    },
    {
      id: "fr",
      option_label: "France",
    },
    {
      id: "de",
      option_label: "Germany",
    },
    {
      id: "gi",
      option_label: "Gibraltar",
    },
    {
      id: "gr",
      option_label: "Greece",
    },
    {
      id: "hk",
      option_label: "Hong Kong",
    },
    {
      id: "hu",
      option_label: "Hungary",
    },
    {
      id: "is",
      option_label: "Iceland",
    },
    {
      id: "in",
      option_label: "India",
    },
    {
      id: "id",
      option_label: "Indonesia",
    },
    {
      id: "ie",
      option_label: "Ireland",
    },
    {
      id: "im",
      option_label: "Isle of Man",
    },
    {
      id: "il",
      option_label: "Israel",
    },
    {
      id: "it",
      option_label: "Italy",
    },
    {
      id: "ci",
      option_label: "Ivory Coast",
    },
    {
      id: "jm",
      option_label: "Jamaica",
    },
    {
      id: "jo",
      option_label: "Jordan",
    },
    {
      id: "jp",
      option_label: "Japan",
    },
    {
      id: "ke",
      option_label: "Kenya",
    },
    {
      id: "kr",
      option_label: "Korea, Republic of",
    },
    {
      id: "kw",
      option_label: "Kuwait",
    },
    {
      id: "lv",
      option_label: "Latvia",
    },
    {
      id: "li",
      option_label: "Liechtenstein",
    },
    {
      id: "lt",
      option_label: "Lithuania",
    },
    {
      id: "lu",
      option_label: "Luxembourg",
    },
    {
      id: "my",
      option_label: "Malaysia",
    },
    {
      id: "mt",
      option_label: "Malta",
    },
    {
      id: "mu",
      option_label: "Mauritius",
    },
    {
      id: "mx",
      option_label: "Mexico",
    },
    {
      id: "nl",
      option_label: "The Netherlands",
    },
    {
      id: "nz",
      option_label: "New Zealand",
    },
    {
      id: "ng",
      option_label: "Nigeria",
    },
    {
      id: "no",
      option_label: "Norway",
    },
    {
      id: "pa",
      option_label: "Panama",
    },
    {
      id: "py",
      option_label: "Paraguay",
    },
    {
      id: "pe",
      option_label: "Peru",
    },
    {
      id: "ph",
      option_label: "Philippines",
    },
    {
      id: "pl",
      option_label: "Poland",
    },
    {
      id: "pt",
      option_label: "Portugal",
    },
    {
      id: "qa",
      option_label: "Qatar",
    },
    {
      id: "ro",
      option_label: "Romania",
    },
    {
      id: "ru",
      option_label: "Russia",
    },
    {
      id: "sa",
      option_label: "Saudi Arabia",
    },
    {
      id: "sn",
      option_label: "Senegal",
    },
    {
      id: "rs",
      option_label: "Serbia",
    },
    {
      id: "sg",
      option_label: "Singapore",
    },
    {
      id: "sk",
      option_label: "Slovakia",
    },
    {
      id: "si",
      option_label: "Slovenia",
    },
    {
      id: "za",
      option_label: "South Africa",
    },
    {
      id: "es",
      option_label: "Spain",
    },
    {
      id: "se",
      option_label: "Sweden",
    },
    {
      id: "ch",
      option_label: "Switzerland",
    },
    {
      id: "tw",
      option_label: "Taiwan",
    },
    {
      id: "tt",
      option_label: "Trinidad and Tobago",
    },
    {
      id: "tn",
      option_label: "Tunisia",
    },
    {
      id: "tr",
      option_label: "Turkey",
    },
    {
      id: "ua",
      option_label: "Ukraine",
    },
    {
      id: "ae",
      option_label: "United Arab Emirates",
    },
    {
      id: "gb",
      option_label: "United Kingdom",
    },
    {
      id: "us",
      option_label: "United States",
    },
    {
      id: "uy",
      option_label: "Uruguay",
    },
    {
      id: "ve",
      option_label: "Venezuela",
    },
  ];
};
