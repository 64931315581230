import React from 'react';
import './navbar.css'
import AnchorLink from "react-anchor-link-smooth-scroll";
import { useAuth } from '../../../Utils/Auth';
import UserInfo from '../../UserInfo';
import { Link } from 'react-router-dom';
const NavSmall = () => {
     const { currentUser } = useAuth();
     return <div className='small_nav'>
          <ul className="nav_elements d-flex flex-column justify-content-center align-items-left">
               <li className="nav_element_small">
                    <AnchorLink href="#howItWorks">How it works</AnchorLink>
               </li>
               <li className="nav_element_small">
                    <AnchorLink href="#insights">Insights</AnchorLink>
               </li>
               <li className="nav_element_small">
                    <AnchorLink href="#pricing">Pricing</AnchorLink>
               </li>
               <li className="nav_element_small">
                    <AnchorLink href="#contactUs">contact us</AnchorLink>
               </li>
               <hr className='my-3' width="90%" />
               <li className='mt-3'>
                    {
                         currentUser ? <UserInfo isDashboard={false} /> : <Link to={'/login'}>
                              <li className="nav_element_small">
                                   Log in
                              </li>
                         </Link>
                    }
               </li>

          </ul>
     </div>
}

export default NavSmall;