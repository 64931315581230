module.exports.getequidamvariables = (type) => {

  switch (type) {
    case "tenques":
      return {
        estimatedrevenues_y1: 0,
        estimatedrevenues_y4: 0,
        foundersnumber: 1,
        employeesnumber: 0,
        startedyear: 0,
        currency: null,
        country: "",
        industry: "",
        iptype: "",
        devstage: "",
        profitability: "",
      };
    case "ques":
      return {
        reasonforvalonboarding: null,
        valuationparty: null,
        foundersnumber: null,
        employeesnumber: null,
        yearsexperience: null,
        founderscommitment: null,
        businessex: null,
        knowbefore: null,
        entrage: null,
        previousstartup: null,
        capitalinvested: null,
        scalability: null,
        sellingapproach: null,
        devstage: null,
        profitability: null,
        investors_type1: null,
        investors_type2: null,
        investors_type3: null,
        investors_type4: null,
        investors_type5: null,
        boardpresence: null,
        external_advisors: null,
        loyaltydegree: null,
        companyexit: null,
        feedbacktrend: null,
        country: null,
        industry: null,
        demandvalidation: null,
        industrycompetition: null,
        competitiveproducts1: null,
        competitiveproducts2: null,
        entrybarriers: null,
        rollout: null,
        relateintcomp: null,
        internationalex: null,
        legalcomp: null,
        constyear: null,
        ippatent: null,
        iptype: null,
        legalconsultants: null,
        tam: null,
        tamgrowth: 5,
        techskills: null,
        partners: null,
        startedyear: null,
        onblockchain: null,
        blockchaintype: null,
        currency_symbol: null,
        countryextended: null,
        devstage_limit_in_currency: null,
      };
    case "financials":
      return {
        currency: null,
        projections: {
          marketsize: [0, 0, 0, 0],
          productsold: [0, 0, 0, 0],
          estimatedrevenues: [0, 0, 0, 0],
          contractsinplace: [0, 0, 0, 0],
          costgoods: [0, 0, 0, 0],
          salaries: [0, 0, 0, 0],
          loi: [0, 0, 0, 0],
          operatingexpenses: [0, 0, 0, 0],
          longterminvestments: [0, 0, 0, 0],
          endingyeardebt: [0, 0, 0, 0],
          fundraisingplan: [0, 0, 0, 0],
          ebitda: [0, 0, 0, 0],
          ebit: [0, 0, 0, 0],
          receivables: [0, 0, 0, 0],
          inventory: [0, 0, 0, 0],
          payables: [0, 0, 0, 0],
          netprofit: [0, 0, 0, 0],
          fingainloss: [0, 0, 0, 0],
          taxliabilities: [0, 0, 0, 0],
          depreciations: [0, 0, 0, 0],
          receivables_default: [0, 0, 0, 0],
          inventory_default: [0, 0, 0, 0],
          payables_default: [0, 0, 0, 0],
          netprofit_default: [0, 0, 0, 0],
          opcash_default: [0, 0, 0, 0],
          changeincash_default: [0, 0, 0, 0],
          fingainloss_default: [0, 0, 0, 0],
          taxliabilities_default: [0, 0, 0, 0],
          depreciations_default: [0, 0, 0, 0],
          cashtoequity_default: [0, 0, 0, 0],
          changeindebt_default: [0, 0, 0, 0],
          workcap: [0, 0, 0, 0],
          changeworkcap: [0, 0, 0, 0],
          year_index_count: 4,
          currency: null,
          startdate: null,
          profitmeasurements_default: true,
        },
        balance_sheet: {
          y0cash: 0,
          y0tangassets: 0,
          y0intangassets: 0,
          y0finassets: 0,
          y0currliab: 0,
          endingyeardebt_y1: 0,
          y0equity: 0,
          y0deftaxass: 0,
        },
      };
    case "transactions":
      return {
        closedrounds: [],
        cInfo: {
          capitalneeded: 0,
          currency: null,
          currency_symbol: null,
          rate: "1",
          useOfFunds: [
            {
              name: "Product and R&D",
              desc: "Building innovation and improvement of products or processes",
              amount: 0,
              key: "uofproduct_dev",
              percentage: 0,
            },
            {
              name: "Sales and marketing",
              desc: "Creation and communication of the product value for customers, partners, and society at large",
              amount: 0,
              key: "uofmarketing",
              percentage: 0,
            },
            {
              name: "Inventory",
              desc: "Cumulated raw material for production and finished goods before sales",
              amount: 0,
              key: "uofinventory",
              percentage: 0,
            },
            {
              name: "Operations",
              desc: "Day-to-day activities to run an ongoing company (e.g.: location, procurement, processes)",
              amount: 0,
              key: "uofoperating",
              percentage: 0,
            },
            {
              name: "Capital expenditures",
              desc: "Tangible and intangible asset acquisition (purchase of equipments, real estate, brands, patents)",
              amount: 0,
              key: "uofcapex",
              percentage: 0,
            },
            {
              name: "Others",
              desc: "Any other activity not included in the ones described above",
              amount: 0,
              key: "uofothers",
              percentage: 0,
            },
          ],
          cid: null,
          uofSum: 0,
        },
      };
    default:
      break;
  }
};
