import React, { useContext, createContext, useEffect, useState } from 'react';
import api from '../../axiosAPI';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const authContext = createContext();

const useProviderAuth = () => {
     const navigate = useNavigate()
     const [currentUser, setCurrentUser] = useState(null)
     const [currentUserOrganization, setCurrentUserOrganization] = useState(null)
     const [sessionExpire, setSessionExpire] = useState(false);
     const [isLoading, setIsLoading] = useState(true);
     const [loadCurrent, setLoadCurrent] = useState(false)
     const [hasCredit, sethasCredit] = useState(false)
     const [billingAddress, setBillingAddress] = useState({})

     const logout = () => {
          api.get('/user/logout').then(res => {
               if (res.data.success) {
                    toast.success('log out')
                    setCurrentUser(null)
                    setCurrentUserOrganization(null)
                    sethasCredit(false)
                    setBillingAddress({})
                    setLoadCurrent(!loadCurrent);
                    navigate('/')
               }
          }).catch(err => {
               console.log(err);
          })
     }

     const getUser = () => {
          setIsLoading(true)
          api.get('/user/current').then((res) => {
               setCurrentUser(res.data)
               setCurrentUserOrganization(res.data.organizationName);
               setBillingAddress(res.data.billing_add)
               if (res.data.valuation_credit > 0) {
                    sethasCredit(true)
               }
               setIsLoading(false)
          }).catch((err) => {
               console.log(err)
               setIsLoading(false)
          })
     }

     useEffect(() => {
          getUser();
     }, [loadCurrent])

     return {
          currentUser,
          logout,
          getUser,
          setSessionExpire,
          sessionExpire,
          currentUserOrganization,
          isLoading,
          setLoadCurrent,
          loadCurrent,
          hasCredit,
          sethasCredit,
          setBillingAddress,
          billingAddress
     }
}

export function ProvideAuth({ children }) {
     const auth = useProviderAuth();
     return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext)