import { useEffect, useState } from 'react'

import { useNavigate } from "react-router-dom"
// import { useAuth } from '../Auth'
import Cookies from 'js-cookie';
const ProtectedRoute = ({ children }) => {
     const navigate = useNavigate();

     const [isLoggedIn, setIsLoggedIn] = useState(false);
     const userToken = Cookies.get('auth_tk');

     const allCookies = document.cookie;
     function getCookie(name) {
          const cookieArray = document.cookie.split('; ');
          for (let i = 0; i < cookieArray.length; i++) {
            const cookie = cookieArray[i].split('=');
            if (cookie[0] === name) {
              return cookie[1];
            }
          }
          return null;
        }
        const myCookieValue = getCookie('auth_tk');
        console.log('allCookies',allCookies)
        console.log('myCookieValue',myCookieValue)
     const checkUserToken = () => {
          
          
          if (!userToken || userToken === undefined) {
               setIsLoggedIn(false);
               navigate('/login')
          }
          setIsLoggedIn(true)
     }

     useEffect(() => {
          checkUserToken()
     }, [isLoggedIn])

     return children

};

export default ProtectedRoute;