import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../../Assets/Logos/vcbay_with_name.svg";
import { useMediaQuery } from "react-responsive";
import "./navbar.css";
import AnchorLink from "react-anchor-link-smooth-scroll";
import NavSmall from "./SmallNav";
import { Link } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import UserInfo from "../../UserInfo";
const Navbar = () => {
  const { currentUser } = useAuth()

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const [navNavigation, setNavNavigation] = useState(false);

  const handleNavigation = (e) => {
    e.preventDefault();
    setNavNavigation(prev => !prev)
  }

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div
      className={`navbar ${scrolled && 'nav_bg'} d-flex align-items-center justify-content-between ${isDesktopOrLaptop && "px-80"}`}
    >
      <div>
        <Logo />
      </div>

      {isDesktopOrLaptop ? (
        <>
          <div>
            <ul className="nav_elements d-flex justify-content-center align-items-center">
              <li className="nav_element">
                <AnchorLink href="#howItWorks">How it works</AnchorLink>
              </li>
              <li className="nav_element">
                <AnchorLink href="#insights">Insights</AnchorLink>
              </li>
              <li className="nav_element">
                <AnchorLink href="#pricing">Pricing</AnchorLink>
              </li>
              <li className="nav_element">
                <AnchorLink href="#contactUs">contact us</AnchorLink>
              </li>
            </ul>
          </div>
          <div>
            {
              currentUser ? <UserInfo isDashboard={false} /> : <ul className='nav_actions'>
                <Link to="/login">
                  <li className='nav_action'>Log in</li>
                </Link>

              </ul>
            }


          </div>
        </>
      ) : (
        <>
          {
            !navNavigation ? <svg
              width="20"
              height="17"
              viewBox="0 0 18 15"
              fill="#000"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-4"
              onClick={handleNavigation}
            >
              <path
                d="M1.125 13.375H16.875M1.125 7.375H16.875M1.125 1.375H16.875"
                stroke="#000"
                strokeWidth="2.3125"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> : <svg width="24" height="24" viewBox="0 0 24 24" fill="#000" xmlns="http://www.w3.org/2000/svg" className="mr-4" onClick={handleNavigation}>
              <path
                d="M16.875 7.125L7.125 16.875M7.125 7.125L16.875 16.875"
                stroke="#000"
                strokeWidth="2.3125"
                strokeLinecap="round"
                strokeLinejoin="round" />
            </svg>
          }
          {
            navNavigation && <NavSmall />
          }
        </>

      )}
    </div>
  );
};

export default Navbar;
