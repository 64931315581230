import React, { useContext, useEffect, useState } from "react";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Element from "../Elements";
import "./step2.css";
import { FormContext } from "../Context/FormContext";

// import { ArrowBack } from "@mui/icons-material";
import { Drawer, TextField, Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Information from "../Information";
import { getcurrencylist } from "../Lists/Currency";
import { Close, Info } from "@mui/icons-material";
import { FinantialDrawerData } from "../FinantialDrawerData";
import parse from "html-react-parser";

const Financial = (props) => {
  const {
    Finantial,
    balanceSheet,
    finDate,
    setfinDate,
    currency,
    financialsEquidam,
    finRef,
    type,
    value,
    handlesave,
    handleresetfinancials,
  } = props;

  // const [currentdate] = useState(new Date().getFullYear());
  const [currentdate, setcurrentdate] = useState();
  React.useEffect(() => {
    setcurrentdate(new Date(finDate).getFullYear());
  }, [finDate]);

  const context = useContext(FormContext);
  const { handleChangeFinancial } = context;
  const [state, setState] = useState(false);

  const [selectedFinancial, setSelectFinancial] = useState(null);

  const toggleDrawer = (open) => {
    setState(open);
  };

  const handleSelectFinanctial = (data) => {
    setSelectFinancial(data);
    toggleDrawer(true);
  };

  // const handleTabs = (event, newValue) => {
  //   setValue(newValue);
  // };
  // const [isLockBalance, setisLockBalance] = useState(!finFilled);

  // const valuation_url_id = localStorage.getItem("valuation_url_id");

  const FinantialContent = {
    0: "Financial projections are valuable to take into account the future cash flows of a company, and to compute investors’ returns.",
    1: "The balance sheet doesn’t directly impact valuation, but it’s an additional information on the current status of the company. With respect to the table below, Cash and equivalents impact Interests, Debt due beyond one year time are linked with Debt at the end of the year, and Deferred tax assets impact Taxes",
  };

  useEffect(() => {
    if (finRef) {
      finRef.current?.scrollIntoView({
        behaviour: "smooth",
      });
    }
  }, [finRef]);
  return (
    <>
      <div className="main-tab-content">
        <div className="" ref={finRef}>
          <div className="d-flex align-items-center justify-content-between mx-0 mt-24">
            <h6 id="valuation-head-id" className="title2 mb-2">Financials</h6>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={finDate}
                onChange={(newValue) => {
                  setfinDate(newValue);
                }}
                variant="outlined"
                label="Valuation start date"
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>

          <p className="mb-16 mt-24">{FinantialContent[value]}</p>

          <Information />

          <div className="d-flex justify-content-end">
            <button
              onClick={() => handleresetfinancials()}
              className="cta_lets_start_outline"
              type="button"
            >
              Reset Financials
            </button>
          </div>

        </div>
        <div className="tabs-financial-root">
          <form id="financials" onSubmit={handlesave}>
            <div className="white-background p-32 mt-24">
              <table className="cashflowTable">
                <thead className="cashflowthead">
                  <tr>
                    <th></th>
                    <th>
                      <span className="text-center">Past Year</span>
                      <p className="body1">
                        {currentdate - 1}-{currentdate}
                      </p>
                    </th>
                    <th>
                      <span className="text-center">Forecasts {">"}</span>
                      <p className="body1">
                        {currentdate}-{currentdate + 1}
                      </p>
                    </th>
                    <th>
                      <p className="body1">
                        {currentdate + 1}-{currentdate + 2}
                      </p>
                    </th>
                    <th>
                      <p className="body1">
                        {currentdate + 2}-{currentdate + 3}
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className="cashflowthead">
                  {Finantial && Finantial.fields
                    ? Finantial.fields.map((field, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <th
                              className="body2 pointer"
                              onClick={() =>
                                handleSelectFinanctial(field.row_label)
                              }
                            >
                              <div className="d-flex">
                                <div>
                                  <span className="mr-12 pointer">
                                    <Info style={{ width: '1.1rem', cursor: "pointer" }} />
                                  </span>
                                </div>
                                <div>
                                  {`${field.row_label}(${currency})`}<span className="text-danger fw-500" style={{ fontSize: '1.3rem', whiteSpace: 'pre' }}>
                                    *</span>
                                </div>
                              </div>
                            </th>
                            <td>
                              {field.past_year.type === "input" ? (
                                <Tooltip
                                  title={
                                    getcurrencylist().find(
                                      (v) => v.code === currency
                                    )?.symbol
                                  }
                                  placement="left-start"
                                >
                                  <input
                                    className="financial_input"
                                    type="number"
                                    hidden={field.past_year.disable}
                                    disabled={field.past_year.static}
                                    required
                                    value={
                                      field.past_year.id === "f93"
                                        ? financialsEquidam["projections"][
                                        "receivables"
                                        ][0] +
                                        financialsEquidam["projections"][
                                        "inventory"
                                        ][0] -
                                        financialsEquidam["projections"][
                                        "payables"
                                        ][0]
                                        : field.past_year["value"]
                                    }
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.past_year.id,
                                        event
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <p>
                                  <b>{field.past_year.value}</b>
                                </p>
                              )}
                            </td>
                            <td>
                              {field.forcast.type === "input" ? (
                                <Tooltip
                                  title={
                                    getcurrencylist().find(
                                      (v) => v.code === currency
                                    )?.symbol
                                  }
                                  placement="left-start"
                                >
                                  <input
                                    className="financial_input"
                                    type="number"
                                    hidden={field.forcast.disable}
                                    disabled={field.forcast.static}
                                    required
                                    value={
                                      field.forcast.id === "f94"
                                        ? financialsEquidam["projections"][
                                        "receivables"
                                        ][1] +
                                        financialsEquidam["projections"][
                                        "inventory"
                                        ][1] -
                                        financialsEquidam["projections"][
                                        "payables"
                                        ][1]
                                        : field.forcast.id === "f98"
                                          ? financialsEquidam["projections"][
                                          "receivables"
                                          ][1] +
                                          financialsEquidam["projections"][
                                          "inventory"
                                          ][1] -
                                          financialsEquidam["projections"][
                                          "payables"
                                          ][1] -
                                          (financialsEquidam["projections"][
                                            "receivables"
                                          ][0] +
                                            financialsEquidam["projections"][
                                            "inventory"
                                            ][0] -
                                            financialsEquidam["projections"][
                                            "payables"
                                            ][0])
                                          : field.forcast["value"]
                                    }
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.forcast.id,
                                        event
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <p>
                                  <b>{field.forcast.value}</b>
                                </p>
                              )}
                            </td>
                            <td>
                              {field.forcast1.type === "input" ? (
                                <Tooltip
                                  title={
                                    getcurrencylist().find(
                                      (v) => v.code === currency
                                    )?.symbol
                                  }
                                  placement="left-start"
                                >
                                  <input
                                    className="financial_input"
                                    type="number"
                                    required
                                    hidden={field.forcast1.disable}
                                    disabled={field.forcast1.static}
                                    value={
                                      field.forcast1.id === "f95"
                                        ? financialsEquidam["projections"][
                                        "receivables"
                                        ][2] +
                                        financialsEquidam["projections"][
                                        "inventory"
                                        ][2] -
                                        financialsEquidam["projections"][
                                        "payables"
                                        ][2]
                                        : field.forcast1.id === "f99"
                                          ? financialsEquidam["projections"][
                                          "receivables"
                                          ][2] +
                                          financialsEquidam["projections"][
                                          "inventory"
                                          ][2] -
                                          financialsEquidam["projections"][
                                          "payables"
                                          ][2] -
                                          (financialsEquidam["projections"][
                                            "receivables"
                                          ][1] +
                                            financialsEquidam["projections"][
                                            "inventory"
                                            ][1] -
                                            financialsEquidam["projections"][
                                            "payables"
                                            ][1])
                                          : field.forcast1["value"]
                                    }
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.forcast1.id,
                                        event
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <p>
                                  <b>{field.forcast1.value}</b>
                                </p>
                              )}
                            </td>
                            <td>
                              {field.forcast2.type === "input" ? (
                                <Tooltip
                                  title={
                                    getcurrencylist().find(
                                      (v) => v.code === currency
                                    )?.symbol
                                  }
                                  placement="left-start"
                                >
                                  <input
                                    className="financial_input"
                                    type="number"
                                    required
                                    hidden={field.forcast2.disable}
                                    disabled={field.forcast2.static}
                                    value={
                                      field.forcast2.id === "f96"
                                        ? financialsEquidam["projections"][
                                        "receivables"
                                        ][3] +
                                        financialsEquidam["projections"][
                                        "inventory"
                                        ][3] -
                                        financialsEquidam["projections"][
                                        "payables"
                                        ][3]
                                        : field.forcast2.id === "f100"
                                          ? financialsEquidam["projections"][
                                          "receivables"
                                          ][3] +
                                          financialsEquidam["projections"][
                                          "inventory"
                                          ][3] -
                                          financialsEquidam["projections"][
                                          "payables"
                                          ][3] -
                                          (financialsEquidam["projections"][
                                            "receivables"
                                          ][2] +
                                            financialsEquidam["projections"][
                                            "inventory"
                                            ][2] -
                                            financialsEquidam["projections"][
                                            "payables"
                                            ][2])
                                          : field.forcast2["value"]
                                    }
                                    onChange={(event) =>
                                      handleChangeFinancial(
                                        field.forcast2.id,
                                        event
                                      )
                                    }
                                  />
                                </Tooltip>
                              ) : (
                                <p>
                                  <b>{field.forcast2.value}</b>
                                </p>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })
                    : null}
                </tbody>
              </table>
            </div>

            <div className="white-background p-32 mt-24">
              <div className="row_between mx-0 py-2">
                <h6 id="valuation-head-id" className="title2 mb-2">Last Available Balance Sheet</h6>
              </div>
              <p>
                The balance sheet discloses the financial position of your
                company at a particular point of time. Note that this table
                doesn&apos;t impact valuation
              </p>

              {balanceSheet ? (
                <>
                  <div className="dashboard01_block white_bg">
                    <div className="balanceSheetmain">
                      <div className="assetsmain">
                        <p className="body1 mb-16">Assets({currency})</p>
                        <hr />
                        <div className="balanceSheet">
                          {balanceSheet.Assets
                            ? balanceSheet.Assets.map((field, i) => (
                              <Element key={i} field={field} type={type} />
                            ))
                            : null}
                        </div>
                      </div>
                      <div className="liabilitymain">
                        <p className="body1 mb-16">Liabilities({currency})</p>
                        <hr />
                        <div className="balanceSheet">
                          {balanceSheet.Liabilities
                            ? balanceSheet.Liabilities.map((field, i) => (
                              <Element key={i} field={field} type={type} />
                            ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
      {/* c9f0ff */}
      <Drawer anchor={"right"} open={state} onClose={() => toggleDrawer(false)}>
        <div className="valuation_drawer_info_root">
          <div className="valuation_drawer_info_head d-flex align-items-center justify-content-between">
            <h6>Info</h6>
            <button className="trans_cta" onClick={() => toggleDrawer(false)}>
              <Close />
            </button>
          </div>
          {FinantialDrawerData[selectedFinancial] && (
            <div className="valuation_drawer_info_main">
              <div className="d-flex align-items-center justify-content-between">
                <p className="title1">
                  {String(
                    FinantialDrawerData[selectedFinancial].title
                  ).toUpperCase()}
                </p>
                <Info fontSize="medium" />
              </div>
              {FinantialDrawerData[selectedFinancial].what && (
                <div className="mt-16">
                  <p className="body1 grey_58">What is it?</p>
                  <p className="body2 mt-12">
                    {parse(`${FinantialDrawerData[selectedFinancial].what}`)}
                  </p>
                </div>
              )}
              {FinantialDrawerData[selectedFinancial].howTitle && (
                <div className="mt-16">
                  <p className="body1 grey_58">
                    {FinantialDrawerData[selectedFinancial].howTitle}
                  </p>
                  <p className="body2 mt-12">
                    {parse(`${FinantialDrawerData[selectedFinancial].howDesc}`)}
                  </p>
                </div>
              )}
              {FinantialDrawerData[selectedFinancial].tips && (
                <div className="mt-16">
                  <p className="body1 grey_58">Tips</p>
                  <p className="body2 mt-12">
                    {parse(`${FinantialDrawerData[selectedFinancial].tips}`)}
                  </p>
                </div>
              )}
              {FinantialDrawerData[selectedFinancial].example && (
                <div className="mt-16">
                  <p className="body1 grey_58">Example</p>
                  <p className="body2 mt-12">
                    {parse(`${FinantialDrawerData[selectedFinancial].example}`)}
                  </p>
                </div>
              )}
              {FinantialDrawerData[selectedFinancial].article && (
                <div className="mt-16">
                  <p className="body1 grey_58">Related Articles</p>
                  <p className="body2 mt-12">
                    <a
                      target="_blank"
                      href={FinantialDrawerData[selectedFinancial].article}
                      rel="noreferrer"
                    >
                      <span
                        style={{
                          color: "#4ad7d1",
                          textDecoration: "underline",
                        }}
                      >
                        {FinantialDrawerData[selectedFinancial].articletopic}
                      </span>
                      <br />
                      (Source : Equidam)
                    </a>
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

Financial.propTypes = {
  Finantial: PropTypes.any,
  handleNext: PropTypes.any,
  balanceSheet: PropTypes.any,
  handleChangeFinancial: PropTypes.any,
};
export default Financial;
