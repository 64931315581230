import React, { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../Auth";
import { CircularProgress } from "@mui/material";
export default function UserCheck() {
     const { isLoading, currentUser } = useAuth(); //hasCredit currentUser
     const navigate = useNavigate()

     useEffect(() => {
          if (!isLoading) {
               if (currentUser) {
                    if (currentUser.valuation_credit > 0) {
                         navigate('/dashboard/my-reports')
                    } else {
                         navigate('/payment')
                    }
               }
          }


     }, [isLoading]);

     return (
          <div
               style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                    background: "#170817",
               }}
          >
               <CircularProgress style={{ color: "#fff" }} />
          </div>
     );
}
